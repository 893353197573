import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import queryClient from 'services/api/queryClient';

const useAuthStore = create(persist(set => ({
	token: null,
	authorize: (token) => {
		if (typeof token !== 'string') {
			return;
		}

		set({ token });
	},
	deauthorize: () => {
		set({ token: null });

		queryClient.clear();
		queryClient.resetQueries();
	}
}), {
	name: 'auth-storage'
}));

export default useAuthStore;
