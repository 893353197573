import ago from 'utils/ago';
import PopoutMenu from 'components/PopoutMenu';
import ShareDialog from 'components/ShareDialog';
import QueryableListItem from 'components/Queryable/ListItem';
import { Link } from 'react-router-dom';
import { PencilIcon, ShareIcon } from '@heroicons/react/24/outline';
import { useState, useCallback, Fragment } from 'react';

const DashboardUnlocksListItem = ({
	id,
	type,
	title,
	image,
	created,
	loading,
	viewType,
	username,
	description,
	claimable
}) => {
	const [sharing, setSharing] = useState(false);

	const handleClose = useCallback(() => {
		setSharing(false);
	}, []);

	const handleOpen = useCallback(() => {
		setSharing(true);
	}, []);

	return (
		<Fragment>
			<QueryableListItem
				title={title}
				banner={image}
				loading={loading}
				viewType={viewType}
				primary={`Unlockable ${type === 'url' ? 'URL' : type === 'file' ? 'File' : type}`}
				secondary={`Created ${ago(created)}`}
				description={description}
				link={id}
				menu={(
					<PopoutMenu className="items-start" items={[
						<button key="share" onClick={handleOpen} className="flex w-full items-center px-4 py-2 text-sm hover:bg-theme-menu-hover">
							<ShareIcon className="w-4 h-4 mr-2" />Share
						</button>,
						<Link key="edit" component="button" to={id} className="flex w-full items-center px-4 py-2 text-sm hover:bg-theme-menu-hover">
							<PencilIcon className="w-4 h-4 mr-2" />Edit
						</Link>
					]} />
				)}
			/>
			<ShareDialog
				claimable={claimable}
				open={sharing}
				title={title}
				banner={image}
				username={username}
				onClose={handleClose}
				link={`${window?.location?.origin}/c/${username}/unlocks/${id}`}
			/>
		</Fragment>
	);
};

export default DashboardUnlocksListItem;
