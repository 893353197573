import client from '../client';
import useAuthStore from 'state/hooks/useAuthStore';

const registerAccount = (data = {}) => client
	.post('/accounts/grant-password/register', data)
	.then(response => {
		if (response?.data?.token) {
			useAuthStore.getState().authorize(response.data.token);
		}

		return response?.data;
	})
;

export default registerAccount;
