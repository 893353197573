const copy = (data) => {
	if (!navigator?.permissions?.query) {
		return copyLegacy(data);
	}

	return navigator.permissions.query({
		name: 'clipboard-write'
	}).then(() => {
		return navigator.clipboard.writeText(data);
	}).catch(() => {
		return copyLegacy(data);
	});
};

const copyLegacy = (data) => new Promise((resolve, reject) => {
	const input = document.createElement('input');

	if (!input?.select) {
		reject(new Error('Feature not supported'));
		return;
	}

	input.setAttribute('readOnly', true);
	input.setAttribute('value', data);

	input.style.position = 'fixed';
	input.style.top = '-9999px';
	input.style.left = '-9999px';
	input.style.opacity = 0;
	input.style.pointerEvents = 'none';

	document.body.appendChild(input);

	input.select();
	input.focus();

	const copied = document?.execCommand('copy');

	document.body.removeChild(input);

	if (copied) {
		resolve();
		return;
	}

	reject(new Error('Feature not supported'));
});

export default copy;
