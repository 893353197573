import Heading from 'components/Heading';
import DashboardUnlocksURLForm from 'features/Dashboard/Unlocks/URLForm';

const DashboardUnlocksCreateURLRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Create URL Unlock</Heading>
			<DashboardUnlocksURLForm />
		</div>
	);
};

export default DashboardUnlocksCreateURLRoute;
