import client from '../client';

const unfollowBrand = (data = {}) => {
	const brandId = data?.brandId;

	if (!(brandId)) {
		return Promise.reject(new Error('Unable to unfollow brand without valid brand id'));
	}

	return client
		.delete(`/pages/${ brandId }/follow`)
		.then(response => response?.data)
	;
};

export default unfollowBrand;
