import useTokenQuery from 'services/api/hooks/useTokenQuery';
import Heading from 'components/Heading';
import { useParams } from 'react-router-dom';
import { Fragment } from 'react';

const TokenAbout = ({
	heading,
	className,
	disableHeading
}) => {
	const meta = useParams();

	const { data = {}, isLoading } = useTokenQuery({
		meta
	});

	return (
		<div className={className}>
			{disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{heading} {data?.collection?.name}
				</Heading>
			)}
			{isLoading ? (
				<Fragment>
					<div className="bg-theme-placeholder rounded-md h-6 mb-1" />
					<div className="bg-theme-placeholder rounded-md h-6 w-1/2 mb-1" />
					<div className="bg-theme-placeholder rounded-md h-6 w-3/4" />
				</Fragment>
			) : data?.collection?.details?.description ? (
				<p className='whitespace-pre-line'>
					{data.collection.details.description}
				</p>
			) : (
				<p className="col-span-3 text-theme-typography-dim italic text-sm pre-wrap">
					There is no description assigned to this token.
				</p>
			)}
		</div>
	);
};

TokenAbout.defaultProps = {
	heading: 'About'
};

export default TokenAbout;
