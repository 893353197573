import BrandStore from 'features/Brand/Store';

const BrandStoreRoute = () => {
	return (
		<div className="p-4">
			<BrandStore />
		</div>
	);
};

export default BrandStoreRoute;
