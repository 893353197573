const KeyIcon = () => (
	<div className="flex flex-row justify-center align-center pb-8">
		<svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M44.1814 20.6181C45.7438 20.6181 47.2421 21.2388 48.3469 22.3435C49.4516 23.4483 50.0723 24.9466 50.0723 26.509M61.8539 26.509C61.8547 29.2693 61.2089 31.9915 59.9682 34.4573C58.7275 36.9231 56.9265 39.0641 54.7094 40.7085C52.4923 42.3529 49.9208 43.4551 47.2011 43.9267C44.4813 44.3984 41.6889 44.2264 39.0476 43.4245L32.3998 50.0723H26.5089V55.9632H20.6181V61.854H11.7818C11.0007 61.854 10.2515 61.5437 9.69912 60.9913C9.14675 60.4389 8.83643 59.6897 8.83643 58.9086V51.2917C8.83659 50.5106 9.14702 49.7616 9.69943 49.2093L27.2659 31.6428C26.5309 29.2129 26.328 26.6531 26.6711 24.1378C27.0141 21.6224 27.8951 19.2104 29.254 17.0661C30.613 14.9218 32.4179 13.0954 34.5461 11.7113C36.6742 10.3271 39.0756 9.41776 41.5868 9.04503C44.0979 8.6723 46.6599 8.84497 49.0983 9.55129C51.5368 10.2576 53.7944 11.481 55.7176 13.1382C57.6407 14.7953 59.1843 16.8474 60.2432 19.1547C61.3021 21.462 61.8515 23.9703 61.8539 26.509Z"
				stroke="#7E3AF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	</div>
);

export default KeyIcon;
