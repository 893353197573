import BrandUnlocks from 'features/Brand/Unlocks';

const BrandUnlocksRoute = () => {
	return (
		<div className="p-4">
			<BrandUnlocks />
		</div>
	);
};

export default BrandUnlocksRoute;
