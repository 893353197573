import clsx from 'clsx';

const LogoWordMark = ({
	color = 'black',
	className,
	...rest
}) => (
	<img className={clsx('fill-current', className)} alt='HexKey Logo' src={`/logos/wordmark_${color}.png`} />
);

export default LogoWordMark;
