import ta from 'time-ago';

/**
 * Had to create this wrapper to fix the annoying ms thing.
 */

const ago = (date) => {
	const now = new Date();
	const then = date instanceof Date ? date : new Date(date);

	if (now - then <= 1000) {
		return 'just now';
	}

	return ta.ago(date);
};

export default ago;
