import clsx from 'clsx';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { useCallback, Fragment } from 'react';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';

const Banner = ({
	src,
	title,
	avatar,
	actions,
	editable,
	verified,
	username,
	className,
	description,
	onAvatarChange,
	onSourceChange
}) => {
	const handleAvatarChange = useCallback(event => {
		const file = event?.target?.files?.[0];

		if (file && onAvatarChange) {
			onAvatarChange({
				type: 'avatar',
				value: file
			});
		}
	}, [onAvatarChange]);

	const handleBannerChange = useCallback(event => {
		const file = event?.target?.files?.[0];

		if (file && onSourceChange) {
			onSourceChange({
				type: 'source',
				value: file
			});
		}
	}, [onSourceChange]);

	return (
		<div className={clsx('bg-theme-header-dimmer xl:rounded-lg overflow-hidden', className)}>
			<div className="w-full aspect-[6/1] bg-theme-placeholder overflow-hidden">
				{src ? (
					<img alt="Banner" className="w-full h-full object-cover object-center" src={src} />
				) : null}
			</div>
			<div className="flex flex-col md:flex-row">
				<Avatar className="self-start ring-4 ring-theme-background -mt-6 ml-4 w-24 xl:-mt-14 xl:w-32" src={avatar} size={null} />
				<div className="flex flex-1 p-4 min-w-0">
					<div className="flex-1 min-w-0">
						<h1 className="text-3xl tracking-tight font-bold md:truncate">{title}</h1>
						<h2 className="font-medium flex items-center">
							{verified ? (
								<CheckBadgeIcon className="w-4 h-4 mr-1 text-theme-primary" />
							) : null}
							{username}
						</h2>
					</div>
					{editable || actions?.length > 0 ? (
						<div className="flex items-center space-x-3 ml-4">
							{editable ? (
								<Fragment>
									<Button className="cursor-pointer" component="label" htmlFor="avatar" variant="neutral">Edit Avatar</Button>
									<Button className="cursor-pointer" component="label" htmlFor="banner" variant="neutral">Edit Banner</Button>
									<input onChange={handleAvatarChange} accept="image/*" name="avatar" id="avatar" type="file" className="sr-only" />
									<input onChange={handleBannerChange} accept="image/*" name="banner" id="banner" type="file" className="sr-only" />
								</Fragment>
							) : null}
							{actions?.length > 0 ? actions : null}
						</div>
					) : null}
				</div>
			</div>
			{description ? (
				<div className="text-sm text-theme-typography-dim max-w-3xl p-4 pt-0">
					{description}
				</div>
			) : null}
		</div>
	);
};

export default Banner;
