import clsx from 'clsx';
import Heading from 'components/Heading';
import Skeleton from 'components/Skeleton';

const WIP = ({
	name,
	className
}) => {
	return (
		<div className={ clsx('p-4', className) }>
			<Heading variant="page" className="mb-4">
				<strong className="inline-flex bg-theme-primary text-white p-2 rounded-lg">WIP</strong> { name }
			</Heading>
			<Skeleton />
		</div>
	);
};

export default WIP;
