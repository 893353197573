import Logo from 'components/Logo';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

const LandingTemplate = () => {
	return (
		<div className="relative text-white h-full flex flex-col bg-black">
			<header className="py-8 px-8 md:px-20 relative z-10">
				<Logo className="h-8 w-auto text-white" variant="text" />
			</header>
			<main className="flex-1 flex items-center relative z-10 bg-gradient-to-r from-black">
				<div className="max-w-2xl py-20 px-8 md:px-20">
					<h1 className="text-5xl font-bold tracking-tight mb-4">Web3 Tools for the Mainstream</h1>
					<p className="text-lg text-slate-400 mb-8">
						Experience the power of Web3 with our easy-to-use tools that are accessible for everyone.
						Don't miss out on the opportunity to take your business to the next level with the latest technology.
					</p>
					<Button className="inline-flex mb-4" component={Link} to="/authorize" size="xl">Get Started</Button>
					{/* Commented out until creator studio is good to go */}
					{/* <p className="text-sm text-slate-400">Looking for the creator studio? <Link className="text-slate-300 font-medium" to="/studio/auth/signin">Sign in</Link></p> */}
				</div>
			</main>
			<footer className="py-8 px-8 md:px-20 relative z-10 text-slate-500 text-sm">
				© {(new Date()?.getFullYear())} Hexkey. All rights reserved.
			</footer>
			<img alt="Hexkey" className="fixed w-full h-full inset-0 object-cover z-0" src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2048&q=80" />
		</div>
	);
};

export default LandingTemplate;
