import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';
import Heading from 'components/Heading';
import Textarea from 'components/Textarea';
import Upload from 'components/Upload';
import Toggle from 'components/Toggle';
import useJSONState from 'hooks/useJSONState';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useNetworksQuery from 'services/api/hooks/useNetworksQuery';
import useCollectionForBrandManagerQuery from 'services/api/hooks/useCollectionForBrandManagerQuery';
import useCreateCollectionMutation from 'services/api/hooks/useCreateCollectionMutation';
import useUpdateCollectionMutation from 'services/api/hooks/useUpdateCollectionMutation';
import useArchiveCollectionMutation from 'services/api/hooks/useArchiveCollectionMutation';
import getErrorMessage from 'utils/getErrorMessage';
import { useEffect, useCallback, useMemo } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

const DashboardCollectionsForm = ({
	className
}) => {
	const meta = useParams();

	const notifications = useNotificationsStore();

	const isEditing = !!meta?.collectionId;

	const { data: networks, isLoading: isLoadingNetworks } = useNetworksQuery();

	const { data, isSuccess, mutate, isLoading } = useCreateCollectionMutation({
		onSuccess: (data) => {
			notifications.success('A new collection was created.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to create collection.'))
		}
	});

	const [form, formActions] = useJSONState(() => ({
		name: '',
		network: '',
		coverUrl: '',
		soulbound: true,
		description: '',
	}));

	const { data: collection, isLoading: isLoadingForm } = useCollectionForBrandManagerQuery({
		meta,
		onSuccess: (data) => {
			formActions.extend({
				name: data?.name ?? '',
				network: data?.details?.network?.id ?? '',
				coverUrl: data?.details?.cover ?? '',
				soulbound: data?.details?.soulbound ?? true,
				description: data?.details?.description ?? '',
			});
		}
	});

	const { mutate: mutateArchive, isLoading: isArchivingForm, isSuccess: isSuccessfulArchive } = useArchiveCollectionMutation({
		collectionId: meta?.collectionId,
		onSuccess: (data) => {
			notifications.success('Collection was archived successfully.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to archive collection.'));
		}
	})

	const { mutate: mutateUpdate, isLoading: isUpdatingForm, isSuccess: isSuccessfulUpdate } = useUpdateCollectionMutation({
		collectionId: meta?.collectionId,
		onSuccess: (data) => {
			notifications.success('Collection was updated successfully.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update collection.'));
		}
	});

	const handleArchiveClick = useCallback(event => {
		event.preventDefault();
		mutateArchive();
	}, [mutateArchive]);

	const handleFormSubmit = useCallback(event => {
		event.preventDefault();

		const body = {
			...form,
			network: parseInt(form.network, 10)
		};

		if (!isEditing) {
			mutate(body);
			return;
		}
		
		const current = {
			name: collection?.name ?? '',
			network: collection?.details?.network?.id ?? '',
			coverUrl: collection?.details?.cover ?? '',
			soulbound: collection?.details?.soulbound ?? true,
			description: collection?.details?.description ?? '',
		};

		const keys = Object.keys(current).filter(key => current[key] !== body[key]);

		if (!keys.length) {
			return;
		}

		mutateUpdate(keys.reduce((data, key) => {
			data[key] = body[key];
			return data;
		}, {}));
	}, [
		form,
		mutate,
		isEditing,
		collection,
		mutateUpdate
	]);

	const isDisabled = useMemo(() => {
		return (
			isLoading ||
			isLoadingNetworks ||
			(isLoadingForm && isEditing) ||
			isUpdatingForm ||
			isArchivingForm ||
			!form.name?.trim() ||
			!form.network
		);
	}, [
		form,
		isLoading,
		isEditing,
		isLoadingForm,
		isUpdatingForm,
		isArchivingForm,
		isLoadingNetworks
	]);

	useEffect(() => {
		const isDisabledNetwork = !!networks?.find(network => network?.disabled && network?.value?.toString() === form?.network?.toString());

		if (isDisabledNetwork) {
			formActions.extend({
				network: ''
			});
		}
	}, [
		form,
		networks,
		formActions
	]);

	/**
	 * Navigate to collections on successful archive.
	 */
	if (isSuccessfulArchive) {
		return (
			<Navigate to="../.." replace />
		);
	}

	/**
	 * Navigate to updated collection on successful update.
	 */
	if (isSuccessfulUpdate) {
		return (
			<Navigate to=".." replace />
		);
	}

	/**
	 * Navigate to created collection on success.
	 */
	if (isSuccess) {
		return (
			<Navigate to={ `../${ data.uuid }` } replace />
		);
	}

	return (
		<div className={ className }>
			<form disabled={ isDisabled } onSubmit={ handleFormSubmit } method="POST" action="/fake-action-for-ios" className="space-y-8 divide-y divide-theme-divider">
				<div className="space-y-8 divide-y divide-theme-divider sm:space-y-5">
					<div className="space-y-6 sm:space-y-5">
						<div>
							<Heading component="h3" variant="section">General</Heading>
							<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
								This information will be displayed publicly so be careful what you share.
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="name" className="sm:mt-px sm:pt-2">
									Name
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input disabled={ isLoadingForm && isEditing } value={ form.name } onChange={ formActions.intercept } id="name" name="name" type="text" className="max-w-lg sm:max-w-xs" placeholder="e.g. Spartan Race Contract" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="network" className="sm:mt-px sm:pt-2">
									Network
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Select disabled={ isLoadingForm && isEditing } required value={ form.network } onChange={ formActions.intercept } id="network" name="network" className="max-w-lg sm:max-w-xs" values={ networks } />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<div className="sm:mt-px sm:pt-2">
									<Label className="mb-1" htmlFor="soulbound">
										Soulbound?
									</Label>
									<p className="text-theme-typography-dim text-sm mb-4 sm:mb-0">
										Tokens created within this collection can only be owned and transferred by a specific address.
									</p>
								</div>
								<Toggle disabled={ isLoadingForm && isEditing } checked={ form.soulbound } name="soulbound" id="soulbound" onChange={ formActions.intercept } className="mt-1 sm:col-span-2 sm:mt-0" />
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="description" className="sm:mt-px sm:pt-2">
									About
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Textarea disabled={ isLoadingForm && isEditing } value={ form.description } onChange={ formActions.intercept } id="description" name="description" rows="6" className="max-w-lg" placeholder="A little bit about your collection of NFTs." />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label className="sm:mt-px sm:pt-2">
									Cover Image
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Upload disabled={ isLoadingForm && isEditing } image name="coverUrl" value={ form.coverUrl } onChange={ formActions.intercept } className="max-w-lg sm:max-w-xs aspect-[1/1]" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="flex justify-end space-x-3">
						<Button component={ Link } to=".." variant="neutral">
							Cancel
						</Button>
						{ isEditing ? (
							<Button onClick={ handleArchiveClick } type="button" variant="neutral">
								Archive
							</Button>
						) : null }
						<Button disabled={ isDisabled } type="submit" variant="primary">
							Save
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default DashboardCollectionsForm;
