import BrandFeed from 'features/Brand/Feed';
import BrandAbout from 'features/Brand/About';
import BrandSocials from 'features/Brand/Socials';

const BrandFeedRoute = () => {
	return (
		<div className="p-4">
			<div className="md:grid md:grid-cols-12 md:gap-8 space-y-8 md:space-y-0">
				<div className="md:col-span-7 space-y-8">
					<BrandFeed />
				</div>
				<div className="md:col-span-5 space-y-8">
					<BrandAbout />
					<BrandSocials />
				</div>
			</div>
		</div>
	);
};

export default BrandFeedRoute;
