import AuthSignInForm from './SignInForm';
import AuthSignUpForm from './SignUpForm';
import AuthSetupForm from './SetupForm';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import { Dialog, Transition } from '@headlessui/react';
import { useState, useCallback, useEffect, Fragment } from 'react';

const AuthDialog = ({
	open,
	onClose
}) => {
	const [view, setView] = useState('signin');

	const handleSignUpRequest = useCallback(() => {
		setView('signup');
	}, []);

	const handleSignInRequest = useCallback(() => {
		setView('signin');
	}, []);

	const handleCloseRequest = useCallback((response) => {
		if (typeof onClose === 'function') {
			onClose(response);
		}
	}, [onClose]);

	const isAuthorized = useIsAuthorized();

	const { data: session, isLoading } = useSessionQuery({
		enabled: !!open
	});

	useEffect(() => {
		if (open && isAuthorized && session?.phoneVerified === 1) {
			handleCloseRequest({
				success: true
			});
		}
	}, [
		handleCloseRequest,
		isAuthorized,
		session,
		open,
	]);

	return (
		<Transition.Root show={ open } as={ Fragment }>
			<Dialog as="div" className="relative z-10" onClose={ handleCloseRequest }>
				<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-theme-backdrop bg-opacity-75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-theme-background p-8 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
								{ isAuthorized ? (
									isLoading || session?.phoneVerified === 1 ? (
										<div>
											<div className="bg-theme-placeholder h-8 rounded-md w-3/4 mb-8" />
											<div className="bg-theme-placeholder h-6 rounded-md w-1/2 mb-1" />
											<div className="bg-theme-placeholder h-6 rounded-md w-full mb-1" />
											<div className="bg-theme-placeholder h-6 rounded-md w-3/4 mb-4" />
											<div className="bg-theme-placeholder h-6 rounded-md w-3/4 mb-1" />
											<div className="bg-theme-placeholder h-6 rounded-md w-1/4" />
										</div>
									) : (
										<AuthSetupForm embedded />
									)
								) : view === 'signin' ? (
									<AuthSignInForm embedded onAccountSignUpRequest={ handleSignUpRequest } />
								) : (
									<AuthSignUpForm embedded onAccountSignInRequest={ handleSignInRequest } />
								) }
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default AuthDialog;
