import ConsumerHeader from 'features/Consumer/Header';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import { Navigate, Outlet } from 'react-router-dom';
import FullscreenLoader from 'components/FullscreenLoader';

const ConsumerTemplate = () => {
	const { isSuccess } = useSessionQuery();
	const isAuthorized = useIsAuthorized();

	if (!isAuthorized) {
		return (
			<Navigate to="/landing" replace />
		);
	}

	if (!isSuccess) {
		return <FullscreenLoader />;
	}

	return (
		<div className="min-h-full">
			<ConsumerHeader />
			<main className="w-full max-w-7xl mx-auto">
				<Outlet />
			</main>
		</div>
	);
};

export default ConsumerTemplate;