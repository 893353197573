import { useQuery } from '@tanstack/react-query'
import getNetworks from '../endpoints/getNetworks';

const useNetworksQuery = (options) => {
	return useQuery(['networks'], getNetworks, {
		...options
	});
};

export default useNetworksQuery;
