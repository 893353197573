import ago from 'utils/ago';
import clsx from 'clsx';
import Avatar from 'components/Avatar';
import PopoutMenu from 'components/PopoutMenu';
import { ChatBubbleLeftIcon, HeartIcon, EllipsisHorizontalIcon, TrashIcon } from '@heroicons/react/24/outline';

const NotificationsListItem = ({
	disableActions,
	disableMenu,
	className,
	username,
	children,
	loading,
	created,
	avatar,
	owner
}) => {
	if (loading) {
		return (
			<li className={ clsx('py-4', className) }>
				<div className="flex">
					<div className="w-10 h-10 bg-theme-placeholder rounded-full mr-4" />
					<div className="flex-1">
						<div className="w-32 mb-1 h-4 bg-theme-placeholder rounded-md" />
						<div className="w-3/4 mb-1 h-4 bg-theme-placeholder rounded-md" />
						<div className="w-1/2 h-4 bg-theme-placeholder rounded-md" />
					</div>
				</div>
			</li>
		);
	}

	return (
		<li className={ clsx('py-4', className) }>
			<div className="flex">
				<Avatar src={ avatar } size="md" className="self-start mr-4" />
				<div className="flex-1">
					<div className="flex items-center justify-between mb-1">
						<div className="flex items-baseline flex-shrink-0">
							<h3 className="text-sm font-semibold tracking-tight mr-2">{ username }</h3>
							<p className="text-xs text-theme-typography-dim">{ ago(created) }</p>
						</div>
						{ owner && !disableMenu ? (
							<PopoutMenu
								className="items-start"
								itemsClassName="top-6 w-32"
								icon={(
									<EllipsisHorizontalIcon className="w-6 h-6 text-theme-typography-dim text-opacity-60" />
								)}
								items={[
									<button key="delete" className="flex w-full items-center px-4 py-2 text-sm hover:bg-theme-menu-hover">
										<TrashIcon className="w-4 h-4 mr-2" />Delete
									</button>,
								]}
							/>
						) : null }
					</div>
					<p className="text-sm">
						{ children }
					</p>
					{ disableActions ? null : (
						<div className="flex items-center space-x-4 text-theme-typography-dim text-opacity-60 mt-2">
							<button title="Replies">
								<ChatBubbleLeftIcon className="w-5" />
							</button>
							<button title="Like">
								<HeartIcon className="w-5" />
							</button>
						</div>
					) }
				</div>
			</div>
		</li>
	);
};

export default NotificationsListItem;
