import { useQuery } from '@tanstack/react-query'
import getUnlock from '../endpoints/getUnlock';

const useUnlockQuery = (options) => {
	const unlockId = options?.meta?.unlockId;

	return useQuery(['unlock', unlockId], getUnlock, {
		...options,
		enabled: !!unlockId
	});
};

export default useUnlockQuery;
