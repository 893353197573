import { useMutation, useQueryClient } from '@tanstack/react-query'
import linkWeb3Account from '../endpoints/linkWeb3Account';

const useLinkWeb3AccountMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(linkWeb3Account, {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.setQueryData(['web3Accounts'], data);
		}
	});
};

export default useLinkWeb3AccountMutation;
