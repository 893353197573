import formatCurrency from 'utils/formatCurrency';
import PopoutMenu from 'components/PopoutMenu';
import ShareDialog from 'components/ShareDialog';
import QueryableListItem from 'components/Queryable/ListItem';
import { PencilIcon, ShareIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useState, useCallback, Fragment } from 'react';

const DashboardCollectionTokenListItem = ({
	id,
	name,
	price,
	banner,
	supply,
	minted,
	loading,
	username,
	viewType,
	description,
	collectionId,
	includeOnStorefront,
	claimable,
}) => {
	const [sharing, setSharing] = useState(false);

	const handleClose = useCallback(() => {
		setSharing(false);
	}, []);

	const handleOpen = useCallback(() => {
		setSharing(true);
	}, []);

	return (
		<Fragment>
			<QueryableListItem
				title={name}
				banner={banner}
				loading={loading}
				viewType={viewType}
				primary={formatCurrency(price, { defaultValue: 'Free' })}
				secondary={supply === 0 ? `Minted ${minted}` : `Minted ${minted} of ${supply}`}
				description={description}
				link={id}
				siteLink={(
					includeOnStorefront
						? <a className='text-theme-primary' href={`/drop/collection/${collectionId}/${id}`}>Public claim link</a>
						: null
				)}
				menu={(
					<PopoutMenu className="items-start" items={[
						<button key="share" onClick={handleOpen} className="flex w-full items-center px-4 py-2 text-sm hover:bg-theme-menu-hover">
							<ShareIcon className="w-4 h-4 mr-2" />Share
						</button>,
						<Link key="edit" component="button" to={id} className="flex w-full items-center px-4 py-2 text-sm hover:bg-theme-menu-hover">
							<PencilIcon className="w-4 h-4 mr-2" />Edit
						</Link>
					]} />
				)}
			/>
			<ShareDialog
				claimable={claimable}
				open={sharing}
				title={name}
				banner={banner}
				username={username}
				onClose={handleClose}
				link={`${window?.location?.origin}/drop/collection/${collectionId}/${id}`}
			/>
		</Fragment>
	);
};

export default DashboardCollectionTokenListItem;
