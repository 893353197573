import RichTextarea from 'components/RichTextarea';
import useCreateBrandPageMessageMutation from 'services/api/hooks/useCreateBrandPageMessageMutation';
import { useState, useCallback } from 'react';

const BrandFeedComposer = ({
	className
}) => {
	const [message, setMessage] = useState('');

	const { mutate, isLoading } = useCreateBrandPageMessageMutation({
		onSuccess: () => {
			setMessage('');
		}
	});

	const handleMessageChange = useCallback(event => {
		if (isLoading) {
			return;
		}

		setMessage(event?.target?.value ?? '');
	}, [isLoading]);

	const handleMessageSubmit = useCallback(event => {
		mutate({
			content: message
		});
	}, [
		mutate,
		message
	]);

	return (
		<RichTextarea
			value={ message }
			onChange={ handleMessageChange }
			onSubmit={ handleMessageSubmit }
			className={ className }
			disableSubmit={ !message }
		/>
	);
};

export default BrandFeedComposer;
