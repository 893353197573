import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import useOptionalTokenQuery from 'services/api/hooks/useOptionalTokenQuery';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from 'components/Button';

const Claimed = () => {
	const navigate = useNavigate();
	const { tokenData = {} } = useOptionalTokenQuery();

	const open = !!tokenData?.collection;

	const onClose = () => { navigate('/collection/tokens') };

	let tokenUrl = '/';

	if (tokenData?.collection) {
		const { details, address } = tokenData.collection;
		const { token } = tokenData;

		tokenUrl = `/t/${details.network.slug}/${address}/${token}`;
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-theme-backdrop bg-opacity-75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-theme-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
								<div className="flex flex-shrink-0 justify-end">
									<button onClick={onClose} type="button" className="inline-flex">
										<XMarkIcon className="h-8 w-8" />
									</button>
								</div>
								<div className='space-y-4'>
									<h2 className='text-lg'>Congratulations</h2>
									<div className='text-sm'>
										On minting your Fintech Nexus Medallion ({tokenData?.metadata?.name}). Visit us at booth #851 with any questions. And stay tuned for all kinds of fun rewards headed your way.
									</div>

									<div className='flex justify-center mt'>
										{
											tokenData?.metadata?.image
												? <img src={tokenData?.metadata?.image} alt={tokenData?.metadata?.name} className="w-4/5 h-4/5 aspect-[1/1] object-cover" />
												: <div className='w-4/5 h-4/5 aspect-[1/1]'></div>
										}
									</div>

									<Button component={Link} to={tokenUrl} size="md" variant="primary" className="w-full py-3">
										Go to Token
									</Button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root >
	)
}

export default Claimed;