import SocialForm from './Form';
import { useCallback } from 'react';

const SocialInput = ({
	className,
	onChange,
	values,
	name,
}) => {
	const handleSocialAdd = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = [...values, SocialForm.getInitialState()];

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});
	}, [
		onChange,
		values,
		name
	]);

	const handleSocialChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = values.map(value => {
			if (value === event.previous) {
				return event.next
			}

			return value;
		});

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});		
	}, [
		onChange,
		values,
		name
	]);

	const handleSocialRemove = useCallback(attribute => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = values.filter(current => current !== attribute);

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});	
	}, [
		onChange,
		values,
		name
	]);

	return (
		<div className={ className }>
			<div className="space-y-2 mb-4">
				{ values?.map((value, index) => (
					<SocialForm
						key={ index }
						value={ value }
						onChange={ handleSocialChange }
						onAddButtonClick={ handleSocialAdd }
						onRemoveButtonClick={ handleSocialRemove }
						disableAddButton={ index !== values?.length - 1 }
						disableRemoveButton={ index === values?.length - 1 }
					/>
				)) }
			</div>
		</div>
	);
};

SocialInput.getInitialState = () => [
	SocialForm.getInitialState()
];

SocialInput.defaultProps = {
	values: SocialInput.getInitialState()
};

export default SocialInput;
