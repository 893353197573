import clsx from 'clsx';

const Button = ({
	component: Component,
	className,
	children,
	variant,
	size,
	...rest
}) => {
	return (
		<Component { ...rest } className={ clsx('flex justify-center border rounded-md shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2', Button.sizes[size], Button.variants[variant], className) }>
			{ children }
		</Component>
	);
};

Button.defaultProps = {
	component: 'button',
	variant: 'primary',
	size: 'md'
};

Button.sizes = {
	xs: 'px-2.5 py-1.5 text-xs',
	sm: 'px-3 py-2 leading-4 text-sm',
	md: 'px-4 py-2 text-sm',
	lg: 'px-4 py-2 text-base',
	xl: 'px-6 py-3 text-base'
};

Button.variants = {
	neutral: [
		'border-theme-button-neutral-border',
		'text-theme-button-neutral-content',
		'focus:ring-theme-button-neutral-focus',
		'bg-theme-button-neutral',
		'hover:bg-theme-button-neutral-hover',
		'disabled:bg-opacity-50',
		'disabled:pointer-events-none'
	].join(' '),
	primary: [
		'border-theme-button-primary-border',
		'text-theme-button-primary-content',
		'focus:ring-theme-button-primary-focus',
		'bg-theme-button-primary',
		'hover:bg-theme-button-primary-hover',
		'disabled:bg-opacity-50',
		'disabled:pointer-events-none'
	].join(' '),
	secondary: [
		'border-theme-button-secondary-border',
		'text-theme-button-secondary-content',
		'focus:ring-theme-button-secondary-focus',
		'bg-theme-button-secondary',
		'hover:bg-theme-button-secondary-hover',
		'disabled:bg-opacity-50',
		'disabled:pointer-events-none'
	].join(' ')
};

export default Button;
