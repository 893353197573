import InlineNavigation from 'components/InlineNavigation';
import { Outlet } from 'react-router-dom';

const navigation = [{
	name: 'Brand',
	path: 'brand'
}, {
	name: 'Profile',
	path: 'profile'
}];

const DashboardSettingsTemplate = () => {
	return (
		<div>
			<InlineNavigation className="2xl:mt-4" paths={ navigation } />
			<Outlet />
		</div>
	);
};

export default DashboardSettingsTemplate;
