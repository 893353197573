import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

const InlineNavigation = ({
	className,
	paths
}) => (
	<nav className={clsx('whitespace-nowrap overflow-x-scroll bg-theme-header-dimmer scrollbar-none xl:rounded-lg', className)}>
		{paths?.map(({ path, name }) => (
			<NavLink key={path} to={path} className={({ isActive }) => clsx(
				'inline-flex items-center p-4 text-sm font-medium hover:text-theme-typography-dim', {
				'text-theme-primary border-theme-primary hover:text-theme-primary': isActive
			}
			)}>
				{name}
			</NavLink>
		))}
	</nav>
);

export default InlineNavigation;
