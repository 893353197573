import client from '../client';

const createLivelyToken = (data = {}) => {
	if (!data?.userId) {
		return Promise.reject(new Error('"userId" must be provided to createLivelyToken endpoint.'))
	}

	return client
		.post('/lively/upload-token', {
			userId: data?.userId,
		})
		.then(response => response?.data)
		;
};

export default createLivelyToken;
