import QueryableList from 'components/Queryable/List';
import useEventTargetValue from 'hooks/useEventTargetValue';
import useCollectionsForBrandManagerQuery from 'services/api/hooks/useCollectionsForBrandManagerQuery';
import DashboardCollectionsListItem from './ListItem';
import { useMemo, Fragment } from 'react';

const DashboardCollectionsList = ({
	className
}) => {
	const [viewType, setViewType] = useEventTargetValue(QueryableList.viewTypes[0]?.value);
	const [searchTerm, setSearchTerm] = useEventTargetValue();
	const [sortMethod, setSortMethod] = useEventTargetValue(QueryableList.sortMethods[0]?.value);
	const { data = {}, isLoading } = useCollectionsForBrandManagerQuery();

	const filteredData = useMemo(() => {
		if (!searchTerm) {
			return data?.collections;
		}

		const term = searchTerm.toLowerCase();

		return data?.collections?.filter(item => {
			return (item?.name?.toLowerCase()?.indexOf(term) ?? -1) !== -1
		});
	}, [
		data,
		searchTerm
	]);

	return (
		<div className={ className }>
			<QueryableList
				viewType={ viewType }
				searchTerm={ searchTerm }
				sortMethod={ sortMethod }
				onViewTypeChange={ setViewType }
				onSearchTermChange={ setSearchTerm }
				onSortMethodChange={ setSortMethod }
				type="collection"
			>
			{ isLoading ? (
				<Fragment>
					<DashboardCollectionsListItem viewType={ viewType } loading />
					<DashboardCollectionsListItem viewType={ viewType } loading />
					<DashboardCollectionsListItem viewType={ viewType } loading />
				</Fragment>
			) : filteredData?.length > 0 ? filteredData?.map((item = {}) => {
				return (
					<DashboardCollectionsListItem
						key={ item.uuid }
						id={ item.uuid }
						name={ item.name }
						type={ item.standard }
						banner={ item?.details?.cover }
						address={ item?.details?.address }
						blockchain={ item?.details?.network?.network }
						description={ item?.details?.description }
						viewType={ viewType } 
					/>
				);
			}) : null }
			</QueryableList>
		</div>
	);
};

export default DashboardCollectionsList;
