import { useQuery } from '@tanstack/react-query'
import getBrandPage from '../endpoints/getBrandPage';

const useBrandPageQuery = (options) => {
	const brandSlug = options?.meta?.brandSlug;

	return useQuery(['brandPage', brandSlug], getBrandPage, {
		...options,
		enabled: !!brandSlug
	});
};

export default useBrandPageQuery;
