import client from '../client';

const updateBrand = (data = {}, options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId)) {
		return Promise.reject(new Error('Unable to edit brand without valid agency/brand id'));
	}

	return client
		.patch(`/agencies/${ agency.id }/brands/${ agency.brandId }`, data)
		.then(response => response?.data)
	;
};

export default updateBrand;
