import { useQuery } from '@tanstack/react-query'
import useAgency from 'features/Auth/hooks/useAgency';
import getUnlockForBrandManager from '../endpoints/getUnlockForBrandManager';

const useUnlockForBrandManagerQuery = (options) => {
	const { id, brandId } = useAgency();
	const unlockId = options?.meta?.unlockId;

	return useQuery(['unlockManager', id, brandId, unlockId], getUnlockForBrandManager, {
		...options,
		enabled: !!(id && brandId && unlockId),
		meta: {
			agency: {
				id,
				brandId
			},
			...options?.meta,
		},
	});
};

export default useUnlockForBrandManagerQuery;
