import Heading from 'components/Heading';
import BackToSignIn from './components/BackToSignIn';
import EmailIcon from './components/EmailIcon';
import ResendForgotPasswordEmail from './components/ResendForgotPasswordEmail';

const ForgotPassword = () => (
	<>
		<EmailIcon />

		<Heading className="text-center">Let us know it’s you</Heading>
		<hr className="my-6 border-t border-gray-200" />
		<p className="font-light mt-4">
			We have sent an email to name@email.com to recover your password. After receiving the email, follow the link provided to complete resetting your password.
		</p>

		<hr className="my-6 border-t border-gray-200" />

		<ResendForgotPasswordEmail />
		<BackToSignIn />
	</>
);

export default ForgotPassword;
