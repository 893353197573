import { Fragment } from 'react';
import { Transition } from '@headlessui/react'
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import Alert from 'components/Alert';

const UINotifications = () => {
	const notifications = useNotificationsStore();

	return (
		<div className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6">
			<div className="flex w-full flex-col items-end space-y-4">
				{ notifications.data.map(notification => (
					<Transition
						as={ Fragment }
						key={ notification.id }
						show
						appear
						enter="transform ease-out duration-300 transition"
						enterFrom="translate-y-2 opacity-0"
						enterTo="translate-y-0 opacity-100"
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
					{ (ref) => <Alert { ...notification } ref={ ref } /> }
					</Transition>
				)) }
			</div>
		</div>
	);
};

export default UINotifications;
