import clsx from 'clsx';
import { Link } from 'react-router-dom';

const QueryableListItem = ({
	link,
	siteLink,
	menu,
	title,
	banner,
	primary,
	loading,
	viewType,
	secondary,
	description,
}) => {
	return (
		<div className={clsx('flex min-w-0 group', {
			'flex-col': viewType === VIEW_TYPE_GRID,
		})}>
			{viewType === VIEW_TYPE_GRID ? (
				<div className="flex items-start mb-2">
					{loading ? (
						<div className="rounded-md bg-theme-placeholder h-6 w-full" />
					) : (
						<h3 className="flex-1 font-bold tracking-tight truncate mr-4">{title}</h3>
					)}
					{loading ? null : menu}
				</div>
			) : null}
			<Link to={link} className={clsx('rounded-md bg-theme-placeholder overflow-hidden', {
				'w-32 h-32 mr-4': viewType === VIEW_TYPE_LIST,
				'w-full aspect-[1/1] mb-2': viewType === VIEW_TYPE_GRID
			})}>
				{banner ? <img src={banner} alt={title} className="w-full h-full object-cover" /> : null}
			</Link>
			{loading ? (
				<div className={clsx('space-y-2', {
					'w-1/2': viewType === VIEW_TYPE_LIST,
					'flex-1': viewType === VIEW_TYPE_GRID
				})}>
					<div className="rounded-md bg-theme-placeholder h-4 w-full" />
					<div className="rounded-md bg-theme-placeholder h-4 w-1/2" />
					<div className="rounded-md bg-theme-placeholder h-4 w-3/4" />
				</div>
			) : (
				<div className="flex-1 min-w-0">
					{viewType === VIEW_TYPE_LIST ? (
						<h3 className="font-bold tracking-tight">{title}</h3>
					) : null}
					<p className="text-base font-semibold text-theme-primary">{primary}</p>
					<p className="text-sm font-medium">{secondary}</p>
					<p className={clsx('text-sm text-theme-typography-dim line-clamp-3 max-w-2xl', {
						'italic': !description
					})}>{description || 'No description was provided for this item.'}</p>

					{
						siteLink && (
							<p className={'text-sm text-theme-typography-dim line-clamp-3 max-w-2xl'}>
								{siteLink}
							</p>
						)
					}
				</div>
			)}
			{viewType === VIEW_TYPE_LIST && !loading ? menu : null}
		</div>
	);
};

const VIEW_TYPE_GRID = 'grid';
const VIEW_TYPE_LIST = 'list';

export default QueryableListItem;
