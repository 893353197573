import { useQuery } from '@tanstack/react-query'
import getUserUnlocks from '../endpoints/getUserUnlocks';

const useUserUnlocksQuery = (options) => {
	return useQuery(['userUnlocks'], getUserUnlocks, {
		...options
	});
};

export default useUserUnlocksQuery;
