import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useBrandPageQuery from 'services/api/hooks/useBrandPageQuery';
import Heading from 'components/Heading';
import BrandFeedMessages from './Messages';
import BrandFeedComposer from './Composer';

const BrandFeed = ({
	owner,
	heading,
	className,
	brandSlug,
	disableHeading
}) => {
	const { data = {} } = useBrandPageQuery(useRouteParamsAsMeta({
		brandSlug
	}));

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			{ owner ? (
				<BrandFeedComposer className="mb-4" />
			) : null }
			<BrandFeedMessages
				owner={ owner }
				brandName={ data?.name }
				brandPageId={ data?.uuid }
			/>
		</div>
	);
};

BrandFeed.defaultProps = {
	heading: 'Discussion'
};

export default BrandFeed;
