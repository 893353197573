import { Link } from 'react-router-dom';

const ConsumerUnlocksListItem = ({
	id,
	type,
	title,
	banner,
	loading,
	brandSlug,
	className,
	description
}) => {
	if (loading) {
		return (
			<div className={ className }>
				<div className="flex">
					<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-48 md:h-48 mr-4 overflow-hidden" />
					<div className="flex-1 max-w-lg">
						<div className="bg-theme-placeholder rounded-md h-4 w-3/4 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/4 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/2" />
					</div>
				</div>
			</div>
		);
	}

	return (
		<Link to={ `/c/${ brandSlug }/unlocks/${ id }` } className={ className }>
			<div className="flex">
				<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-48 md:h-48 mr-4 overflow-hidden">
					{ banner ? (
						<img className="w-full h-full object-cover" alt={ title} src={ banner } />
					) : null }
				</div>
				<div className="flex-1">
					<h3 className="font-bold tracking-tight mb-1">{ title }</h3>
					<p className="text-sm text-theme-typography-dim max-w-lg line-clamp-3">{ description }</p>
				</div>
			</div>
		</Link>
	);
}

export default ConsumerUnlocksListItem;
