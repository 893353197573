import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import updateUnlock from '../endpoints/updateUnlock';

const useUpdateUnlockMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(data => updateUnlock(data, {
		agency,
		unlockId: options?.unlockId
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.setQueryData(['unlockManager', agency.id, agency.brandId, data.uuid], data);

			queryClient.setQueryData(['unlocksManager', agency.id, agency.brandId], (state) => {
				return {
					...state,
					unlocks: state?.unlocks?.map(unlock => {
						if (unlock.uuid !== data.uuid) {
							return unlock;
						}

						return data;
					})
				};
			});
		}
	});
};

export default useUpdateUnlockMutation;
