import { Link } from 'react-router-dom';

const ConsumerTokensListItem = ({
	id,
	href,
	title,
	banner,
	loading,
	address,
	network,
	className,
	attributes,
	description
}) => {
	if (loading) {
		return (
			<div className={ className }>
				<div className="flex md:flex-col">
					<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-full md:aspect-[1/1] mr-4 overflow-hidden md:mr-0 md:mb-4 md:h-auto" />
					<div className="flex-1 max-w-lg">
						<div className="bg-theme-placeholder rounded-md h-4 w-3/4 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/4 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/2" />
					</div>
				</div>
			</div>
		);
	}

	return (
		<Link to={ `/t/${ network }/${ address }/${ id }` } className="flex md:flex-col">
			<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-full md:aspect-[1/1] mr-4 overflow-hidden md:mr-0 md:mb-4 md:h-auto">
				{ banner ? (
					<img className="object-cover object-center w-full h-full" alt={ title } src={ banner } />
				) : null }
			</div>
			<div className="flex-1">
				<h3 className="font-bold tracking-tight mb-1 truncate">{ title }</h3>
				<p className="text-sm text-theme-typography-dim line-clamp-3">{ description }</p>
			</div>
		</Link>
	);
}

export default ConsumerTokensListItem;
