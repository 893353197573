import ConsumerHeader from 'features/Consumer/Header';
import { Outlet } from 'react-router-dom';

const DropTemplate = () => {
	return (
		<div className="min-h-full">
			<ConsumerHeader />
			<main className="w-full max-w-7xl mx-auto">
				<Outlet />
			</main>
		</div>
	);
};

export default DropTemplate;
