import { useMemo, useState, useCallback } from 'react';

const useJSONState = (initialState = {}) => {
	const [json, set] = useState(initialState);

	const reset = useCallback(() => {
		if (typeof initialState === 'function') {
			set(initialState());
		} else {
			set(initialState);
		}
	}, [initialState]);

	const assign = useCallback((key, value) => {
		set({
			...json,
			[key]: value
		});
	}, [json]);

	const extend = useCallback(nextState => {
		set({
			...json,
			...nextState
		});
	}, [json]);

	const intercept = useCallback(event => {
		const { target = {} } = event ?? {};
		const { name, type, value, checked, tagName } = target;

		if (!name) {
			console.warn('Unable to intercept value. No name attribute provided in event.');
			return;
		}

		switch (tagName?.toLowerCase()) {
			case 'input':
			case 'select':
			case 'textarea':
				break;
			default:
				console.warn('Unable to intercept value. Unknown tagName (%s)', tagName);
				return;
		};

		set({
			...json,
			[name]: type?.toLowerCase() === 'checkbox' ? !!checked : value
		});
	}, [json]);

	const actions = useMemo(() => ({
		set,
		reset,
		assign,
		extend,
		intercept
	}), [
		set,
		reset,
		assign,
		extend,
		intercept
	]);

	return useMemo(() => [
		json,
		actions
	], [
		json,
		actions
	]);
};

export default useJSONState;
