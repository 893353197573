import clsx from 'clsx';

const Heading = ({
	component: Component,
	className,
	children,
	variant
}) => {
	return (
		<Component className={clsx('tracking-tight font-bold', Heading.variants[variant], className)}>
			{children}
		</Component>
	);
};

Heading.variants = {
	page: 'text-3xl',
	section: 'text-lg',
	responsive: 'text-xl xl:text-3xl'
};

Heading.defaultProps = {
	component: 'h2',
	variant: 'page'
};

export default Heading;
