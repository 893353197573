import { useMutation, useQueryClient } from '@tanstack/react-query'
import unlinkWeb3Account from '../endpoints/unlinkWeb3Account';

const useUnlinkWeb3AccountMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(unlinkWeb3Account, {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.setQueryData(['web3Accounts'], data);
		}
	});
};

export default useUnlinkWeb3AccountMutation;
