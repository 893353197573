import { useQuery } from '@tanstack/react-query'
import getAccountInvite from '../endpoints/getAccountInvite';

const useAccountInviteQuery = (options) => {
	const code = options?.meta?.code;

	return useQuery(['accountInvite', code], getAccountInvite, {
		...options,
		enabled: !!(code && (typeof options?.enabled === 'boolean' ? options.enabled : true)),
		retry: false,
	});
};

export default useAccountInviteQuery;
