import Input from 'components/Input';
import { useCallback } from 'react';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';

const AttributesForm = ({
	onRemoveButtonClick,
	onAddButtonClick,
	disableAddButton,
	disableRemoveButton,
	className,
	onChange,
	value,
}) => {
	const handleNameChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		onChange({
			previous: value,
			next: {
				name: event?.target?.value ?? '',
				value: value?.value ?? ''
			}
		});
	}, [
		onChange,
		value,
	]);

	const handleValueChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		onChange({
			previous: value,
			next: {
				name: value?.name ?? '',
				value: event?.target?.value ?? ''
			}
		});
	}, [
		onChange,
		value
	]);

	const handleRemoveButtonClick = useCallback(() => {
		if (typeof onRemoveButtonClick !== 'function') {
			return;
		}

		onRemoveButtonClick(value);
	}, [
		onRemoveButtonClick,
		value
	]);

	return (
		<div className={ className }>
			<div className="grid grid-cols-3 gap-4">
				<div className="span-1">
					<Input id="name" name="name" type="text" value={ value?.name ?? '' } onChange={ handleNameChange } placeholder="Attribute name (e.g, color)" />
				</div>
				<div className="col-span-2">
					<div className="flex items-center">
						<Input id="value" name="value" type="text" className="flex-1" value={ value?.value ?? '' } onChange={ handleValueChange } placeholder="Attribute value (e.g, blue)" />
						{ disableRemoveButton ? null : (
							<button className="ml-2" type="button" onClick={ handleRemoveButtonClick }>
								<MinusCircleIcon className="w-6 h-6 text-theme-typography-dim" />
							</button>
						) }
						{ disableAddButton ? null : (
							<button className="ml-2" type="button" disabled={ !(value?.name && value?.value) } onClick={ onAddButtonClick }>
								<PlusCircleIcon className="w-6 h-6 text-theme-typography-dim" />
							</button>
						) }
					</div>
				</div>
			</div>
		</div>
	);
};

AttributesForm.getInitialState = () => ({
	name: '',
	value: ''
});

AttributesForm.defaultProps = {
	value: AttributesForm.getInitialState()
};

export default AttributesForm;
