import Heading from 'components/Heading';
import DashboardSettingsProfileForm from 'features/Dashboard/Settings/ProfileForm';
import DashboardSettingsProfileSecurityForm from 'features/Dashboard/Settings/ProfileSecurityForm';

const DashboardSettingsProfileRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Profile</Heading>
			<DashboardSettingsProfileForm className="mb-10" />
			<DashboardSettingsProfileSecurityForm />
		</div>
	);
};

export default DashboardSettingsProfileRoute;
