import getToken from '../endpoints/getToken';
import { useQuery } from '@tanstack/react-query';

const useTokenQuery = (options) => {
	const network = options?.meta?.network;
	const address = options?.meta?.address;
	const token = options?.meta?.token;

	return useQuery(['token', network, address, token], getToken, {
		...options,
		enabled: !!(network && address && token)
	});
};

export default useTokenQuery;
