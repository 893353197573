import clsx from 'clsx';
import useDragDrop from 'hooks/useDragDrop';
import useUploadMutation from 'services/api/hooks/useUploadMutation';
import { CloudIcon } from '@heroicons/react/24/outline';
import { useState, useEffect, useCallback, useMemo, Fragment } from 'react';

const Upload = ({
	acceptText,
	className,
	onChange,
	accept,
	image,
	value,
	name,
}) => {
	const id = useMemo(() => `file-upload-${Math.ceil(Date.now() * Math.random())}`, []);

	const [progress, onProgress] = useState({});
	const [previewImageURI, setPreviewImageURI] = useState(null);

	const onSuccess = useCallback(({ uri: value, file }) => {
		if (typeof onChange !== 'function') {
			return;
		}

		onChange({
			target: {
				name,
				file,
				value,
				tagName: 'input',
			},
		});
	}, [
		name,
		onChange,
	]);

	const { mutate, isLoading, variables } = useUploadMutation({
		onProgress,
		onSuccess,
	});

	const dragDropEventHandlers = useDragDrop(async (files) => {
		await handleFileChange(files?.[0]);
		mutate(files?.[0]);
	});

	const createVideoThumbnail = async (file) => {
		return new Promise((resolve, reject) => {
			const video = document.createElement('video');
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');

			const captureFrame = () => {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, canvas.width, canvas.height);
				canvas.toBlob((blob) => {
					resolve(URL.createObjectURL(blob));
				}, 'image/jpeg', 0.95);
			};

			video.addEventListener('loadeddata', () => {
				video.currentTime = 0; // You can change this to capture a different frame of the video
			});

			video.addEventListener('seeked', captureFrame);

			video.addEventListener('error', (err) => {
				reject(err);
			});

			video.src = URL.createObjectURL(file);
		});
	};


	const handleFileChange = useCallback(async (file) => {
		const fileType = file?.type ?? '';

		if (!file) {
			setPreviewImageURI(null);
			return;
		}

		if (fileType.indexOf('image/') === 0) {
			if (!window?.URL) {
				setPreviewImageURI(null);
				return;
			}
			setPreviewImageURI(URL.createObjectURL(file));
			return;
		}

		if (fileType.indexOf('video/') === 0) {
			try {
				const thumbnail = await createVideoThumbnail(file);
				setPreviewImageURI(thumbnail);
			} catch (err) {
				console.error('Error generating video thumbnail:', err);
			}
			return;
		}

		setPreviewImageURI(null);
	}, []);

	const handleChange = useCallback(async (event) => {
		await handleFileChange(event?.target?.files?.[0]);
		mutate(event?.target?.files?.[0]);
	}, [mutate, handleFileChange]);

	const imageSrc = useMemo(() => {
		if (previewImageURI) {
			return previewImageURI;
		}

		if (image && value) {
			return value;
		}

		return null
	}, [
		previewImageURI,
		value,
		image,
	]);

	useEffect(() => {
		if (!previewImageURI) {
			return;
		}

		return () => URL.revokeObjectURL(previewImageURI);
	}, [previewImageURI]);

	return (
		<div {...dragDropEventHandlers} className={clsx('group relative rounded-md border-2 border-dashed border-theme-divider overflow-hidden', className)}>
			<div className="w-full h-full flex justify-center items-center flex-col p-6 min-w-0">
				<CloudIcon className="mx-auto h-8 w-8 text-theme-typography-dim" />
				{isLoading ? (
					<Fragment>
						<p className="text-sm font-medium truncate w-full text-center mb-2">
							{progress?.value === 100 ? 'Finalizing...' : `Transferring ${variables?.name ?? 'file'}...`}
						</p>
						<div className="w-full h-2 bg-theme-placeholder rounded-full overflow-hidden">
							<div className="bg-theme-primary h-full rounded-full transition-[width] ease-in-out duration-300" style={{ width: `${progress?.value ?? 0}%` }} />
						</div>
					</Fragment>
				) : (
					<Fragment>
						<p className="text-sm font-medium">
							<span className="text-theme-primary font-semibold">Browse</span> or use drag and drop
						</p>
						<p className="text-xs text-theme-typography-dim">{acceptText}</p>
						<input type="file" id={id} onChange={handleChange} accept={accept} className="absolute inset-0 opacity-0 cursor-pointer" />
					</Fragment>
				)}
			</div>
			{!isLoading && imageSrc ? (
				<img src={imageSrc} className="absolute w-full h-full top-0 left-0 object-cover group-hover:opacity-0 transition-opacity pointer-events-none bg-theme-background" alt={variables?.name ?? 'Preview Image'} />
			) : null}
		</div>
	);
};

Upload.defaultProps = {
	accept: 'image/*',
	acceptText: 'Image and video files up to 10mb',
};

export default Upload;
