import Button from 'components/Button';
import { Link } from 'react-router-dom';

const ErrorIndexRoute = ({
	children
}) => {
	return (
		<div className="min-h-full flex items-center justify-center">
			<div className="p-8 space-y-8 sm:max-w-xl">
				<div className="flex items-center">
					<span className="text-9xl tracking-tight font-bold">4</span>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-28 h-auto">
						<path fill="currentColor" d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z" />
						<rect width="176" height="32" x="168" y="320" fill="currentColor" />
						<polygon fill="currentColor" points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042" />
						<polygon fill="currentColor" points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63" />
					</svg>
					<span className="text-9xl tracking-tight font-bold">4</span>
				</div>		
				<p className="text-2xl font-semibold">
					Sorry, we couldn't find this page.
				</p>
				<p className="text-base text-theme-typography-dim">
					But don't worry, you can find plenty of other great things from our home page.
				</p>
				<Button component={ Link } to="/" variant="primary" size="xl">
					Go Home
				</Button>
			</div>
		</div>
	);
};

export default ErrorIndexRoute;
