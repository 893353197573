import Heading from 'components/Heading';
import ConsumerUnlocksListItem from './ListItem';
import useUserUnlocksQuery from 'services/api/hooks/useUserUnlocksQuery';

const ConsumerUnlocksList = ({
	heading,
	className,
	disableHeading
}) => {
	const { data, isLoading } = useUserUnlocksQuery();

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			{ isLoading ? (
				<div className="grid gap-4">
					<ConsumerUnlocksListItem loading />
					<ConsumerUnlocksListItem loading />
					<ConsumerUnlocksListItem loading />
				</div>
			) : (
				<div className="grid gap-4">
					{ data?.unlocks?.length > 0 ? data.unlocks.map(unlock => (
						<ConsumerUnlocksListItem
							id={ unlock?.uuid }
							key={ unlock?.uuid }
							type={ unlock?.privateContext?.type }
							title={ unlock?.title }
							banner={ unlock?.coverImageUrl }
							brandSlug={ unlock?.brand?.slug }
							description={ unlock?.description }
						/>
					)) : (
						<div className="col-span-3 text-theme-typography-dim italic">
							You've yet obtain any unlocks.
						</div>
					) }
				</div>
			) }
		</div>
	);
};

ConsumerUnlocksList.defaultProps = {
	heading: 'Unlocks'
};

export default ConsumerUnlocksList;
