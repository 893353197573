import client from '../client';

const createUnlock = (data = {}, options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId)) {
		return Promise.reject(new Error('Unable to create unlock without valid agency/brand id'));
	}

	return client
		.post(`/agencies/${ agency.id }/brands/${ agency.brandId }/unlocks`, data)
		.then(response => response?.data)
	;
};

export default createUnlock;
