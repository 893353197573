import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useAuthStore from 'state/hooks/useAuthStore';
import useIsAuthorized from "hooks/useIsAuthorized";

const SignOut = () => {
	const { deauthorize } = useAuthStore();
	const isAuthorized = useIsAuthorized();

	useEffect(() => {
		if (isAuthorized) {
			deauthorize();
		}
	}, [
		isAuthorized,
		deauthorize
	]);

	if (isAuthorized) {
		return null;
	}

	return (
		<Navigate to='/studio/auth/signin' replace />
	);
};

export default SignOut;
