import client from '../client';

const archiveUnlock = (options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId && options?.unlockId)) {
		return Promise.reject(new Error('Unable to archive unlock without valid agency/brand/unlock id'));
	}

	return client
		.patch(`/agencies/${ agency.id }/brands/${ agency.brandId }/unlocks/${ options.unlockId }/archive`)
		.then(response => response?.data)
	;
};

export default archiveUnlock;
