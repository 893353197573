import { useMemo, useRef, useEffect, useCallback } from 'react';

const useDragDrop = (callback) => {
	const fn = useRef(null);

	const onDrop = useCallback(event => {
		const files = [];

		event.preventDefault();

		if (event.dataTransfer.items) {
			[...event.dataTransfer.items].forEach(item => {
				if (item.kind === 'file') {
					files.push(item.getAsFile());
				}
			});
		} else if (event.dataTransfer.files) {
			[...event.dataTransfer.files].forEach(file => {
				files.push(file);
			});
		}

		if (fn.current) {
			fn.current(files);
		}
	}, []);

	const onDragOver = useCallback(event => {
		event.preventDefault();
	}, []);

	useEffect(() => {
		fn.current = typeof callback === 'function' ? callback : null;
	}, [callback]);

	return useMemo(() => ({
		onDrop,
		onDragOver
	}), [
		onDrop,
		onDragOver
	]);
};

export default useDragDrop;
