import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useCollectionQuery from 'services/api/hooks/useCollectionQuery';
import Heading from 'components/Heading';


const DropMedia = ({
	heading,
	className,
	disableHeading
}) => {
	const videoRef = useRef();
	const meta = useParams();

	const { data = {} } = useCollectionQuery({
		requireTemplateId: true,
		meta
	});

	useEffect(() => {
		if (videoRef?.current) {
			const p = videoRef.current.play();
			if (p) {
				p.catch((_) => {
					videoRef.current.muted = true;
					videoRef.current.play().catch((_) => { });
				});
			}
		}
	}, [videoRef]);

	return (
		<div className={className}>
			{disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{heading}
				</Heading>
			)}
			<div className="bg-theme-placeholder rounded-lg aspect-[1/1] overflow-hidden">
				{data?.template?.videoUrl ? (
					<video
						ref={videoRef}
						src={data.template.videoUrl}
						alt={data?.template?.title}
						poster={data?.metadata?.image}
						className="object-cover object-center w-full h-full"
						playsInline
						muted
						autoPlay
						loop
					/>
				) : data?.template?.imageUrl ? (
					<img src={data.template.imageUrl} alt={data?.template?.title} className="object-cover object-center w-full h-full" />
				) : null}
			</div>
		</div>
	);
};

DropMedia.defaultProps = {
	heading: 'Media'
};

export default DropMedia;
