import Heading from 'components/Heading';
import DashboardCollectionsForm from 'features/Dashboard/Collections/Form';

const DashboardCollectionsEditIndexRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Edit Collection</Heading>
			<DashboardCollectionsForm />
		</div>
	);
};

export default DashboardCollectionsEditIndexRoute;
