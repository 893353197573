import clsx from 'clsx';

const Input = ({
	icon: Icon,
	iconTrailing,
	classNameInput,
	className,
	variant,
	prefix,
	...rest
}) => {
	return (
		<div className={ clsx('relative rounded-md shadow-sm', {
			'flex': !!prefix,
			'opacity-50': !!rest?.disabled
		}, className) }>
			{ prefix ? (
				<span className={ clsx('inline-flex items-center select-none rounded-l-md border border-r-0 px-3 sm:text-sm', Input.variants[variant]?.prefix) }>
					{ prefix }
				</span>
			) : null }
			<input
				{ ...rest }
				className={ clsx('appearance-none block w-full px-3 py-2 border rounded-md focus:outline-none sm:text-sm focus:ring-1', Input.variants[variant]?.base, {
					'pr-10': !prefix && Icon && iconTrailing,
					'pl-10': !prefix && Icon && !iconTrailing,
					'rounded-l-none': !!prefix
				}, classNameInput) }
			/>
			{ !prefix && Icon ? (
				<div className={ clsx('pointer-events-none absolute inset-y-0 flex items-center', {
					'right-0 pr-3': iconTrailing,
					'left-0 pl-3': !iconTrailing
				}) }>
					<Icon className="h-5 w-5 text-theme-input-primary-placeholder" />
				</div>
			) : null }
		</div>
	);
};

Input.defaultProps = {
	variant: 'primary'
};

Input.variants = {
	primary: {
		base: [
			'bg-theme-input-primary',
			'border-theme-input-primary-border',
			'text-theme-input-primary-content',
			'placeholder-theme-input-primary-placeholder',
			'focus:ring-theme-input-primary-focus',
			'focus:border-theme-input-primary-focus'
		],
		prefix: [
			'bg-theme-input-primary-prefix',
			'border-theme-input-primary-border',
			'text-theme-input-primary-placeholder'
		]
	}
};

export default Input;
