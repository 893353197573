import clsx from 'clsx';
import Avatar from '../Avatar';
import Button from '../Button';
import useUploadMutation from 'services/api/hooks/useUploadMutation';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useMemo, useEffect, useCallback } from 'react';

const UploadAvatar = ({
	className,
	onChange,
	value,
	name
}) => {
	const id = useMemo(() => `file-upload-${ Math.ceil(Date.now() * Math.random()) }`, []);
	
	const onSuccess = useCallback(({ uri: value }) => {
		if (typeof onChange !== 'function') {
			return
		}

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});
	}, [
		name,
		onChange
	]);
	
	const { mutate, isLoading, variables } = useUploadMutation({
		onSuccess
	});

	const handleChange = useCallback(event => {
		mutate(event?.target?.files?.[0]);
	}, [mutate]);

	const previewImageURI = useMemo(() => {
		const fileType = variables?.type ?? '';

		if (fileType.indexOf('image/') !== 0) {
			return null;
		}

		if (!window?.URL) {
			return null;
		}

		return URL.createObjectURL(variables);
	}, [variables]);

	const imageSrc = useMemo(() => {
		return previewImageURI ?? value ?? null;
	}, [
		previewImageURI,
		value
	])

	useEffect(() => {
		if (!previewImageURI) {
			return;
		}

		return () => URL.revokeObjectURL(previewImageURI);
	}, [previewImageURI]);

	return (
		<div className={ clsx('flex items-center', className) }>
			<div className={ clsx('mr-2 flex items-center justify-center', {
				'bg-gray-600 rounded-full': isLoading
			}) }>
				<Avatar className={ isLoading ? 'opacity-20' : null } outlined src={ imageSrc } size="lg" />
				{ isLoading ? (
					<ArrowPathIcon className="w-5 animate-spin absolute text-white" />
				) : null }
			</div>
			<Button className="relative" variant="neutral" size="sm">
				Change
				<input onChange={ handleChange } id={ id } accept="image/*" name="file-upload" type="file" className="absolute inset-0 opacity-0 cursor-pointer" />
			</Button>
		</div>
	);
};

export default UploadAvatar;
