import Logo from 'components/Logo';
import Avatar from 'components/Avatar';
import Prompt from 'components/Prompt';
import useProfileQuery from 'services/api/hooks/useProfileQuery';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import useAuthStore from 'state/hooks/useAuthStore';
import { Link } from 'react-router-dom';
import { useMemo, useState, useCallback, Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const navigation = [{
	name: 'Collection',
	path: '/collection'
}, {
	name: 'Settings',
	path: '/settings'
}];

const ConsumerHeader = ({
	className
}) => {
	const { data } = useProfileQuery();

	const { data: session } = useSessionQuery();

	const { token, deauthorize } = useAuthStore();

	const [showSignOutPrompt, setShowSignOutPrompt] = useState(false);

	const handleSignOutClick = useCallback(() => {
		setShowSignOutPrompt(true);
	}, []);

	const handleSignOutDismiss = useCallback(() => {
		setShowSignOutPrompt(false);
	}, []);

	const isBrandManager = useMemo(() => {
		return (session?.permissions?.indexOf('manage-brand') ?? -1) !== -1;
	}, [session]);

	return (
		<Disclosure as="header">
			{({ open }) => (
				<Fragment>
					<div className="max-w-7xl mx-auto h-16 p-4 space-x-4 flex items-center">
						<Link to="/collection" className="h-full">
							<Logo className="h-full w-auto text-theme-palette-primary fill-current" />
						</Link>
						<div className="flex flex-1 justify-end items-center h-full space-x-4">
							{token ? (
								<Avatar component={Link} to="/settings/profile" src={data?.profilePictureUrl} size="xs" />
							) : null}
							{ /** Mobile Menu Button **/}
							<Disclosure.Button className="sm:hidden focus:outline-none">
								{open ? (
									<XMarkIcon className="h-6 w-auto" />
								) : (
									<Bars3Icon className="h-6 w-auto" />
								)}
							</Disclosure.Button>
							{ /** Desktop Menu **/}
							<Menu as="div" className="hidden sm:inline-flex relative">
								{ /** Desktop Menu Button **/}
								<Menu.Button className="focus:outline-none">
									<Bars3Icon className="h-6 w-auto" />
								</Menu.Button>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									{ /** Desktop Menu Items **/}
									<Menu.Items className="overflow-hidden absolute right-0 w-64 top-8 origin-top-right rounded-md ring-1 ring-theme-menu-focus ring-opacity-20 bg-theme-menu shadow-lg">
										{token ? (
											<div className="p-4 border-b border-theme-divider">
												<p className="text-sm font-bold">Account Owner</p>
												<p className="text-sm text-theme-typography-dim">{data?.email?.email}</p>
											</div>
										) : null}
										{token ? (
											<div>
												{navigation.map(({ path, name }, index) => (
													<Menu.Item key={index}>
														<Link className="block px-4 py-2 text-sm hover:bg-theme-menu-hover" to={path}>{name}</Link>
													</Menu.Item>
												))}
												{isBrandManager ? (
													<Menu.Item>
														<Link to="/studio" className="block w-full text-left px-4 py-2 text-sm hover:bg-theme-menu-hover">
															Studio
														</Link>
													</Menu.Item>
												) : null}
												<Menu.Item>
													<button onClick={handleSignOutClick} className="block w-full text-left px-4 py-2 text-sm hover:bg-theme-menu-hover">
														Sign Out
													</button>
												</Menu.Item>
											</div>
										) : (
											<div>
												<Menu.Item>
													<Link className="block px-4 py-2 text-sm hover:bg-theme-menu-hover" to="/authorize">Sign In</Link>
												</Menu.Item>
											</div>
										)}
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
					</div>
					{ /** Mobile Menu Items **/}
					<Disclosure.Panel className="bg-theme-menu-mobile sm:hidden">
						{token ? (
							<div className="p-4 border-b border-theme-divider">
								<p className="text-sm font-bold">Account Owner</p>
								<p className="text-sm text-theme-typography-dim">{data?.email?.email}</p>
							</div>
						) : null}
						{token ? (
							<div className="py-2">
								{navigation.map(({ path, name }, index) => (
									<Disclosure.Button className="block px-4 py-2 text-sm font-medium" as={Link} to={path} key={index}>
										{name}
									</Disclosure.Button>
								))}
								{isBrandManager ? (
									<Disclosure.Button className="block px-4 py-2 text-sm font-medium" as={Link} to="/studio">
										Studio
									</Disclosure.Button>
								) : null}
								<Disclosure.Button onClick={handleSignOutClick} className="w-full text-left block px-4 py-2 text-sm font-medium" as="button">
									Sign Out
								</Disclosure.Button>
							</div>
						) : (
							<div className="py-2">
								<Disclosure.Button className="block px-4 py-2 text-sm font-medium" as={Link} to="/authorize">
									Sign In
								</Disclosure.Button>
							</div>
						)}
					</Disclosure.Panel>
					<Prompt
						open={showSignOutPrompt}
						onClose={handleSignOutDismiss}
						severity="high"
						title="Sign Out"
						primaryAction="Confirm"
						onPrimaryAction={deauthorize}>
						Are you sure you want to sign out of your account?
					</Prompt>
				</Fragment>
			)}
		</Disclosure>
	);
};

export default ConsumerHeader;
