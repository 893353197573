import { useQuery } from '@tanstack/react-query'
import getWeb3Accounts from '../endpoints/getWeb3Accounts';

const useWeb3AccountsQuery = (options) => {
	return useQuery(['web3Accounts'], getWeb3Accounts, {
		...options
	});
};

export default useWeb3AccountsQuery;
