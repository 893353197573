import useCollectionQuery from 'services/api/hooks/useCollectionQuery';
import Heading from 'components/Heading';
import { useParams } from 'react-router-dom';

const DropDetails = ({
	heading,
	className,
	disableHeading
}) => {
	const meta = useParams();

	const { data = {}, isLoading } = useCollectionQuery({
		requireTemplateId: true,
		meta
	});

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			{ isLoading ? (
				<div className="space-y-2">
					<div className="bg-theme-placeholder rounded-md h-6" />
					<div className="bg-theme-placeholder rounded-md h-6" />
					<div className="bg-theme-placeholder rounded-md h-6" />
				</div>
			) : (
				<div className="divide-y divide-theme-divider">
					<div className="grid grid-cols-3 gap-4 pb-2">
						<span className="col-span-1 font-medium truncate">Contract Address</span>
						<span className="col-span-2 truncate">{ data?.details?.address }</span>
					</div>
					<div className="grid grid-cols-3 gap-4 py-2">
						<span className="col-span-1 font-medium  truncate">Token Standard</span>
						<span className="col-span-2 truncate">{ data?.details?.network?.standard }</span>
					</div>
					<div className="grid grid-cols-3 gap-4 py-2">
						<span className="col-span-1 font-medium  truncate">Blockchain</span>
						<span className="col-span-2 truncate">{ data?.details?.network?.network }</span>
					</div>
				</div>
			) }
		</div>
	);
};

DropDetails.defaultProps = {
	heading: 'Details'
};

export default DropDetails;
