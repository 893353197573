import Button from "./Button";

const ItsACatastrophe = () => {
	return (
		<div className="flex items-center h-screen justify-center">
			<div className="w-full max-w-sm text-center bg-white rounded shadow-md p-10">
				<h1 className="text-2xl font-medium mb-6">Oops!</h1>
				<p className="text-gray-700 mb-6">The servers seem to be down at the moment. Please try again later.</p>
				<Button className='w-full' onClick={() => window.location.reload()}>Refresh</Button>
			</div>
		</div>

	)
}

export default ItsACatastrophe;
