import Avatar from 'components/Avatar';
import Logo from 'components/Logo';
import TotalError from 'components/TotalError';
import useAgency from 'features/Auth/hooks/useAgency';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon, HomeIcon, Bars3Icon, Cog6ToothIcon, ChatBubbleLeftRightIcon, LockClosedIcon, UserGroupIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { Navigate, Link, NavLink, Outlet } from 'react-router-dom';
import useIsAuthorized from 'hooks/useIsAuthorized';
import FullscreenLoader from 'components/FullscreenLoader';

/**
 * @TODO
 * Create connected feature components for header and nav (if necessary)
 */

const navigation = [{
	name: 'Home',
	path: 'profile',
	icon: HomeIcon,
}, {
	name: 'Collections',
	path: 'collections',
	icon: ChatBubbleLeftRightIcon
}, {
	name: 'Unlocks',
	path: 'unlocks',
	icon: LockClosedIcon
}, {
	name: 'Audience',
	path: 'audience',
	icon: UserGroupIcon,
	disabled: true
}, {
	name: 'Analytics',
	path: 'analytics',
	icon: PresentationChartLineIcon,
	disabled: true
}, {
	name: 'Settings',
	path: 'settings',
	icon: Cog6ToothIcon
}];

const DashboardTemplate = () => {
	const agency = useAgency();
	const { data: session, isError, isSuccess } = useSessionQuery();
	const isAuthorized = useIsAuthorized();

	if (isError) {
		return <TotalError />;
	}

	if (!isAuthorized) {
		return <Navigate to='/studio/auth/signin' replace />
	}

	if (!isSuccess) {
		return <FullscreenLoader />;
	}

	if (isSuccess && agency.brandSetupRequired) {
		return <Navigate to="/studio/auth/brand" replace />
	}

	return (
		<div className="md:flex min-h-screen">
			<div className="hidden md:flex w-64 flex-col flex-shrink-0 bg-theme-header">
				<div className="bg-theme-header p-4 h-16">
					<Logo className="h-full w-auto fill-white" variant="text" />
				</div>
				<div className="flex flex-1 flex-col overflow-y-auto bg-theme-header-dim">
					<div className="p-4 bg-theme-header-dim border-b border-gray-900 flex flex-row items-center">
						<Avatar size="md" className="self-start" src={session?.profilePictureUrl} />
						<div className="ml-2">
							<p className="text-gray-300 text-sm font-medium leading-none mb-1">{agency.brandName || 'No Brand'}</p>
							<p className="text-gray-500 text-sm leading-none">Free</p>
						</div>
					</div>
					<nav className="p-2 space-y-2">
						{navigation.map(({ name, path, disabled, icon: Icon }) => disabled ? null : (
							<NavLink to={path} key={name} className={({ isActive }) => isActive ?
								'bg-theme-header text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md' :
								'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
							}>
								{({ isActive }) => (
									<Fragment>
										<Icon className={`w-6 h-6 mr-3 ${isActive ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300'}`} />
										{name}
									</Fragment>
								)}
							</NavLink>
						))}
					</nav>
				</div>
			</div>
			<Disclosure as="header" className="md:hidden">
				{({ open }) => (
					<Fragment>
						<div className="max-w-7xl mx-auto h-16 p-4 space-x-4 flex items-center">
							<Logo className="h-full w-auto text-theme-palette-primary fill-black" />
							<div className="flex flex-1 justify-end items-center">
								<Disclosure.Button className="focus:outline-none">
									{open ? (
										<XMarkIcon className="h-6 w-auto" />
									) : (
										<Bars3Icon className="h-6 w-auto" />
									)}
								</Disclosure.Button>
							</div>
						</div>
						<Disclosure.Panel as="nav" className="p-2 space-y-2 bg-theme-header-dim">
							<div className="px-2 py-4 bg-theme-header-dim border-b border-gray-900 flex flex-row items-center">
								<Avatar size="md" className="self-start" src={session?.profilePictureUrl} />
								<div className="ml-2">
									<p className="text-gray-300 text-sm font-medium leading-none mb-1">{agency.brandName || 'No Brand'}</p>
									<p className="text-gray-500 text-sm leading-none">Free</p>
								</div>
							</div>
							{navigation.map(({ name, path, disabled, icon: Icon }) => disabled ? null : (
								<NavLink to={path} key={name} className={({ isActive }) => isActive ?
									'bg-theme-header text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md' :
									'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md'
								}>
									{({ isActive }) => (
										<Fragment>
											<Icon className={`w-6 h-6 mr-3 ${isActive ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300'}`} />
											{name}
										</Fragment>
									)}
								</NavLink>
							))}
						</Disclosure.Panel>
					</Fragment>
				)}
			</Disclosure>
			<div className="w-full">
				<header className="hidden md:flex bg-theme-header p-4 h-16 relative z-10">
					<div className="flex flex-1 items-center justify-end">
						<Menu as="div" className="inline-flex relative">
							<Menu.Button className="flex focus:outline-none">
								<Avatar size="sm" src={session?.profilePictureUrl} />
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items className="overflow-hidden absolute right-0 w-64 top-8 origin-top-right rounded-md ring-1 ring-theme-menu-focus ring-opacity-20 bg-theme-menu shadow-lg">
									<div className="p-4 border-b border-theme-divider">
										<p className="text-sm font-bold">Account Owner</p>
										<p className="text-sm text-theme-typography-dim">{session?.email}</p>
									</div>
									<div>
										<Menu.Item>
											<Link className="w-full text-left block px-4 py-2 text-sm hover:bg-theme-menu-hover" to={`/c/${agency?.brandSlug}`}>View As Consumer</Link>
										</Menu.Item>
										<Menu.Item>
											<Link className="w-full text-left block px-4 py-2 text-sm hover:bg-theme-menu-hover" to="settings">Settings</Link>
										</Menu.Item>
										<Menu.Item>
											<Link className="w-full text-left block px-4 py-2 text-sm hover:bg-theme-menu-hover" to='/studio/auth/signout'>Sign Out</Link>
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</header>
				<main className="max-w-7xl mx-auto bg-theme-background">
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default DashboardTemplate;
