import Heading from 'components/Heading';
import useTokenQuery from 'services/api/hooks/useTokenQuery';
import { CheckBadgeIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { useParams, Link } from 'react-router-dom';

const TokenMeta = ({
	className
}) => {
	const meta = useParams();

	const { data = {}, isLoading } = useTokenQuery({
		meta
	});

	if (isLoading) {
		return (
			<div className={className}>
				<div className="bg-theme-placeholder rounded-md mb-2 h-10 w-3/4" />
				<div className="bg-theme-placeholder rounded-md mb-4 h-8 w-1/4 mb-4" />
				<div className="bg-theme-placeholder rounded-md h-6 mb-1" />
				<div className="bg-theme-placeholder rounded-md h-6 w-1/2 mb-1" />
				<div className="bg-theme-placeholder rounded-md h-6 w-3/4" />
			</div>
		);
	}

	return (
		<div className={className}>
			<h1 className="text-3xl tracking-tight font-bold">{data?.metadata?.name}</h1>
			<div className="mb-4">
				<Link to={`/c/${data?.collection?.brand?.slug}`} className="font-medium flex items-center">
					<CheckBadgeIcon className="w-4 h-4 mr-1 text-theme-primary" />
					{data?.collection?.brand?.slug}
				</Link>
				{data?.metadata?.external_url ? (
					<a target="_blank" rel="noreferrer" href={`https://${data?.metadata?.external_url}`} className="flex items-center font-medium text-theme-primary mb-4">
						<ArrowRightCircleIcon className="w-4 h-4 mr-1" /> https://{data.metadata.externalUrl}
					</a>
				) : null}
			</div>
			<Heading variant="section" className="mb-4">
				About
			</Heading>
			{data?.metadata?.description ? (
				<p className="mb-8 whitespace-pre-line">
					{data.metadata.description}
				</p>
			) : (
				<p className="mb-8 whitespace-pre-line text-theme-typography-dim italic text-sm">
					No description was provided for this token.
				</p>
			)}
		</div>
	);
};

export default TokenMeta;
