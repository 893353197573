import QueryableList from 'components/Queryable/List';
import useEventTargetValue from 'hooks/useEventTargetValue';
import useUnlocksForBrandManagerQuery from 'services/api/hooks/useUnlocksForBrandManagerQuery';
import DashboardUnlocksListItem from './ListItem';
import { useMemo, Fragment } from 'react';

const DashboardUnlocksList = ({
	className
}) => {
	const [viewType, setViewType] = useEventTargetValue(QueryableList.viewTypes[0]?.value);
	const [searchTerm, setSearchTerm] = useEventTargetValue();
	const [sortMethod, setSortMethod] = useEventTargetValue(QueryableList.sortMethods[0]?.value);
	const { data = {}, isLoading } = useUnlocksForBrandManagerQuery();

	const filteredData = useMemo(() => {
		if (!searchTerm) {
			return data?.unlocks;
		}

		const term = searchTerm.toLowerCase();

		return data?.unlocks?.filter(item => {
			return (item?.title?.toLowerCase()?.indexOf(term) ?? -1) !== -1
		});
	}, [
		data,
		searchTerm
	]);

	return (
		<div className={ className }>
			<QueryableList
				viewType={ viewType }
				searchTerm={ searchTerm }
				sortMethod={ sortMethod }
				onViewTypeChange={ setViewType }
				onSearchTermChange={ setSearchTerm }
				onSortMethodChange={ setSortMethod }
				type="unlock"
			>
			{ isLoading ? (
				<Fragment>
					<DashboardUnlocksListItem viewType={ viewType } loading />
					<DashboardUnlocksListItem viewType={ viewType } loading />
					<DashboardUnlocksListItem viewType={ viewType } loading />
				</Fragment>
			) : filteredData?.length > 0 ? filteredData?.map((item = {}) => {
				return (
					<DashboardUnlocksListItem
						key={ item.uuid }
						id={ item.uuid }
						title={ item.title }
						type={ item.privateContext?.type }
						image={ item.coverImageUrl }
						created={ item.created }
						username={ item?.brand?.slug }
						description={ item.description }
						viewType={ viewType } 
					/>
				);
			}) : null }
			</QueryableList>
		</div>
	);
};

export default DashboardUnlocksList;
