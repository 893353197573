import ConsumerWalletsDisconnectDialog from './DisconnectDialog';
import Loader from 'components/Loader';
import { WalletIcon } from '@heroicons/react/24/outline';
import { useState, useCallback } from 'react';
	
const ConsumerWalletsListItem = ({
	id,
	name,
	symbol,
	address,
	loading,
	className
}) => {
	const [open, setOpen] = useState(false);

	const handleDisconnectClick = useCallback(() => {
		setOpen(true);
	}, []);

	const handleDisconnectClose = useCallback(() => {
		setOpen(false);
	}, []);

	if (loading) {
		return (
			<div className={ className }>
				<div className="flex items-start">
					<div className="w-8 h-8 bg-theme-placeholder rounded-full" />
					<div className="ml-2 flex-1">
						<div className="h-4 w-1/2 bg-theme-placeholder rounded-md mb-1" />
						<div className="h-4 w-3/4 bg-theme-placeholder rounded-md mb-1" />
						<div className="h-4 w-16 bg-theme-placeholder rounded-md" />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={ className }>
			<div className="flex items-start">
				{ open ? (
					<Loader className="w-8 h-8" />
				) : (
					<WalletIcon className="h-8 w-8" />
				) }
				<div className="ml-2">
					<h3 className="tracking-tight font-bold text-base">
						{ name } ({ symbol })
					</h3>
					<p className="text-sm text-theme-typography-dim mb-1">
						{ address }
					</p>
					<button onClick={ handleDisconnectClick } disabled={ open } className="font-medium text-sm text-theme-primary">Remove Wallet</button>
				</div>
			</div>
			<ConsumerWalletsDisconnectDialog address={ address } networkId={ id } onClose={ handleDisconnectClose } open={ open } />
		</div>
	);
}

export default ConsumerWalletsListItem;
