import { useMemo, useCallback, Children } from 'react';
import { MagnifyingGlassIcon, Squares2X2Icon, Bars3Icon } from '@heroicons/react/20/solid';
import EmptyState from 'components/EmptyState';
import Select from 'components/Select';
import Input from 'components/Input';
import clsx from 'clsx';

const QueryableList = ({
	onSortMethodChange,
	onSearchTermChange,
	onViewTypeChange,
	sortMethods,
	sortMethod,
	searchTerm,
	createPath,
	className,
	children,
	viewTypes,
	viewType,
	type
}) => {
	const hasChildren = useMemo(() => Children.count(children) > 0, [children]);

	const handleViewTypeClick = useCallback(event => {
		const value = event?.currentTarget?.dataset?.attrValue;

		if (viewType === value) {
			return;
		}

		if (typeof onViewTypeChange !== 'function') {
			return;
		}

		onViewTypeChange({
			target: {
				value
			}
		});
	}, [
		viewType,
		onViewTypeChange
	]);

	return (
		<div className={ className }>
			<div className="flex flex-row mb-4">
				<Input className="flex-1 md:flex-none lg:w-72 self-start mb-2 lg:mb-0" value={ searchTerm } onChange={ onSearchTermChange } placeholder={ `Search ${ type }s` } icon={ MagnifyingGlassIcon } />
				<div className="md:flex-1 md:flex hidden" />
				<div className="flex ml-2 lg:ml-0">
					<Select className="lg:w-48 self-start mr-2" value={ sortMethod } values={ sortMethods } onChange={ onSortMethodChange } />
					<div className="flex self-start relative divide-x rounded-md shadow-sm border bg-theme-input-primary border-theme-input-primary-border text-theme-input-primary-content">
						{ viewTypes.map(({ icon: Icon, value }) => (
							<button onClick={ handleViewTypeClick } data-attr-value={ value } key={ value } className="px-3 py-2">
								<Icon className={ viewType === value ? 'w-6 sm:w-5 text-theme-input-primary-content' : 'w-6 sm:w-5 opacity-20' } />
							</button>
						)) }
					</div>
				</div>
			</div>
			{ hasChildren ? (
				<div className={ clsx('grid gap-4', {
					'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4': viewType === QueryableList.viewTypes[1].value
				}) }>
					{ children }
				</div>
			) : (
				<EmptyState createPath={ createPath } type={ type } />
			) }
		</div>
	);
};

QueryableList.viewTypes = [{
	name: 'List',
	value: 'list',
	icon: Bars3Icon
}, {
	name: 'Grid',
	value: 'grid',
	icon: Squares2X2Icon
}];

QueryableList.sortMethods = [{
	name: 'Last Modified',
	value: 'modified'
}, {
	name: 'Date Created',
	value: 'created'
}];

QueryableList.defaultProps = {
	type: 'item',
	createPath: 'create',
	viewType: QueryableList.viewTypes[0].value,
	viewTypes: QueryableList.viewTypes,
	sortMethod: QueryableList.sortMethods[0].value,
	sortMethods: QueryableList.sortMethods
};

export default QueryableList;
