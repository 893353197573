import client from '../client';

const createTemplate = (data = {}, options = {}) => {
	const collectionId = options?.collectionId;
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId && collectionId)) {
		return Promise.reject(new Error('Unable to create template without valid agency/brand/collection id'));
	}

	return client
		.post(`/agencies/${ agency.id }/brands/${ agency.brandId }/collections/${ collectionId }/templates`, data)
		.then(response => response?.data)
	;
};

export default createTemplate;
