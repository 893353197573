import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Heading from 'components/Heading';
import DashboardUnlocksList from 'features/Dashboard/Unlocks/List';

const DashboardUnlocksIndexRoute = () => {
	return (
		<div className="p-4">
			<div className="flex items-center mb-6">
				<Heading className="flex-1">Unlocks</Heading>
				<Button component={ Link } to="create" className="inline-flex items-center" size="sm">
					Create
				</Button>
			</div>
			<DashboardUnlocksList />
		</div>
	);
};

export default DashboardUnlocksIndexRoute;
