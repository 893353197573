import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useBrandPageQuery from 'services/api/hooks/useBrandPageQuery';
import useUpdateBrandPageMutation from 'services/api/hooks/useUpdateBrandPageMutation';
import Heading from 'components/Heading';
import Textarea from 'components/Textarea';
import Button from 'components/Button';
import getErrorMessage from 'utils/getErrorMessage';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useJSONState from 'hooks/useJSONState';
import clsx from 'clsx';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useState, useCallback } from 'react';

const BrandAbout = ({
	heading,
	editable,
	className,
	brandSlug,
	disableHeading
}) => {
	const notifications = useNotificationsStore();

	const [editing, setEditing] = useState(false);

	const [form, formActions] = useJSONState({
		description: ''
	});

	const { data = {}, isLoading } = useBrandPageQuery(useRouteParamsAsMeta({
		brandSlug
	}));

	const { mutate, isLoading: isUpdating } = useUpdateBrandPageMutation({
		onSuccess: () => {
			notifications.success('About information successfully updated');
			setEditing(false);
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update about information'));
		},
	});

	const setEditingActive = useCallback(event => {
		formActions.extend({
			description: data?.community?.details?.description ?? ''
		});

		setEditing(true);
	}, [
		data,
		formActions
	]);

	const setEditingInactive = useCallback(event => {
		setEditing(false);
	}, []);

	const handleSaveClick = useCallback(event => {
		mutate({
			description: form.description
		});
	}, [
		form,
		mutate
	]);

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<div className="min-w-0 flex items-center mb-4">
					<Heading variant="section" className="truncate flex-1">
						{ heading }
					</Heading>
					{ editing || !editable ? null : (
						<button disabled={ isLoading } onClick={ setEditingActive }>
							<PencilIcon className="w-5 h-5" />
						</button>
					) }
				</div>
			) }
			{ editing ? (
				<Textarea className="mb-4" autoFocus rows="5" name="description" value={ form.description } onChange={ formActions.intercept } />
			) : (
				<p className={ clsx('text-sm text-theme-typography-dim whitespace-pre-line', {
					'italic': !data?.community?.details?.description
				}) }>
					{ data?.community?.details?.description ?? `${ data?.name ?? 'This brand' } hasn't told us anything about itself.` }
				</p>
			) }
			{ editing ? (
				<div className="flex justify-end space-x-3">
					<Button disabled={ isUpdating } onClick={ setEditingInactive } variant="neutral">
						Cancel
					</Button>
					<Button disabled={ isUpdating } onClick={ handleSaveClick } variant="primary">
						Save
					</Button>
				</div>
			) : null }
		</div>
	);
};

BrandAbout.defaultProps = {
	heading: 'About'
};

export default BrandAbout;
