import { Outlet } from 'react-router-dom';
import StudioAuthHeader from './components/StudioAuthHeader';

const textGradientStyle = {
	background: 'linear-gradient(to right, #70A9FF 0%, #70A9FF 50%, #9C59A2 100%)',
	'WebkitBackgroundClip': 'text',
	'backgroundClip': 'text',
	'WebkitTextFillColor': 'transparent',
	'textFillColor': 'transparent'
};

const StudioAuthTemplate = () => (
	<div className="h-screen flex flex-col md:flex-row">
		<div className="w-full hidden md:flex md:w-1/2 pb-20 bg-theme-higlight flex-col justify-center items-center">
			<div className='p-6 flex flex-col items-start justify-center items-center max-w-lg mx-auto px-4'>
				<h1 className="ml-4 text-2xl uppercase font-extrabold header text-center overflow-hidden text-overflow-ellipsis" style={textGradientStyle}>
					Web3 Tools for the Mainstream
				</h1>

				<div className='max-w-sm mt-2 mx-auto'>
					<h2 className="mt-4 text-xl font-bold text-primary py-2">Start building with Hexkey, no credit card required.</h2>
					<p className="mt-2 text-sm text-dim py-2">Quickly launch NFT initiatives to attract, retain, and delight your audience.</p>
					<p className="mt-2 text-sm text-dim py-2">Create a branded storefront to sell and distribute NFTs, provide access to unlocks and offers, and keep your community updated with notifications.</p>
					<p className="mt-2 text-sm text-dim py-2">Engage your audience, collect direct contact information, and activate customers in a fresh, interactive way. </p>
				</div>
			</div>
		</div>
		<div className="w-full h-full md:w-1/2 pb-0 md:pb-20 flex flex-col md:justify-center md:items-center">
			<div className="flex flex-col items-start p-12 max-w-lg w-full mt-2 mx-auto">
				<StudioAuthHeader />
				<Outlet />
			</div>
		</div>
	</div>
);

export default StudioAuthTemplate;
