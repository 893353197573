import { Link } from 'react-router-dom';
import Button from 'components/Button';
import DashboardCollectionHeading from 'features/Dashboard/Collection/Heading';
import DashboardCollectionTokenList from 'features/Dashboard/Collection/Token/List';

const DashboardCollectionIndexRoute = () => {
	return (
		<div className="p-4">
			<div className="flex flex-col mb-6 lg:flex-row lg:items-start">
				<DashboardCollectionHeading className="flex-1 mb-4 lg:mb-0 lg:mr-4" />
				<div className="flex gap-4">
					<Button component={ Link } to="edit" variant="neutral" size="sm">
						Edit Collection
					</Button>
					<Button component={ Link } to="create/free" size="sm">
						Create Token
					</Button>
				</div>
			</div>
			<DashboardCollectionTokenList />
		</div>
	);
};

export default DashboardCollectionIndexRoute;
