import { useMutation, useQueryClient } from '@tanstack/react-query'
import followBrand from '../endpoints/followBrand';

const useFollowBrandMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(followBrand, {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			const brandSlug = options?.meta?.brandSlug;

			if (!brandSlug) {
				return;
			}

			queryClient.setQueryData(['brandPage', brandSlug], (state) => {
				return {
					...state,
					following: true
				}
			});
		}
	});
};

export default useFollowBrandMutation;
