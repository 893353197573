import { useMutation, useQueryClient } from '@tanstack/react-query'
import createBrandPageMessage from '../endpoints/createBrandPageMessage';
import useAgency from 'features/Auth/hooks/useAgency';

const useCreateBrandPageMessageMutation = (options) => {
	const agency = useAgency();
	const queryClient = useQueryClient();

	return useMutation(data => createBrandPageMessage(data, {
		agency
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.setQueryData(['brandPageMessages', agency.pageId], (state) => {
				return {
					...state,
					messages: [
						data, 
						...state.messages
					]
				};
			});
		}
	});
};

export default useCreateBrandPageMessageMutation;
