import client from '../client';

const updateBrandPage = (data = {}, options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId && agency.pageId)) {
		return Promise.reject(new Error('Unable to edit brand without valid agency/brand/page id'));
	}

	return client
		.patch(`/agencies/${ agency.id }/brands/${ agency.brandId }/pages/${ agency.pageId }`, data)
		.then(response => response?.data)
	;
};

export default updateBrandPage;
