import createQRCode from 'utils/createQRCode';
import { useMemo } from 'react';

const QRCode = ({
	className,
	content,
	padding,
}) => useMemo(() => {
	const svg = createQRCode({
		msg: content,
		pad: padding
	});

	return (
		<svg className={ className } viewBox={ svg.getAttribute('viewBox') } fill="currentColor" shapeRendering="crispEdges" xmlns="http://www.w3.org/2000/svg" version="1.1">
			<path
				transform={ svg.lastElementChild.getAttribute('transform') }
				d={ svg.lastElementChild.getAttribute('d') }
			/>
		</svg>
	);
}, [
	className,
	content,
	padding,
]);

QRCode.defaultProps = {
	content: 'https://localhost:3000/drop/collection/1234/939393',
	padding: 1,
};

export default QRCode;
