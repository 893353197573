import Heading from 'components/Heading';
import clsx from 'clsx';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useVerifyAccountMutation from 'services/api/hooks/useVerifyAccountMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import { useParams, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

const AuthVerifyForm = ({
	className
}) => {
	const params = useParams();
	const isAuthorized = useIsAuthorized();
	const notifications = useNotificationsStore();

	const { mutate } = useVerifyAccountMutation({
		onError: (data) => {
			notifications.failure('Unable to verify your account at this time. Try requesting a new verification code.');
		}
	});

	const { code } = params;

	useEffect(() => {
		if (isAuthorized) {
			return;
		}

		if (!code) {
			return;
		}

		mutate({ code });
	}, [
		isAuthorized,
		mutate,
		code
	]);

	/** 
	 * @TODO
	 * If failure, enable request new code button and submit request.
	 */

	return isAuthorized ? (
		<Navigate to="/" replace />
	) : (
		<div className={ clsx('space-y-8', className) }>
			<div className="space-y-4">
				<Heading>Verifying account</Heading>
				<p>
					One moment while we verify your account...
				</p>
			</div>
		</div>
	);
};

export default AuthVerifyForm;