import InlineNavigation from 'components/InlineNavigation';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

const navigation = [{
	name: 'Profile',
	path: 'profile'
}, {
	name: 'Wallets',
	path: 'wallets'
}];

const ConsumerSettingsTemplate = () => {
	return (
		<Fragment>
			<InlineNavigation paths={ navigation } />
			<Outlet />
		</Fragment>
	);
};

export default ConsumerSettingsTemplate;
