import QueryableList from 'components/Queryable/List';
import useEventTargetValue from 'hooks/useEventTargetValue';

const DashboardPagesList = ({
	className
}) => {
	const [searchTerm, setSearchTerm] = useEventTargetValue();
	const [sortMethod, setSortMethod] = useEventTargetValue(QueryableList.sortMethods[0]?.value);

	return (
		<div className={ className }>
			<QueryableList
				searchTerm={ searchTerm }
				sortMethod={ sortMethod }
				onSearchTermChange={ setSearchTerm }
				onSortMethodChange={ setSortMethod }
				type="page"
			/>
		</div>
	);
};

export default DashboardPagesList;
