import { useParams, Navigate } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordResetPassword from './ForgotPasswordResetPassword';
import Verified from './Verified';
import Verifying from './Verifying';
import VerifyYourEmail from './VerifyYourEmail';

const templates = {
	'reset-password': ForgotPasswordResetPassword,
	'forgot-password': ForgotPassword,
	'verified': Verified,
	'verifying': Verifying,
	'verify-your-email': VerifyYourEmail,
}

const Message = () => {
	const { message } = useParams();

	const Component = templates[message];
	if (!Component) {
		return <Navigate to='/studio' />;
	}

	return (
		<Component />
	);
};

export default Message;
