import useNotificationsStore, { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_FAILURE } from 'state/hooks/useNotificationsStore';
import { ExclamationCircleIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useMemo, useEffect, useCallback, forwardRef } from 'react';

const Alert = forwardRef(({
	id,
	ttl,
	body,
	type,
	title,
	image,
	...rest
}, ref) => {
	const { remove } = useNotificationsStore();

	const [Icon, iconColor] = useMemo(() => {
		switch (type) {
			case NOTIFICATION_TYPE_SUCCESS:
				return [CheckCircleIcon, 'text-green-400'];
			case NOTIFICATION_TYPE_FAILURE:
				return [XCircleIcon, 'text-red-400'];
			default:
				return [ExclamationCircleIcon, 'text-blue-400'];
		};
	}, [type]);

	const handleRemove = useCallback(() => {
		remove(id);
	}, [
		id,
		remove
	]);

	useEffect(() => {
		if (typeof ttl !== 'number' || ttl <= 0) {
			return;
		}

		const id = setTimeout(handleRemove, ttl * 1000);

		return () => {
			clearTimeout(id);
		};
	}, [
		ttl,
		handleRemove
	]);

	return (
		<div { ...rest } ref={ ref } className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
			<div className="p-4">
				<div className="flex items-start">
					<div className="flex-shrink-0">
						{ image ? (
							<div className="w-24 h-24 rounded-lg bg-theme-placeholder overflow-hidden">
								<img src={ image } alt={ title } className="w-full h-full object-contain" />
							</div>
						) : (
							<Icon className={ `h-6 w-6 ${ iconColor }` } />
						) }
					</div>
					<div className="ml-3 w-0 flex-1 pt-0.5">
						<p className="text-sm font-medium text-gray-900">{ title }</p>
						<p className="mt-1 text-sm text-gray-500">{ body }</p>
					</div>
					<div className="ml-4 flex flex-shrink-0">
						<button onClick={ handleRemove } type="button" className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none">
							<XMarkIcon className="h-5 w-5" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
});

export default Alert;
