import useLinkWeb3AccountMutation from './useLinkWeb3AccountMutation';
import { useMutation } from '@tanstack/react-query';
import Web3 from 'web3';

const useConnectMetaMaskMutation = (options = {}) => {
	const { mutateAsync } = useLinkWeb3AccountMutation();

	return useMutation(async () => {
		const networkId = options?.networkId;

		if (!networkId) {
			throw new Error('networkId is required.');
		}

		if (typeof window.ethereum === 'undefined') {
			throw new Error('Uh oh! You need a web3 wallet to continue. Please setup a MetaMask account and try again.');
		}

		const accounts = await window.ethereum.request({
			method: 'eth_requestAccounts'
		});

		if (!Array.isArray(accounts) || accounts.length === 0) {
			throw new Error('no accounts were found.');
		}
	
		const web3 = new Web3(window.ethereum);
		const currentChainId = await web3.eth.net.getId();

		if (currentChainId !== networkId) {
			await web3.currentProvider.request({
				method: 'wallet_switchEthereumChain',
				params: [{
					chainId: Web3.utils.toHex(networkId)
				}]
			});
		}

		const account = accounts[0];
		const message = getSignatureRequestMessage(account);
		const signature = await window.ethereum.request({
			method: 'personal_sign',
			params: [
				message,
				account
			]
		});
		
		return mutateAsync({
			message,
			signature
		});
	}, options);
};

const getSignatureRequestMessage = (account) => `
Welcome to Hexkey!

Click to sign in and accept the Hexkey Terms of Service: https://www.hexkey.com/tos,

This request will not trigger a blockchain transaction or cost any gas fees.

Wallet address: ${ account }
`.trim();

export default useConnectMetaMaskMutation;
