import client from '../client';

const createBrandPageMessage = (data = {}, options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId && agency.pageId)) {
		return Promise.reject(new Error('Unable to create template without valid agency/brand/page id'));
	}

	return client
		.post(`/agencies/${ agency.id }/brands/${ agency.brandId }/pages/${ agency.pageId }/messages`, data)
		.then(response => response?.data)
	;
};

export default createBrandPageMessage;
