const formatCurrency = (value, options = {}) => {
	const {
		defaultValue,
		locale,
		code,
	} = {
		...defaults,
		...options
	};

	const formatter = getNumberFormat({
		locale,
		code
	});

	if (isNaN(value) || !isFinite(value)) {
		return defaultValue;
	}

	return formatter.format(value);
};

const getNumberFormat = (options = {}) => {
	const { locale, code } = options;
	const key = `${ locale }__${ code }`;

	if (!cache[key]) {
		cache[key] = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: code
		});
	}

	return cache[key];
};

const cache = {};

const defaults = {
	defaultValue: '-',
	locale: 'en-US',
	code: 'USD'
};

export default formatCurrency;