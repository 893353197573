import client from '../client';

const archiveTemplate = (options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId && options?.collectionId && options?.templateId)) {
		return Promise.reject(new Error('Unable to archive template without valid agency/brand/collection/template id'));
	}

	return client
		.patch(`/agencies/${ agency.id }/brands/${ agency.brandId }/collections/${ options.collectionId }/templates/${ options.templateId }/archive`)
		.then(response => response?.data)
	;
};

export default archiveTemplate;
