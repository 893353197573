import Loader from 'components/Loader';
import { Children } from 'react';

const FullscreenLoader = ({
	children
}) => (
	<div className="text-white flex flex-col items-center justify-center fixed inset-0 bg-theme-background-intense bg-opacity-75 z-[100]">
		<Loader className="w-8 text-current" />
		{Children.count(children) > 0 ? (
			<div className="p-4">
				{children}
			</div>
		) : null}
	</div>
);

export default FullscreenLoader;
