import LivelyUploadCore from '@livelyvideo/upload-core';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import useCreateLivelyTokenMutation from 'services/api/hooks/useCreateLivelyTokenMutation';
import { useMutation } from '@tanstack/react-query';
import { useRef, useEffect } from 'react';

const useUploadMutation = (options = {}) => {
	const { onProgress, ...rest } = options;
	const { mutateAsync } = useCreateLivelyTokenMutation();
	const { data: session } = useSessionQuery();
	const destroyRef = useRef();

	useEffect(() => () => {
		if (destroyRef.current) {
			destroyRef.current();
		}
	}, []);

	return useMutation(resource => mutateAsync({
		userId: session?.uuid
	}).then(response => {
		const { token, upload } = response;

		if (!token) {
			return Promise.reject(new Error('Unable to obtain upload token.'));
		}

		return new Promise((resolve, reject) => {
			let destroyed;

			const target = document.createElement('div');

			target.className = 'absolute opacity-0 w-0 h-0 overflow-hidden';

			document.body.appendChild(target);

			const uploadConfig = {
				host: upload.host,
				chunkConnections: 2,
				debug: false,
				https: true,
				wait: false,
				authUrl: upload.authUrl,
				verUpl: upload.version,
				loggerOptions: {
					silent: false,
					disable: true
				},
				token
			};

			const instance = new LivelyUploadCore(target, uploadConfig);

			const events = {
				success: (file, arg, uri) => {
					if (events.progress) {
						events.progress(file, 0, 0, 0);
					}

					destroy();

					resolve({
						uri,
						file
					});
				},
				failure: (file, reason) => {
					if (events.progress) {
						events.progress(file, 0, 0, 0);
					}

					destroy();
					reject(reason);
				}
			};

			const destroy = () => {
				if (destroyed) {
					return;
				}

				destroyed = true;

				Object.keys(events).forEach(name => {
					instance.removeListener(name, events[name]);
				});

				instance.destroy();

				if (target.parentNode) {
					target.parentNode.removeChild(target);
				}
			};

			destroyRef.current = destroy;

			if (typeof onProgress === 'function') {
				let lastChange;

				events.progress = (file, bytesSent, bytesTotal, value) => {
					bytesSent = Math.min(bytesSent, bytesTotal);
					value = Math.max(0, Math.min(100, value))

					if (lastChange === value) {
						return;
					}

					lastChange = value;

					onProgress({
						bytesTotal,
						bytesSent,
						value
					});
				};
			};

			Object.keys(events).forEach(name => {
				instance.on(name, events[name]);
			});

			instance.addFile(resource);
		});
	}), rest);
};

export default useUploadMutation;
