import Button from 'components/Button';
import Heading from 'components/Heading';
import ConsumerWalletsListItem from './ListItem';
import ConsumerWalletsConnectDialog from './ConnectDialog';
import useWeb3AccountsQuery from 'services/api/hooks/useWeb3AccountsQuery';
import { WalletIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState, useCallback } from 'react';

const ConsumerWalletsList = ({
	heading,
	className,
	disableHeading
}) => {
	const [open, setOpen] = useState(false);

	const { data = {}, isLoading } = useWeb3AccountsQuery();

	const handleConnectClick = useCallback(() => {
		setOpen(true);
	}, []);

	const handleConnectClose = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			{ isLoading ? (
				<div className="grid gap-4 max-w-2xl">
					<ConsumerWalletsListItem loading />
					<ConsumerWalletsListItem loading />
					<ConsumerWalletsListItem loading />
				</div>
			) : data?.address ? (
				<div className="grid gap-4 max-w-2xl">
					<ConsumerWalletsListItem
						id={ data?.network?.id }
						name={ data?.network?.name ?? 'Primary' }
						symbol={ data?.network?.currencySymbol ?? 'N/A' }
						address={ data?.address }
					/>
				</div>
			) : (
				<div className="flex flex-col items-center rounded-lg border-2 border-dashed border-theme-diver p-12">
					<WalletIcon className="w-12 h-12 text-theme-typography-dim mb-2" />
					<h3 className="text-sm font-medium mb-1">No wallets.</h3>
					<p className="text-sm text-theme-typography-dim mb-6">Get started by creating a connecting a wallet.</p>
					<Button onClick={ handleConnectClick } className="inline-flex items-center capitalize" size="md">
						<PlusIcon className="w-4 h-4 mr-1" />
						Connect wallet
					</Button>
				</div>
			) }
			<ConsumerWalletsConnectDialog open={ open } onClose={ handleConnectClose } />
		</div>
	);
};

ConsumerWalletsList.defaultProps = {
	heading: 'Wallets'
};

export default ConsumerWalletsList;
