import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Heading from 'components/Heading';
import UploadAvatar from 'components/Upload/Avatar';
import getErrorMessage from 'utils/getErrorMessage';
import useJSONState from 'hooks/useJSONState';
import useProfileQuery from 'services/api/hooks/useProfileQuery';
import useUpdateProfileMutation from 'services/api/hooks/useUpdateProfileMutation';
import useNotificationsStore, { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_FAILURE } from 'state/hooks/useNotificationsStore';
import { useMemo, useCallback } from 'react';

const DashboardSettingsProfileForm = ({
	className
}) => {
	const notifications = useNotificationsStore();

	const [form, formActions] = useJSONState({
		name: '',
		username: '',
		profilePictureUrl: ''
	});

	const { isLoading: isUpdating, mutate } = useUpdateProfileMutation({
		onSuccess: (data) => {
			notifications.add({
				type: NOTIFICATION_TYPE_SUCCESS,
				body: data?.message ?? 'Profile successfully updated'
			});
		},
		onError: (data) => {
			notifications.add({
				type: NOTIFICATION_TYPE_FAILURE,
				body: getErrorMessage(data, 'Failed to update profile information')
			});
		}
	});

	const { isLoading } = useProfileQuery({
		onSuccess: (data) => {
			formActions.extend({
				name: data?.name ?? '',
				username: data?.username?.username ?? '',
				profilePictureUrl: data?.profilePictureUrl ?? ''
			});
		}
	});

	const handleSubmit = useCallback(event => {
		event.preventDefault();

		mutate(form);
	}, [
		form,
		mutate
	]);

	const isDisabled = useMemo(() => {
		return isLoading || isUpdating;
	}, [
		isLoading,
		isUpdating
	]);

	return (
		<div className={ className }>
			<form disabled={ isDisabled } method="post" action="/a-fake-action" onSubmit={ handleSubmit } className="space-y-8 divide-y divide-theme-divider">
				<div className="space-y-8 divide-y divide-theme-divider sm:space-y-5">
					<div className="space-y-6 sm:space-y-5">
						<div>
							<Heading component="h3" variant="section">General</Heading>
							<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
								This information will be displayed publicly so be careful what you share.
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="name" className="sm:mt-px sm:pt-2">
									Name
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input disabled={ isDisabled } value={ form.name } onChange={ formActions.intercept } id="name" name="name" type="text" className="max-w-lg sm:max-w-xs" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="username" className="sm:mt-px sm:pt-2">
									Username
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input disabled={ isDisabled } value={ form.username } onChange={ formActions.intercept } id="username" name="username" type="text" className="max-w-lg sm:max-w-xs" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label className="sm:mt-px sm:pt-2">
									Profile Picture
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<UploadAvatar name="profilePictureUrl" value={ form.profilePictureUrl } onChange={ formActions.intercept } />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="flex justify-end space-x-3">
						<Button disabled={ isDisabled } variant="primary">
							Save
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default DashboardSettingsProfileForm;
