import { useMutation, useQueryClient } from '@tanstack/react-query';
import claimToken from '../endpoints/claimToken';

const useClaimTokenMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(() => claimToken({
		meta: options?.meta
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.invalidateQueries({
				queryKey: ['collection', options?.meta?.collectionId, options?.meta?.templateId]
			});
		}
	});
};

export default useClaimTokenMutation;
