import Heading from 'components/Heading';
import BackToSignIn from './components/BackToSignIn';
import EmailIcon from './components/EmailIcon';
import ResendEmail from './components/ResendEmail';

const VerifyYourEmail = () => (
	<>
		<EmailIcon />

		<Heading className="text-center">Verify your email address</Heading>
		<hr className="my-6 border-t border-gray-200" />
		<p className="font-light">
			Thank you for choosing Hexkey.
		</p>
		<p className="font-light mt-4">
			We have sent an email to name@email.com to confirm the validity of your email address. After receiving the email, follow the link provided to complete regestration.
		</p>

		<hr className="my-6 border-t border-gray-200" />

		<ResendEmail />
		<BackToSignIn />
	</>
);

export default VerifyYourEmail;

