import { Link } from "react-router-dom";
import Button from 'components/Button';

const BackToSignIn = () => (
	<div className="mt-12" >
		<Button className="w-full" size="lg" variant="neutral" component={Link} to='/studio/auth/signin'>
			Back to Sign In
		</Button>
	</div>
);

export default BackToSignIn