import { useQuery } from '@tanstack/react-query'
import getBrandPageMessages from '../endpoints/getBrandPageMessages';

const useBrandPageMessagesQuery = (options) => {
	const brandPageId = options?.meta?.brandPageId;

	return useQuery(['brandPageMessages', brandPageId], getBrandPageMessages, {
		...options,
		enabled: !!brandPageId
	});
};

export default useBrandPageMessagesQuery;
