import ConsumerHeader from 'features/Consumer/Header';
import BrandBanner from 'features/Brand/Banner';
import { Outlet, NavLink } from 'react-router-dom';
import clsx from 'clsx';

const navigation = [{
	name: 'Feed',
	path: 'feed'
}, {
	name: 'Store',
	path: 'store'
}, {
	name: 'Unlocks',
	path: 'unlocks'
}];

const BrandTemplate = () => {
	return (
		<div className="min-h-full">
			<ConsumerHeader />
			<main className="w-full max-w-7xl mx-auto">
				<BrandBanner className="xl:mb-4" />
				<nav className="whitespace-nowrap overflow-x-scroll bg-theme-background-dim scrollbar-none xl:rounded-lg">
					{ navigation.map(({ path, name }) => (
						<NavLink key={ path } to={ path } className={ ({ isActive }) => clsx(
							'inline-flex items-center p-4 text-sm font-medium hover:text-theme-typography-dim', {
								'text-theme-primary border-theme-primary hover:text-theme-primary': isActive
							}
						) }>
							{ name }
						</NavLink>
					)) }
				</nav>
				<Outlet />
			</main>
		</div>
	);
};

export default BrandTemplate;
