import useUnlinkWeb3AccountMutation from './useUnlinkWeb3AccountMutation';
import { useMutation } from '@tanstack/react-query';
// import Web3 from 'web3';

const useDisconnectMetaMaskMutation = (options = {}) => {
	const { mutateAsync } = useUnlinkWeb3AccountMutation();

	return useMutation(async () => {
		// const networkId = options?.networkId;
		const address = options?.address;

		// if (!networkId) {
		// 	throw new Error('networkId is required.');
		// }

		if (!address) {
			throw new Error('address is required.');
		}

		if (typeof window.ethereum === 'undefined') {
			throw new Error('Uh oh! You need a web3 wallet to continue. Please setup a MetaMask account and try again.');
		}

		// const web3 = new Web3(window.ethereum);
		// const currentChainId = await web3.eth.net.getId();

		// if (currentChainId !== networkId) {
		// 	await web3.currentProvider.request({
		// 		method: 'wallet_switchEthereumChain',
		// 		params: [{
		// 			chainId: Web3.utils.toHex(networkId)
		// 		}]
		// 	});
		// }

		const message = getSignatureRequestMessage(address);
		const signature = await window.ethereum.request({
			method: 'personal_sign',
			params: [
				message,
				address
			]
		});
		
		return mutateAsync({
			message,
			signature
		});
	}, options);
};

const getSignatureRequestMessage = (address) => `
Leaving Hexkey?

By continuing with the unlink process, you'll lose your hexkey benefits.

Unlink wallet address: ${ address }?
`.trim();

export default useDisconnectMetaMaskMutation;
