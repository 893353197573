import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import updateBrandPage from '../endpoints/updateBrandPage';

const useUpdateBrandPageMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(data => updateBrandPage(data, {
		agency
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			const brandSlug = data?.brandPage?.brand?.slug;
		
			queryClient.setQueryData(['brandPage', brandSlug], (currentState) => {
				return {
					...currentState,
					...data?.brandPage
				};
			});
		}
	});
};

export default useUpdateBrandPageMutation;