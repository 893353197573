import Logo from 'components/Logo';
import { Outlet } from 'react-router-dom';

const AuthTemplate = () => {
	return (
		<div className="min-h-full flex items-center justify-center p-4 bg-theme-background-intense">
			<div className="w-full max-w-md p-8 bg-theme-background rounded-lg shadow-xl">
				<Logo className="h-12 w-auto mb-8" />
				<Outlet />
			</div>
		</div>
	);
};

export default AuthTemplate;
