import { useQuery } from '@tanstack/react-query'
import useIsAuthorized from 'hooks/useIsAuthorized';
import getSession from '../endpoints/getSession';

const useSessionQuery = (options) => {
	const isAuthorized = useIsAuthorized();

	return useQuery(['session'], getSession, {
		...options,
		enabled: isAuthorized && (typeof options?.enabled === 'boolean' ? options.enabled : true),
		staleTime: Infinity
	});
};

export default useSessionQuery;
