import useUnlockForBrandManagerQuery from 'services/api/hooks/useUnlockForBrandManagerQuery';
import Skeleton from 'components/Skeleton';
import DashboardUnlocksFileForm from './FileForm';
import DashboardUnlocksURLForm from './URLForm';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import getErrorMessage from 'utils/getErrorMessage';
import { Navigate, useParams } from 'react-router-dom';

const DashboardUnlocksEditForm = ({
	className
}) => {
	const meta = useParams();
	const notifications = useNotificationsStore();
	const { data, isLoading, isSuccess, isError } = useUnlockForBrandManagerQuery({
		meta,
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Unable to retrieve unlock details.'));
		}
	});
	
	if (isLoading) {
		return (
			<Skeleton className={ className } />
		);
	}

	/**
	 * @TODO
	 * Parse data from query and figure out if free or priced token then
	 * render appropriate form.
	 */
	if (isSuccess) {
		switch (data?.privateContext?.type) {
			case 'file':
				return <DashboardUnlocksFileForm className={ className } />
			case 'url':
				return <DashboardUnlocksURLForm className={ className } />
			default:
				return <Navigate to=".." replace />
		};
	}

	if (isError) {
		return <Navigate to=".." replace />
	}
};

export default DashboardUnlocksEditForm;
