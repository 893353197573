import client from '../client';

const getUnlockForBrandManager = (options) => {
	const unlockId = options?.meta?.unlockId;
	const agency = options?.meta?.agency ?? {};

	if (!(agency.id && agency.brandId && unlockId)) {
		return Promise.reject(new Error('Unable to fetch unlocks without valid agency/brand/unlock id'));
	}

	return client
		.get(`/agencies/${ agency.id }/brands/${ agency.brandId }/unlocks/${ unlockId }`)
		.then(response => response?.data)
	;
};

export default getUnlockForBrandManager;
