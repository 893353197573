import { useMemo, useCallback } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useJSONState from 'hooks/useJSONState';
import useResetPasswordMutation from 'services/api/hooks/useResetPasswordMutation';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Input from 'components/Input';
import Label from 'components/Label';
import getErrorMessage from 'utils/getErrorMessage';
import KeyIcon from './components/KeyIcon';

const ForgotPasswordResetPassword =  () => {
	const navigate = useNavigate();
	const notifications = useNotificationsStore();

	const [params] = useSearchParams();
	const token = params.get('token');

	const { mutate, isLoading } = useResetPasswordMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, "Please verify your credentials and try again. If you're still having trouble, please contact our support team for assistance."));
		},
		onSuccess: () => {
			notifications.success("You have successfully reset your password, you're reading to sign in!");
			navigate('/studio/auth/signin');
		}
	});

	const [form, formActions] = useJSONState({
		password: '',
		confirmPassword: '',
		token,
		type: 'studio',
	});

	const disabled = useMemo(() => {
		return isLoading || !form.confirmPassword.trim() || !form.password.trim();
	}, [
		form,
		isLoading
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	return (
		<>
			<KeyIcon />

			<Heading className="text-center">Reset your password</Heading>
			<hr className="my-6 border-t border-gray-200" />

			<form onSubmit={handleSubmit}>
				<div className="pt-4 pb-4">
					<Heading variant='section'>Set up your profile</Heading>
				</div>
				<div className="space-y-2">
					<div className='py-2'>
						<Label htmlFor="password" className="mb-1">New Password</Label>
						<Input id="password" name="password" type="password" required onChange={formActions.intercept} value={form.password} />
					</div>
					<div className='py-2'>
						<Label htmlFor="confirmPassword" className="mb-1">Confirm Password</Label>
						<Input id="confirmPassword" name="confirmPassword" type="password" required onChange={formActions.intercept} value={form.confirmPassword} />
					</div>
				</div>
				<div className="pt-8">
					<Button size="xl" className="w-full" disabled={disabled}>
						Continue
					</Button>
				</div>
				<div className="mt-6 text-sm justify-end">
					<div className="text-sm">
						<Link to="/studio/auth/signin" className="text-theme-primary font-medium">Back to Sign In</Link>
					</div>
				</div>
			</form>
		</>
	);
}

export default ForgotPasswordResetPassword;
