import Heading from 'components/Heading';
import DashboardUnlocksEditForm from 'features/Dashboard/Unlocks/EditForm';

const DashboardUnlocksEditIndexRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Edit Unlock</Heading>
			<DashboardUnlocksEditForm />
		</div>
	);
};

export default DashboardUnlocksEditIndexRoute;
