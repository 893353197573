import Heading from 'components/Heading';
import ConsumerTokensListItem from './ListItem';
import useUserTokensQuery from 'services/api/hooks/useUserTokensQuery';
import Claimed from 'features/Consumer/Claimed';

const ConsumerTokensList = ({
	heading,
	className,
	disableHeading
}) => {
	const { data = {}, isLoading } = useUserTokensQuery();

	return (
		<div className={className}>
			{disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{heading}
				</Heading>
			)}

			<Claimed />

			{isLoading ? (
				<div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
					<ConsumerTokensListItem loading />
					<ConsumerTokensListItem loading />
					<ConsumerTokensListItem loading />
				</div>
			) : (
				<div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
					{data?.tokens?.length > 0 ? data.tokens.map(token => (
						<ConsumerTokensListItem
							id={token?.token}
							key={`${token?.collection?.uuid}__${token?.token}`}
							title={token?.metadata?.name}
							address={token?.collection?.address}
							network={token?.collection?.details?.network?.slug}
							banner={token?.metadata?.image}
							attributes={token?.metadata?.attributes}
							description={token?.metadata?.description}
						/>
					)) : (
						<div className="col-span-3 text-theme-typography-dim italic">
							You've yet to claim any tokens.
						</div>
					)}
				</div>
			)}
		</div>
	);
};

ConsumerTokensList.defaultProps = {
	heading: 'Tokens'
};

export default ConsumerTokensList;
