import socialNetworks from 'config/socialNetworks';
import clsx from 'clsx';
import { useMemo } from 'react';

const SocialIcon = ({
	className,
	network,
	size
}) => {
	const match = useMemo(() => {
		return socialNetworks.find(item => item.value === network) ?? {};
	}, [network]);

	return (
		<svg viewBox="0 0 64 64" className={ clsx('rounded-full fill-current h-auto', SocialIcon.sizes[size], match.className ?? 'text-red-500', className) }>
			{ match.mask ? (
				<path d={ match.mask } />
			) : (
				<circle cx="32" cy="32" r="32" />
			) }
		</svg>
	);
};

SocialIcon.sizes = {
	xs: 'w-6',
	sm: 'w-8',
	md: 'w-10',
	lg: 'w-12',
	xl: 'w-14',
	auto: 'w-full'
};

SocialIcon.defaultProps = {
	size: 'lg'
};

export default SocialIcon;
