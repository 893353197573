import Heading from 'components/Heading';
import ConsumerWalletsList from 'features/Consumer/Wallets/List';

const ConsumerSettingsWalletsRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Wallets</Heading>
			<ConsumerWalletsList disableHeading />
		</div>
	);
};

export default ConsumerSettingsWalletsRoute;
