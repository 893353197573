import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useBrandPageQuery from 'services/api/hooks/useBrandPageQuery';
import Heading from 'components/Heading';
import { Link } from 'react-router-dom';

const BrandUnlocks = ({
	heading,
	className,
	brandSlug,
	disableHeading
}) => {
	const { data = {}, isLoading } = useBrandPageQuery(useRouteParamsAsMeta({
		brandSlug
	}));

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			{ isLoading ? (
				<div className="space-y-4">
					<div className="flex">
						<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-48 md:h-48" />
						<div className="flex-1 ml-4 max-w-lg">
							<div className="bg-theme-placeholder h-6 rounded-lg w-3/4 mb-2" />
							<div className="bg-theme-placeholder h-6 rounded-lg w-1/4 mb-2" />
							<div className="bg-theme-placeholder h-6 rounded-lg w-1/2" />
						</div>
					</div>
					<div className="flex">
						<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-48 md:h-48" />
						<div className="flex-1 ml-4 max-w-lg">
							<div className="bg-theme-placeholder h-6 rounded-lg w-3/4 mb-2" />
							<div className="bg-theme-placeholder h-6 rounded-lg w-1/4 mb-2" />
							<div className="bg-theme-placeholder h-6 rounded-lg w-1/2" />
						</div>
					</div>
					<div className="flex">
						<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-48 md:h-48" />
						<div className="flex-1 ml-4 max-w-lg">
							<div className="bg-theme-placeholder h-6 rounded-lg w-3/4 mb-2" />
							<div className="bg-theme-placeholder h-6 rounded-lg w-1/4 mb-2" />
							<div className="bg-theme-placeholder h-6 rounded-lg w-1/2" />
						</div>
					</div>
				</div>
			) : data?.unlocks?.length > 0 ? (
				<div className="space-y-4">
					{ data.unlocks.map(unlock => (
						<Link to={ `${ unlock?.uuid }` } key={ unlock?.uuid } className="flex">
							<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-48 md:h-48 mr-4 overflow-hidden">
								{ unlock?.coverImageUrl ? (
									<img className="w-full h-full object-cover" alt={ unlock?.title } src={ unlock.coverImageUrl } />
								) : null }
							</div>
							<div className="flex-1">
								<h3 className="font-bold tracking-tight mb-1">{ unlock?.title }</h3>
								<p className="text-sm text-theme-typography-dim max-w-lg">{ unlock?.description }</p>
							</div>
						</Link>
					)) }
				</div>
			) : (
				<p className="text-sm text-theme-typography-dim italic">
					{ data?.name } hasn't created an unlocks.
				</p>
			) }
		</div>
	);
};

BrandUnlocks.defaultProps = {
	heading: 'Unlocks'
};

export default BrandUnlocks;
