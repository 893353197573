import { useParams } from 'react-router-dom';
import DropMeta from 'features/Drop/Meta';
import DropMedia from 'features/Drop/Media';
import DropAbout from 'features/Drop/About';
import DropDetails from 'features/Drop/Details';
import DropProperties from 'features/Drop/Properties';
import useCollectionQuery from 'services/api/hooks/useCollectionQuery';
import FullscreenLoader from 'components/FullscreenLoader';
import ErrorIndexRoute from 'routes/Error/Index';

const DropProductRoute = () => {
	const meta = useParams();
	const { data = {}, isLoading } = useCollectionQuery({
		requireTemplateId: true,
		meta
	});

	if (isLoading) {
		return <FullscreenLoader />;
	}

	if (!data.template.includeOnStorefront) {
		return <ErrorIndexRoute />
	}

	return (
		<div className="p-4">
			<div className="md:grid md:grid-cols-12 gap-8">
				<div className="md:col-span-5 space-y-8">
					<DropMedia disableHeading />
					<DropMeta className="md:hidden" />
					<DropProperties />
					<DropAbout />
					<DropDetails />
				</div>
				<div className="md:col-span-7">
					<DropMeta className="hidden md:block" />
				</div>
			</div>
		</div>
	);
};

export default DropProductRoute;
