import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Heading from 'components/Heading';
import getErrorMessage from 'utils/getErrorMessage';
import useJSONState from 'hooks/useJSONState';
import useUpdatePasswordMutation from 'services/api/hooks/useUpdatePasswordMutation';
import useNotificationsStore, { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_FAILURE } from 'state/hooks/useNotificationsStore';
import { useMemo, useCallback } from 'react';

const DashboardSettingsProfileSecurityForm = ({
	className
}) => {
	const notifications = useNotificationsStore();

	const [form, formActions] = useJSONState({
		password: '',
		newPassword: '',
		confirmNewPassword: ''
	});

	const { isLoading, mutate } = useUpdatePasswordMutation({
		onSuccess: (data) => {
			formActions.reset();

			notifications.add({
				type: NOTIFICATION_TYPE_SUCCESS,
				body: data?.message ?? 'Password successfully updated'
			});
		},
		onError: (data) => {
			notifications.add({
				type: NOTIFICATION_TYPE_FAILURE,
				body: getErrorMessage(data, 'Failed to update password')
			});
		}
	});

	const isDisabled = useMemo(() => {
		return (
			isLoading ||
			!form.newPassword ||
			!form.password ||
			form.newPassword === form.password ||
			form.newPassword !== form.confirmNewPassword
		);
	}, [
		isLoading,
		form,
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	return (
		<div className={ className }>
			<form onSubmit={ handleSubmit } method="post" action="/a-fake-action" disabled={ isDisabled } className="space-y-8 divide-y divide-theme-divider">
				<div className="space-y-8 divide-y divide-theme-divider sm:space-y-5">
					<div className="space-y-6 sm:space-y-5">
						<div>
							<Heading component="h3" variant="section">Security</Heading>
							<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
								Routinely updating your password is a quick and simple way to keep your account safe.
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="currentPassword" className="sm:mt-px sm:pt-2">
									Current Password
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input value={ form.password } onChange={ formActions.intercept } disabled={ isLoading } id="password" name="password" type="password" className="max-w-lg sm:max-w-xs" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="newPassword" className="sm:mt-px sm:pt-2">
									New Password
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input value={ form.newPassword } onChange={ formActions.intercept } disabled={ isLoading } id="newPassword" name="newPassword" type="password" className="max-w-lg sm:max-w-xs" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="confirmNewPassword" className="sm:mt-px sm:pt-2">
									Confirm New Password
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input value={ form.confirmNewPassword } onChange={ formActions.intercept } disabled={ isLoading } id="confirmNewPassword" name="confirmNewPassword" type="password" className="max-w-lg sm:max-w-xs" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="flex justify-end space-x-3">
						<Button disabled={ isDisabled } variant="primary">
							Update Password
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default DashboardSettingsProfileSecurityForm;
