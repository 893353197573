import useAgency from 'features/Auth/hooks/useAgency';
import BrandFeed from 'features/Brand/Feed';
import BrandAbout from 'features/Brand/About';
import BrandSocials from 'features/Brand/Socials';

const StudioProfileIndexRoute = () => {
	const agency = useAgency();

	return (
		<div className="p-4">
			<div className="lg:grid lg:grid-cols-12 lg:gap-8 space-y-8 lg:space-y-0">
				<div className="lg:col-span-7 space-y-8">
					<BrandFeed owner brandSlug={ agency?.brandSlug } />
				</div>
				<div className="lg:col-span-5 space-y-8">
					<BrandAbout editable brandSlug={ agency?.brandSlug } />
					<BrandSocials editable brandSlug={ agency?.brandSlug } />
				</div>
			</div>
		</div>
	);
};

export default StudioProfileIndexRoute;
