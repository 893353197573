import useCollectionQuery from 'services/api/hooks/useCollectionQuery';
import Heading from 'components/Heading';
import { useParams } from 'react-router-dom';
import { Fragment } from 'react';

const DropProperties = ({
	heading,
	className,
	disableHeading
}) => {
	const meta = useParams();

	const { data = {}, isLoading } = useCollectionQuery({
		requireTemplateId: true,
		meta
	});

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			<div className="grid grid-cols-3 gap-4">
				{ isLoading ? (
					<Fragment>
						<div className="h-16 bg-theme-placeholder rounded-md" />
						<div className="h-16 bg-theme-placeholder rounded-md" />
						<div className="h-16 bg-theme-placeholder rounded-md" />
						<div className="h-16 bg-theme-placeholder rounded-md" />
					</Fragment>
				) : data?.template?.attributes?.length > 0 ? data?.template?.attributes?.map(attribute => (
					<div className="p-4 bg-indigo-100 rounded-md flex flex-col min-w-0" key={ attribute?.name }>
						<span className="truncate font-medium text-indigo-800 capitalize">{ attribute?.value }</span>
						<span className="truncate text-xs text-indigo-400 capitalize">{ attribute?.name }</span>
					</div>
				)) : (
					<p className="col-span-3 text-theme-typography-dim italic">
						There are no properties assigned to this token.
					</p>
				) }
			</div>
		</div>
	);
};

DropProperties.defaultProps = {
	heading: 'Properties'
};

export default DropProperties;
