import { useMutation, useQueryClient } from '@tanstack/react-query'
import updateProfile from '../endpoints/updateProfile';

const useUpdateProfileMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(updateProfile, {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}
			
			/**
			 * Update profile query state
			 */
			queryClient.setQueryData(['profile'], data);
			
			/**
			 * Update session state with new profile changes
			 */
			queryClient.setQueryData(['session'], (currentSession) => {
				return {
					...currentSession,
					email: data?.email?.email ?? currentSession?.email,
					phone: data?.phone?.phone ?? currentSession?.phone,
					name: data?.name ?? currentSession?.name,
					profilePictureUrl: data?.profilePictureUrl ?? currentSession?.profilePictureUrl
				};
			});
		}
	});
};

export default useUpdateProfileMutation;


