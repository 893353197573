import Select from 'components/Select';
import AttributesForm from 'components/Attributes/Form';
import { useCallback, useMemo } from 'react';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';

const ConditionsForm = ({
	onRemoveButtonClick,
	onAddButtonClick,
	disableAddButton,
	disableRemoveButton,
	collections,
	className,
	onChange,
	value,
}) => {
	const handleCollectionChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		onChange({
			previous: value,
			next: {
				...value,
				context: {
					...value?.context,
					contractUuid: event?.target?.value ?? ''
				}
			}
		});
	}, [
		onChange,
		value
	]);

	const handleAttributesVariableChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		onChange({
			previous: value,
			next: {
				...value,
				context: {
					...value?.context,
					key: event?.next?.name,
					value: event?.next?.value
				}
			}
		});
	}, [
		onChange,
		value
	]);

	const handleRemoveButtonClick = useCallback(() => {
		if (typeof onRemoveButtonClick !== 'function') {
			return;
		}

		onRemoveButtonClick(value);
	}, [
		onRemoveButtonClick,
		value
	]);

	const hasValue = useMemo(() => {
		return !!value?.context?.contractUuid;
	}, [value]);

	const contractValue = useMemo(() => {
		return value?.context?.contractUuid ?? '';
	}, [value]);

	const attributeValue = useMemo(() => ({
		name: value?.context?.key ?? '',
		value: value?.context?.value ?? ''
	}), [value]);

	return (
		<div className={ className }>
			<div className="lg:grid lg:grid-cols-3 lg:gap-4">
				<div className="mb-4 lg:mb-0 lg:span-1">
					<Select defaultText="Select contract" name="collections" values={ collections } value={ contractValue } onChange={ handleCollectionChange } />
				</div>
				<div className="lg:col-span-2">
					<div className="flex items-center">
						<AttributesForm className="flex-1" disableAddButton disableRemoveButton value={ attributeValue } onChange={ handleAttributesVariableChange } />
						{ disableRemoveButton ? null : (
							<button className="ml-2" type="button" onClick={ handleRemoveButtonClick }>
								<MinusCircleIcon className="w-6 h-6 text-theme-typography-dim" />
							</button>
						) }
						{ disableAddButton ? null : (
							<button className="ml-2" type="button" disabled={ !hasValue } onClick={ onAddButtonClick }>
								<PlusCircleIcon className="w-6 h-6 text-theme-typography-dim" />
							</button>
						) }
					</div>
				</div>
			</div>
		</div>
	);
};

ConditionsForm.getInitialState = () => ({
	type: 'contract',
	context: {
		key: '',
		value: '',
		contractUuid: '',
	}
});

ConditionsForm.defaultProps = {
	value: ConditionsForm.getInitialState()
};

export default ConditionsForm;
