import { useMemo, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom';
import useJSONState from 'hooks/useJSONState';
import useForgotPasswordMutation from 'services/api/hooks/useForgotPasswordMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import getErrorMessage from 'utils/getErrorMessage';

const AuthSignUpRoute = () => {
	const notifications = useNotificationsStore();
	const { mutate, isSuccess, isLoading } = useForgotPasswordMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, "We're sorry, but we were unable to initiate the forgot password process at this time. Please verify the information you entered and try again. If you need assistance, please contact our support team"));
		}
	});

	const [form, formActions] = useJSONState({
		email: '',
		type: 'studio',
	});

	const disabled = useMemo(() => {
		return isLoading || !form.email.trim();
	}, [
		form,
		isLoading
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	if (isSuccess) {
		return <Navigate to={`/studio/auth/messages/forgot-password?email=${window.btoa(form.email)}`} />
	}

	return (
		<>
			<p className="mt-2">Don't fret! Just type in your email and we will send you a link to reset your password!</p>
			<form className="mt-4 w-full" onSubmit={handleSubmit}>
				<div className="space-y-2">
					<div className='py-2'>
						<Label htmlFor="email" className="mb-1">Email</Label>
						<Input id="email" name="email" type="email" placeholder="elmo@sesamestreet.com" required value={form.email} onChange={formActions.intercept} />
					</div>
				</div>
				<div className="py-2">
					<Button className="w-full" size="xl" disabled={disabled}>
						Recover Password
					</Button>
				</div>
			</form>
			<div className="mt-4 text-sm">
				<div className="text-right text-sm">
					Remember your account? <Link to="/studio/auth/signin" className="text-theme-primary font-medium">Sign in</Link>
				</div>
			</div>
		</>
	);
};

export default AuthSignUpRoute;


