import Button from 'components/Button';
import { Fragment, useMemo, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const Prompt = ({
	open,
	title,
	onClose,
	severity,
	children,
	primaryAction,
	secondaryAction,
	onPrimaryAction,
	onSecondaryAction
}) => {
	const [Icon, innerColor, outerColor] = useMemo(() => {
		switch (severity) {
			case 'high':
				return [ExclamationTriangleIcon, 'text-red-600', 'bg-red-100'];
			case 'medium':
				return [ExclamationTriangleIcon, 'text-yellow-600', 'bg-yellow-100'];	
			default:
				return [ExclamationTriangleIcon, 'text-blue-600', 'bg-blue-100'];

		};
	}, [severity]);

	const handlePrimaryActionClick = useCallback((event) => {
		if (onClose) {
			onClose();
		}

		if (onPrimaryAction) {
			onPrimaryAction(event);
		}
	}, [
		onPrimaryAction,
		onClose
	]);

	return (
		<Transition.Root show={ open } as={ Fragment }>
			<Dialog as="div" className="relative z-10" onClose={ onClose }>
				<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-theme-backdrop bg-opacity-75 transition-opacity" />
		        </Transition.Child>
		         <div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-theme-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
								<div className="sm:flex sm:items-start">
									<div className={ `mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${ outerColor } sm:mx-0 sm:h-10 sm:w-10` }>
										<Icon className={ `h-6 w-6 ${ innerColor }` } />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg font-medium leading-6">
											{ title }
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm text-theme-typography-dim">
												{ children }
											</p>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
									<Button onClick={ handlePrimaryActionClick } size="md" variant="primary" className="w-full py-3 sm:py-2 sm:w-auto">
										{ primaryAction }
									</Button>
									{ secondaryAction ? (
										<Button onClick={ onSecondaryAction ?? onClose } size="md" variant="neutral" className="w-full py-3 sm:py-2 sm:w-auto mt-3 sm:mt-0 sm:mr-3">
											{ secondaryAction }
										</Button>
									) : null }
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

Prompt.defaultProps = {
	severity: 'low',
	primaryAction: 'Continue',
	secondaryAction: 'Cancel'
};

export default Prompt;
