import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import createCollection from '../endpoints/createCollection';

const useCreateCollectionMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(data => createCollection(data, {
		agency
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			/**
			 * Invalidate collections via brand manager cache.
			 */
			queryClient.invalidateQueries([
				'collectionsManager',
				agency.id,
				agency.brandId
			]);
		}
	});
};

export default useCreateCollectionMutation;
