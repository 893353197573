import { useQuery } from '@tanstack/react-query'
import useAgency from 'features/Auth/hooks/useAgency';
import getUnlocksForBrandManager from '../endpoints/getUnlocksForBrandManager';

const useUnlocksForBrandManagerQuery = (options) => {
	const { id, brandId } = useAgency();

	return useQuery(['unlocksManager', id, brandId], getUnlocksForBrandManager, {
		...options,
		enabled: !!(id && brandId),
		meta: {
			agency: {
				id,
				brandId
			},
			...options?.meta,
		},
	});
};

export default useUnlocksForBrandManagerQuery;
