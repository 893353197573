import formatCurrency from 'utils/formatCurrency';
import { useMemo } from 'react';

const Currency = ({
	component: Component,
	defaultValue,
	locale,
	value,
	code,
	...rest
}) => {
	const children = useMemo(() => formatCurrency(value, {
		defaultValue,
		locale,
		code,
	}), [
		locale,
		value,
		code,
		defaultValue,
	]);

	return (
		<Component { ...rest }>
			{ children }
		</Component>
	);
};

Currency.defaultProps = {
	defaultValue: '-',
	locale: 'en-US',
	code: 'USD'
};

export default Currency;
