import Heading from 'components/Heading';
import DashboardCollectionsForm from 'features/Dashboard/Collections/Form';

const DashboardCollectionsCreateIndexRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Create Collection</Heading>
			<DashboardCollectionsForm />
		</div>
	);
};

export default DashboardCollectionsCreateIndexRoute;
