import { useMemo } from 'react';
import useSessionQuery from 'services/api/hooks/useSessionQuery';

const useAgency = (callback) => {
	const { data = {}, isLoading } = useSessionQuery();

	return useMemo(() => {
		const agency = data?.agencies?.find(agency => agency?.uuid === data?.selectedAgency) ?? {};
		const brand = agency?.brands?.[0] ?? {};
		const page = brand?.page ?? {};

		const state = {
			id: agency.uuid,
			brandId: brand.uuid,
			brandName: brand.name,
			brandSlug: brand.slug,
			pageId: page.uuid,
			pageName: page.name,
			pageSlug: page.slug,
			accountId: data?.uuid,
			brandSetupRequired: data?.usernameGenerated || !brand.name,
			isLoading
		};

		return state;
	}, [
		isLoading,
		data
	]);
};

export default useAgency;
