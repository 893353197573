import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useBrandPageQuery from 'services/api/hooks/useBrandPageQuery';
import useUpdateBrandPageMutation from 'services/api/hooks/useUpdateBrandPageMutation';
import useUploadMutation from 'services/api/hooks/useUploadMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import getErrorMessage from 'utils/getErrorMessage';
import BrandFollowButton from './FollowButton';
import Banner from 'components/Banner';
import FullscreenLoader from 'components/FullscreenLoader';
import { useCallback, Fragment } from 'react';

const BrandBanner = ({
	brandSlug,
	className,
	editable
}) => {
	const notifications = useNotificationsStore();

	const { mutate, isLoading } = useUpdateBrandPageMutation({
		onSuccess: () => {
			notifications.success('Brand media successfully updated');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update brand media'));
		},
	});

	const { mutate: mutateSource, isLoading: isLoadingSource } = useUploadMutation({
		onSuccess: ({ uri: bannerUrl }) => {
			mutate({ bannerUrl });
		}
	});

	const { mutate: mutateAvatar, isLoading: isLoadingAvatar } = useUploadMutation({
		onSuccess: ({ uri: coverUrl }) => {
			mutate({ coverUrl });
		}
	});

	const { data = {} } = useBrandPageQuery(useRouteParamsAsMeta({
		brandSlug
	}));

	const handleSourceChange = useCallback(event => {
		mutateSource(event.value);
	}, [mutateSource]);

	const handleAvatarChange = useCallback(event => {
		mutateAvatar(event.value);
	}, [mutateAvatar]);

	return (
		<Fragment>
			{ isLoading || isLoadingSource || isLoadingAvatar ? (
				<FullscreenLoader />
			) : null }
			<Banner
				src={ data?.community?.details?.banner || `/images/banner.jpg` }
				title={ data?.name }
				avatar={ data?.community?.details?.cover }
				verified={ true }
				username={ data?.slug }
				className={ className }
				editable={ editable }
				description={ null }
				actions= { editable ? null : [
					<BrandFollowButton key="follow" />
				] }
				onSourceChange={ handleSourceChange }
				onAvatarChange={ handleAvatarChange }
			/>
		</Fragment>
	);
};

export default BrandBanner;
