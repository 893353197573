import { useMutation } from '@tanstack/react-query'
import createTemplate from '../endpoints/createTemplate';
import useAgency from 'features/Auth/hooks/useAgency';

const useCreateTemplateMutation = (options) => {
	const agency = useAgency();

	return useMutation(data => createTemplate(data, {
		agency,
		collectionId: options?.meta?.collectionId
	}), options);
};

export default useCreateTemplateMutation;
