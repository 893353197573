import { useQuery } from '@tanstack/react-query'
import useIsAuthorized from 'hooks/useIsAuthorized';
import getProfile from '../endpoints/getProfile';

const useProfileQuery = (options) => {
	const isAuthorized = useIsAuthorized();

	return useQuery(['profile'], getProfile, {
		...options,
		enabled: isAuthorized
	});
};

export default useProfileQuery;
