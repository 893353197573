import { useMemo } from 'react';
import EthereumIcon from './Icon';
import useEthereumSpotPriceQuery from 'services/api/hooks/useEthereumSpotPriceQuery';
import big from 'big.js';

const EthereumPrice = ({
	component: Component,
	defaultValue,
	value,
	...rest
}) => {
	const { data, isLoading } = useEthereumSpotPriceQuery({
		refetchInterval: 30000,
		enabled: !!value
	});

	const children = useMemo(() => {
		if (isLoading) {
			return defaultValue;
		}

		try {
			return big(value).div(data).toFixed(6);
		} catch (err) {
			return defaultValue;
		}
	}, [
		defaultValue,
		isLoading,
		value,
		data
	]);

	return (
		<Component { ...rest } title="Ethereum Price">
			<span className="flex items-center">
				<EthereumIcon className="w-5 h-5 mr-1" />
				{ children }
			</span>
		</Component>
	);
};

EthereumPrice.defaultProps = {
	component: 'span',
	defaultValue: '-'
};

export default EthereumPrice;
