import client from '../client';

const getUnlocksForBrandManager = (options) => {
	const agency = options?.meta?.agency ?? {};

	if (!(agency.id && agency.brandId)) {
		return Promise.reject(new Error('Unable to fetch unlocks without valid agency/brand id'));
	}

	return client
		.get(`/agencies/${ agency.id }/brands/${ agency.brandId }/unlocks`, {
			params: {
				includeArchived: options?.meta?.includeArchived ? 1 : 0
			}
		})
		.then(response => response?.data)
	;
};

export default getUnlocksForBrandManager;
