import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useBrandPageQuery from 'services/api/hooks/useBrandPageQuery';
import useFollowBrandMutation from 'services/api/hooks/useFollowBrandMutation';
import useUnfollowBrandMutation from 'services/api/hooks/useUnfollowBrandMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import getErrorMessage from 'utils/getErrorMessage';
import Button from 'components/Button';
import AuthDialog from 'features/Auth/Dialog';
import useIsAuthorized from 'hooks/useIsAuthorized';
import { HeartIcon as HeartIconOutline } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import { useMemo, useState, useCallback, Fragment } from 'react';
import clsx from 'clsx';

const BrandFollowButton = ({
	brandSlug,
	className,
}) => {
	const [showAuthDialog, setShowAuthDialog] = useState(false);

	const isAuthorized = useIsAuthorized();

	const params = useRouteParamsAsMeta({
		brandSlug
	});

	const notifications = useNotificationsStore();

	const { mutate: followBrand, isLoading: isLoadingFollow } = useFollowBrandMutation({
		meta: params.meta,
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to follow brand!'));
		},
	});

	const { mutate: unfollowBrand, isLoading: isLoadingUnfollow } = useUnfollowBrandMutation({
		meta: params.meta,
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to unfollow brand!'));
		},
	});

	const { data = {}, isLoading } = useBrandPageQuery(params);

	const isDisabled = useMemo(() => {
		return (
			isLoading ||
			isLoadingFollow ||
			isLoadingUnfollow ||
			!data.uuid
		);
	}, [
		isLoading,
		isLoadingFollow,
		isLoadingUnfollow,
		data
	]);

	const Icon = useMemo(() => {
		if (data?.following) {
			return HeartIconSolid;
		}

		return HeartIconOutline;
	}, [data]);

	const handleClick = useCallback(event => {
		event?.preventDefault();

		if (!isAuthorized) {
			setShowAuthDialog(true);
			return;
		}

		if (!data?.uuid) {
			return;
		}

		const action = data?.following ? unfollowBrand : followBrand;

		action({
			brandId: data.uuid
		});
	}, [
		data,
		followBrand,
		isAuthorized,
		unfollowBrand,
		setShowAuthDialog
	]);

	const handleAuthDialogClose = useCallback(() => {
		setShowAuthDialog(false);

		if (isAuthorized) {
			handleClick();
		}
	}, [
		handleClick,
		isAuthorized
	]);

	return (
		<Fragment>
			<Button onClick={handleClick} disabled={isDisabled} key="follow" className={clsx('items-center', className)} variant="neutral">
				<Icon className="w-4 h-4 mr-2" />Follow
			</Button>
			<AuthDialog
				open={showAuthDialog}
				onClose={handleAuthDialogClose}
			/>
		</Fragment>
	);
};

export default BrandFollowButton;
