import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import archiveUnlock from '../endpoints/archiveUnlock';

const useArchiveUnlockMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(() => archiveUnlock({
		agency,
		unlockId: options?.unlockId
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.invalidateQueries({
				queryKey: ['unlockManager', agency.id, agency.brandId, options?.unlockId],
				refetchType: 'none'
			});

			queryClient.setQueryData(['unlocksManager', agency.id, agency.brandId], (state) => {
				return {
					...state,
					unlocks: state?.unlocks?.filter(unlock => {
						console.info('removing');
						return unlock.uuid !== options?.unlockId;
					})
				};
			});
		}
	});
};

export default useArchiveUnlockMutation;
