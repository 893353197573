import { useQuery } from '@tanstack/react-query'
import getCollection from '../endpoints/getCollection';

const useCollectionQuery = (options) => {
	const collectionId = options?.meta?.collectionId;
	const templateId = options?.meta?.templateId;

	return useQuery(['collection', collectionId, templateId], getCollection, {
		...options,
		enabled: !!(collectionId && (options?.requireTemplateId ? templateId : true) && (typeof options?.enabled !== 'undefined' ? options.enabled : true))
	});
};

export default useCollectionQuery;
