import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import SetupBrand from '../endpoints/setupBrand';

const useSetupBrandMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();

	return useMutation(data => SetupBrand(data, {
		agency
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.invalidateQueries({
				queryKey: ['session']
			});
		}
	});
};

export default useSetupBrandMutation;