import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useUnlockQuery from 'services/api/hooks/useUnlockQuery';
import useAttachUnlockMutation from 'services/api/hooks/useAttachUnlockMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import getErrorMessage from 'utils/getErrorMessage';
import Heading from 'components/Heading';
import Button from 'components/Button';
import prettyBytes from 'pretty-bytes';
import { Link } from 'react-router-dom';
import { useMemo, useCallback, Fragment } from 'react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

const BrandUnlock = ({
	className,
	disableHeading
}) => {
	const notifications = useNotificationsStore();

	const { data = {}, isLoading } = useUnlockQuery(useRouteParamsAsMeta());

	const { mutate: attach, isLoading: isLoadingAttach } = useAttachUnlockMutation({
		onSuccess: (data) => {
			notifications.success('Content successfully unlocked!');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to unlock content!'));
		}
	});

	const handleAttachClick = useCallback(() => {
		if (!data?.uuid) {
			return;
		}

		attach({
			unlockId: data.uuid
		});
	}, [
		data,
		attach
	]);
	
	const mediaType = useMemo(() => {
		if (data?.privateContext?.type !== 'file') {
			return null;
		}

		const type = data?.privateContext?.meta?.type ?? '';

		if (type.indexOf('image/') === 0) {
			return 'image';
		}

		if (type.indexOf('video/') === 0) {
			return 'video';
		}

		return null;
	}, [data]);

	const isFile = useMemo(() => {
		return data?.privateContext?.type === 'file';
	}, [data]);

	const isUnlocked = useMemo(() => {
		return data?.hasAccess && data?.attached;
	}, [data]);

	return (
		<div className={ className }>
			{ disableHeading ? null : (
				<div className="mb-4">
					{ isLoading ? (
						<div className="space-y-1">
							<div className="h-4 w-3/4 rounded-md bg-theme-placeholder" />
							<div className="h-4 w-1/2 rounded-md bg-theme-placeholder" />
						</div>
					) : (
						<Heading variant="section">
							{ data?.title }
						</Heading>
					) }
					<Link className="text-sm font-medium text-theme-primary" to="..">Back to unlocks</Link>
				</div>
			) }
			<div className="space-y-4 max-w-2xl mb-8">
				<div className="bg-theme-placeholder rounded-md aspect-[16/9] overflow-hidden">
					{ isUnlocked && mediaType === 'video' ? (
						<video src={ data?.privateContext?.url } className="w-full h-full object-contain" controls />
					) : data?.coverImageUrl ? (
						<img src={ data?.coverImageUrl } alt={ data?.title } className="w-full h-full object-cover" />
					) : null }
				</div>
				<div>
					<h3 className="font-bold tracking-tight mb-1">About this unlock</h3>
					{ isLoading ? (
						<div className="space-y-1">
							<div className="h-4 w-3/4 rounded-md bg-theme-placeholder" />
							<div className="h-4 w-1/4 rounded-md bg-theme-placeholder" />
							<div className="h-4 w-1/2 rounded-md bg-theme-placeholder" />
						</div>
					) : (
						<p className="text-sm text-theme-typography-dim max-w-lg">{ data?.description }</p>
					) }
				</div>
				{ !data?.hasAccess ? (
					<div>
						<h3 className="font-bold tracking-tight mb-1">Conditions</h3>
						{ isLoading ? (
							<div className="space-y-1">
								<div className="h-4 w-3/4 rounded-md bg-theme-placeholder" />
								<div className="h-4 w-1/4 rounded-md bg-theme-placeholder" />
								<div className="h-4 w-1/2 rounded-md bg-theme-placeholder" />
							</div>
						) : data?.conditions?.length > 0 ? (
							<div className="text-sm text-theme-typography-dim max-w-lg">
								<p className="mb-4">To obtain this unlock, you'll need to meet <span className="font-bold">{ data?.any ? 'ANY' : 'ALL' }</span> of the following requirements:</p>
								<div className="space-y-2">
									{ data?.conditions.map(condition => {
										if (condition?.context?.key && condition?.context?.value) {
											return (
												<p key={ condition?.uuid }>• Claim a token from the <span className="font-medium">{ condition?.context?.collection?.name }</span> collection with the <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">{ condition.context.key }</span> attribute equal to <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">{ condition.context.value }</span></p>
											);
										}

										return (
											<p key={ condition?.uuid }>• Claim any token from the <span className="font-medium">{ condition?.context?.collection?.name }</span> collection</p>
										);
									}) }
								</div>
							</div>
						) : (
							<p className="text-sm text-theme-typography-dim max-w-lg">
								There are no conditions to obtain this unlock.
							</p>
						) }
					</div>
				) : null }
				{ data?.hasAccess || isLoading ? (
					<div>
						<h3 className="font-bold tracking-tight mb-1">Instructions</h3>
						{ !isLoading && isUnlocked ? (
							<p className="text-sm text-theme-typography-dim max-w-lg">
								{ data?.privateContext?.instructions || 'No instructions provided.' }
							</p>
						) : (
							<div className="space-y-1">
								<div className="h-4 w-3/4 rounded-md bg-theme-placeholder" />
								<div className="h-4 w-1/4 rounded-md bg-theme-placeholder" />
								<div className="h-4 w-1/2 rounded-md bg-theme-placeholder" />
							</div>
						) }
					</div>
				) : null }
				{ data?.hasAccess || isLoading ? (
					<div>		
						<h3 className="font-bold tracking-tight mb-1">Details</h3>
						{ !isLoading && isUnlocked ? (
							<div className="flex text-sm">
								<div className="mr-4 font-medium">
									{ isFile ? (
										<Fragment>
											<div>Name</div>
											<div>Type</div>
											<div>Size</div>
										</Fragment>
									) : null }
									<div>URL</div>
								</div>
								<div className="text-theme-typography-dim whitespace-nowrap min-w-0">
									{ isFile ? (
										<Fragment>
											<div className="truncate">{ data?.privateContext?.meta?.name }</div>
											<div className="truncate">{ data?.privateContext?.meta?.type }</div>
											<div className="truncate">{ prettyBytes(data?.privateContext?.meta?.size ?? 0) }</div>
										</Fragment>
									) : null }
									<a rel="noreferrer" href={ data?.privateContext?.url } target="_blank" className="truncate block text-theme-primary font-medium">{ data?.privateContext?.url }</a>
								</div>
							</div>
						) : (
							<div className="space-y-1">
								<div className="h-4 w-3/4 rounded-md bg-theme-placeholder" />
								<div className="h-4 w-1/4 rounded-md bg-theme-placeholder" />
								<div className="h-4 w-1/2 rounded-md bg-theme-placeholder" />
							</div>
						) }
					</div>
				) : null }
				{ isUnlocked || !data.hasAccess ? null : (
					<Button disabled={ isLoadingAttach } onClick={ handleAttachClick } variant="primary" size="xl" className="w-full items-center">
						<LockClosedIcon className="w-4 h-4 mr-2" />Reveal Details and Instructions
					</Button>
				) }
			</div>
		</div>
	);
};

export default BrandUnlock;
