import useTemplateForBrandManagerQuery from 'services/api/hooks/useTemplateForBrandManagerQuery';
import DashboardCollectionTokenFreeForm from './FreeForm';
import Skeleton from 'components/Skeleton';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import getErrorMessage from 'utils/getErrorMessage';
import { Navigate, useParams } from 'react-router-dom';

const DashboardCollectionTokenEditForm = ({
	className
}) => {
	const meta = useParams();
	const notifications = useNotificationsStore();
	const { data, isLoading, isSuccess, isError } = useTemplateForBrandManagerQuery({
		meta,
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Unable to retrieve token details.'));
		}
	});

	if (isLoading) {
		return (
			<Skeleton className={className} />
		);
	}

	/**
	 * @TODO
	 * Parse data from query and figure out if free or priced token then
	 * render appropriate form.
	 */
	if (isSuccess) {
		switch (data?.claimMethod?.type) {
			case 'airdrop':
				return <DashboardCollectionTokenFreeForm className={className} />
			case 'claim':
				return <DashboardCollectionTokenFreeForm className={className} />
			default:
				return <Navigate to=".." replace />
		};
	}

	if (isError) {
		return <Navigate to=".." replace />
	}
};

export default DashboardCollectionTokenEditForm;
