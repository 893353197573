import FullscreenLoader from 'components/FullscreenLoader';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import clsx from 'clsx';
import useAccountInviteQuery from 'services/api/hooks/useAccountInviteQuery';
import useRegisterAccountMutation from 'services/api/hooks/useRegisterAccountMutation';
import useRegisterAccountInviteMutation from 'services/api/hooks/useRegisterAccountInviteMutation';
import useJSONState from 'hooks/useJSONState';
import getErrorMessage from 'utils/getErrorMessage';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { Fragment, useMemo, useCallback } from 'react';

const AuthSignUpForm = ({
	embedded,
	className,
	onAccountSignInRequest
}) => {
	const [params] = useSearchParams();
	
	const notifications = useNotificationsStore();

	const code = params.get('c');

	const { mutate, isSuccess, isLoading } = useRegisterAccountMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to setup account.'));
		}	
	});

	const { mutate: mutateInvite, isSuccess: isSuccessInvite, isLoading: isLoadingInvite } = useRegisterAccountInviteMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to setup account.'));
		}
	});

	const { isInitialLoading: isInitialLoadingInvite } = useAccountInviteQuery({
		onSuccess: (data) => {
			formActions.extend({
				email: data?.email
			});

			notifications.notice({
				ttl: 30,
				image: data?.template?.image,
				title: `Claim ${ data?.template?.name }`,
				body: 'Finish setting up your account to receive your free token!',
			});
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to obtain invite information.'));
		},
		enabled: !embedded,
		meta: {
			code
		}
	});
	
	const [form, formActions] = useJSONState({
		email: '',
		password: '',
		passwordConfirmation: ''
	});

	const disabled = useMemo(() => {
		return (
			isLoading ||
			isLoadingInvite ||
			isInitialLoadingInvite ||
			!form.email.trim() ||
			!form.password.trim() ||
			form.password !== form.passwordConfirmation
		);
	}, [
		form,
		isLoading,
		isLoadingInvite,
		isInitialLoadingInvite
	]);

	const handleSubmit = useCallback(event => {
		const action = code ? mutateInvite : mutate;
		
		event.preventDefault();
		
		action(form);
	}, [
		form,
		code,
		mutate,
		mutateInvite
	]);

	if (!embedded && (isSuccess || isSuccessInvite)) {
		return (
			<Navigate to="/authorize/setup" replace />
		);
	}

	return (
		<Fragment>
			<form onSubmit={ handleSubmit } action="/a-fake-action" className={ clsx('space-y-8', className) }>
				<div className="space-y-4">
					<Heading>{ code ? 'Finish creating account' : 'Create an account' }</Heading>
				</div>
				<div className="space-y-2">
					<div>
						<Label htmlFor="email" className="mb-1">Email Address</Label>
						<Input disabled={ !!code } id="email" name="email" type="email" placeholder={ code ? '' : 'elmo@sesamestreet.com' } required value={ form.email } onChange={ formActions.intercept } />
					</div>
					<div>
						<Label htmlFor="password" className="mb-1">Password</Label>
						<Input id="password" name="password" type="password" required onChange={ formActions.intercept } value={ form.password } />
					</div>
					<div>
						<Label htmlFor="passwordConfirmation" className="mb-1">Confirm Password</Label>
						<Input id="passwordConfirmation" name="passwordConfirmation" type="password" required onChange={ formActions.intercept } value={ form.passwordConfirmation } />
					</div>
					{ code ? null : (
						<div className="text-right text-sm">
							Already have an account? { embedded ? (
								<button type="button" onClick={ onAccountSignInRequest } className="text-theme-primary font-medium">Sign in</button>
							) : (
								<Link to="/authorize" className="text-theme-primary font-medium">Sign in</Link>
							) }
						</div>
					) }
				</div>
				<div className="space-y-4">
					<Button className="w-full" size="xl" disabled={ disabled }>
						Continue
					</Button>
				</div>
			</form>
			{ isInitialLoadingInvite ? (
				<FullscreenLoader>
					Retreiving invitation details...
				</FullscreenLoader>
			) : null }
		</Fragment>
	);
};

export default AuthSignUpForm;
