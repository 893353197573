import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Toggle from 'components/Toggle';
import Heading from 'components/Heading';
import Textarea from 'components/Textarea';
import Prompt from 'components/Prompt';
import Upload from 'components/Upload';
import AttributesInput from 'components/Attributes/Input';
import useJSONState from 'hooks/useJSONState';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useCreateTemplateMutation from 'services/api/hooks/useCreateTemplateMutation';
import useUpdateTemplateMutation from 'services/api/hooks/useUpdateTemplateMutation';
import useArchiveTemplateMutation from 'services/api/hooks/useArchiveTemplateMutation';
import useTemplateForBrandManagerQuery from 'services/api/hooks/useTemplateForBrandManagerQuery';
import getErrorMessage from 'utils/getErrorMessage';
import { useState, useMemo, useCallback } from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';

const DashboardCollectionTokenFreeForm = ({
	className
}) => {
	const meta = useParams();

	const notifications = useNotificationsStore();

	const isEditing = !!meta?.templateId;

	const [showArchivePrompt, setShowArchivePrompt] = useState(false);

	const [form, formActions] = useJSONState(() => ({
		title: '',
		capacity: '',
		imageUrl: '',
		videoUrl: '',
		externalUrl: '',
		description: '',
		attributes: AttributesInput.getInitialState(),
		includeOnStorefront: true,
		claimable: true,
	}));

	const { isSuccess, mutate, isLoading, data: template } = useCreateTemplateMutation({
		meta,
		onSuccess: (data) => {
			notifications.success('A new token was created.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to create token.'));
		}
	});

	const { data: savedForm, isLoading: isLoadingForm } = useTemplateForBrandManagerQuery({
		meta,
		onSuccess: (data) => {
			formActions.extend({
				title: data?.title ?? '',
				externalUrl: data?.externalUrl ?? '',
				description: data?.description ?? '',
				imageUrl: data?.imageUrl ?? '',
				videoUrl: data?.videoUrl ?? '',
				capacity: data?.capacity ?? '',
				includeOnStorefront: !!data?.includeOnStorefront,
				claimable: data?.claimMethod?.type === 'claim',
				attributes: data?.attributes?.length > 0 ? data.attributes : AttributesInput.getInitialState()
			});
		}
	});

	const { mutate: mutateArchive, isLoading: isArchivingForm, isSuccess: isSuccessfulArchive } = useArchiveTemplateMutation({
		templateId: meta?.templateId,
		collectionId: meta?.collectionId,
		onSuccess: (data) => {
			notifications.success('Token was archived successfully.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to archive token.'));
		}
	});

	const { mutate: mutateUpdate, isLoading: isUpdatingForm, isSuccess: isSuccessfulUpdate } = useUpdateTemplateMutation({
		templateId: meta?.templateId,
		collectionId: meta?.collectionId,
		onSuccess: (data) => {
			notifications.success('Token was updated successfully.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update token.'));
		}
	});

	const handleArchiveClick = useCallback(() => {
		setShowArchivePrompt(true);
	}, [setShowArchivePrompt]);

	const handleArchiveConfirm = useCallback(event => {
		event.preventDefault();
		mutateArchive();
	}, [mutateArchive]);

	const handleArchiveDismiss = useCallback(event => {
		setShowArchivePrompt(false);
	}, [setShowArchivePrompt]);

	const handleFormSubmit = useCallback(event => {
		event.preventDefault();

		const action = isEditing ? mutateUpdate : mutate;
		const capacity = parseInt(form.capacity, 10);
		const { claimable, ...rest } = form;

		action({
			...rest,
			capacity: isNaN(capacity) || !isFinite(capacity) ? 0 : capacity,
			attributes: form.attributes.filter(item => !!(item.value && item.name)),
			claimMethod: { type: claimable ? 'claim' : 'airdrop' }
		});
	}, [
		form,
		mutate,
		isEditing,
		mutateUpdate
	]);

	/**
	 * Add additional validation here to keep disabled.
	 */
	const isDisabled = useMemo(() => {
		return (
			isLoading ||
			(isEditing && isLoadingForm) ||
			isUpdatingForm ||
			isArchivingForm ||
			!form.title?.trim()
		);
	}, [
		form,
		isLoading,
		isEditing,
		isLoadingForm,
		isUpdatingForm,
		isArchivingForm
	]);

	/**
	 * Navigate to updated tokens on success.
	 */
	if (isSuccessfulUpdate || isSuccessfulArchive) {
		return (
			<Navigate to=".." replace />
		);
	}

	/**
	 * Navigate to created tokens on success.
	 */
	if (isSuccess) {
		return (
			<Navigate to={`../..?s=${template?.templateUuid}`} replace />
		);
	}

	return (
		<div className={className}>
			<form disabled={isDisabled} onSubmit={handleFormSubmit} method="POST" action="/fake-action-for-ios" className="space-y-8 divide-y divide-theme-divider">
				<div className="space-y-8 divide-y divide-theme-divider sm:space-y-5">
					<div className="space-y-6 sm:space-y-5">
						<div>
							<Heading component="h3" variant="section">General</Heading>
							<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
								This information will be displayed publicly so be careful what you share.
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="title" className="sm:mt-px sm:pt-2">
									Name
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input disabled={isLoadingForm && isEditing} value={form.title} onChange={formActions.intercept} id="title" name="title" type="text" className="max-w-lg sm:max-w-xs" placeholder="e.g. Puppy dog scarf" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="externalUrl" className="sm:mt-px sm:pt-2">
									External URL
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input disabled={isLoadingForm && isEditing} value={form.externalUrl} onChange={formActions.intercept} id="externalUrl" name="externalUrl" type="text" prefix="https://" className="max-w-lg" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="description" className="sm:mt-px sm:pt-2">
									Description
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Textarea disabled={isLoadingForm && isEditing} value={form.description} onChange={formActions.intercept} id="description" name="description" rows="6" className="max-w-lg" placeholder="A little bit about your token." />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<div className="sm:mt-px sm:pt-2">
									<Label htmlFor="capacity" className="mb-1">
										Supply
									</Label>
									<p className="text-theme-typography-dim text-sm mb-4 sm:mb-0">
										For an unlimited supply, leave blank or set to zero (0).
									</p>
								</div>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input disabled={isLoadingForm && isEditing} value={form.capacity} onChange={formActions.intercept} min="0" id="capacity" name="capacity" type="number" className="max-w-lg sm:max-w-xs" placeholder="Unlimited" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<div className="sm:mt-px sm:pt-2">
									<Label className="mb-1" htmlFor="includeOnStorefront">
										Include on storefront?
									</Label>
									<p className="text-theme-typography-dim text-sm mb-4 sm:mb-0">
										Items on storefront are publicly available. Leave off if you only want users with a direct link to access your NFTs.
									</p>
								</div>
								<Toggle disabled={isLoadingForm && isEditing} checked={form.includeOnStorefront} name="includeOnStorefront" id="includeOnStorefront" onChange={formActions.intercept} className="mt-1 sm:col-span-2 sm:mt-0" />
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<div className="sm:mt-px sm:pt-2">
									<Label className="mb-1" htmlFor="claimable">
										Claimable?
									</Label>
									<p className="text-theme-typography-dim text-sm mb-4 sm:mb-0">
										If you do not want your tokens to be publicly claimable, please contact us to help you distribute them.
									</p>
								</div>
								<Toggle disabled={isLoadingForm || isEditing} checked={form.claimable} name="claimable" id="claimable" onChange={isEditing ? null : formActions.intercept} className="mt-1 sm:col-span-2 sm:mt-0" />
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<div className="sm:mt-px sm:pt-2">
									<Label className="mb-1">
										Attributes
									</Label>
									<p className="text-theme-typography-dim text-sm mb-4 sm:mb-0">
										Attributes show up underneath your item and also act as tags for unlocks and acess to community pages.
									</p>
								</div>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<AttributesInput disabled={isLoadingForm && isEditing} name="attributes" values={form.attributes} onChange={formActions.intercept} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
					<div>
						<Heading component="h3" variant="section">Media</Heading>
						<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
							Show off your token by setting up a feature image.
						</p>
					</div>
					<div className="space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
							<Label className="sm:mt-px sm:pt-2">
								Feature Image
							</Label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<Upload disabled={isLoadingForm && isEditing} image name="imageUrl" value={form.imageUrl} onChange={formActions.intercept} className="max-w-lg sm:max-w-xs aspect-[1/1]" />
							</div>
						</div>
					</div>

					<div className="space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
							<Label className="sm:mt-px sm:pt-2">
								Feature Video
							</Label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<Upload
									accept='video/*'
									acceptText='Video files up to 50mb'
									disabled={isLoadingForm && isEditing}
									video
									name="videoUrl"
									value={form.videoUrl}
									onChange={formActions.intercept}
									className="max-w-lg sm:max-w-xs aspect-[1/1]"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="flex justify-end space-x-3">
						<Button component={Link} to={isEditing ? '..' : '../..'} variant="neutral">
							Cancel
						</Button>
						{isEditing ? (
							<Button onClick={handleArchiveClick} type="button" variant="neutral">
								Archive
							</Button>
						) : null}
						<Button disabled={isDisabled} type="submit" variant="primary">
							Save
						</Button>
					</div>
				</div>
			</form>
			{isEditing ? (
				<Prompt
					open={showArchivePrompt}
					onClose={handleArchiveDismiss}
					severity="high"
					title="Archive Token"
					primaryAction="Archive"
					onPrimaryAction={handleArchiveConfirm}>
					Archiving a token will remove it from your collection. Are you sure you want to archive <strong>{savedForm?.title ?? 'this token'}</strong>?
				</Prompt>
			) : null}
		</div>
	);
};

export default DashboardCollectionTokenFreeForm;
