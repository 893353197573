import { useQuery } from '@tanstack/react-query'
import getFollowedBrandPages from '../endpoints/getFollowedBrandPages';

const useFollowedBrandPagesQuery = (options) => {
	return useQuery(['followedBrandPages'], getFollowedBrandPages, {
		...options
	});
};

export default useFollowedBrandPagesQuery;
