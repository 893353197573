import useBrandPageMessagesQuery from 'services/api/hooks/useBrandPageMessagesQuery';
import NotificationsList from 'components/Notifications/List';
import NotificationsListItem from 'components/Notifications/ListItem';

const BrandFeedMessages = ({
	owner,
	brandName,
	brandPageId
}) => {
	const { data = {}, isLoading } = useBrandPageMessagesQuery({
		meta: {
			brandPageId
		}
	});

	if (isLoading) {
		return (
			<NotificationsList>
				<NotificationsListItem className="pt-0" loading />
				<NotificationsListItem loading />
				<NotificationsListItem loading />
				<NotificationsListItem loading />
			</NotificationsList>
		);
	}

	if (!data?.messages?.length) {
		return (
			<div className="text-sm text-theme-typography-dim italic">
				{ brandName || 'This brand' } has yet to post any messages.
			</div>
		);
	}

	return (
		<NotificationsList>
			{ data?.messages?.map(({ uuid, content, context, created, deleted, accountUuid, accountUsername, accountProfilePictureUrl, reactions, interactions }, index) => (
				<NotificationsListItem
					id={ uuid }
					key={ uuid }
					owner={ owner }
					userId={ accountUuid }
					avatar={ accountProfilePictureUrl }
					created={ created }
					context={ context }
					username={ accountUsername }
					reactions={ reactions }
					className={ index === 0 ? 'pt-0' : undefined }
					interactions={ interactions }
					disableMenu
					disableActions>
					{ content }
				</NotificationsListItem>
			)) }
		</NotificationsList>
	);
};

export default BrandFeedMessages;
