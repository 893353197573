import { useMemo, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom';
import useJSONState from 'hooks/useJSONState';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import useAccessAccountMutation from 'services/api/hooks/useAccessAccountMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import getErrorMessage from 'utils/getErrorMessage';

const AuthSignUpRoute = () => {
	const isAuthorized = useIsAuthorized();
	const notifications = useNotificationsStore();

	const { data: session, isSuccess } = useSessionQuery();

	const { mutate, isLoading } = useAccessAccountMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, "Please verify your credentials and try again. If you're still having trouble, please contact our support team for assistance."));
		}
	});

	const [form, formActions] = useJSONState({
		identity: '',
		password: ''
	});

	const disabled = useMemo(() => {
		return isLoading || !form.identity.trim() || !form.password.trim();
	}, [
		form,
		isLoading
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	if (isAuthorized && isSuccess) {
		const [brand] = session?.agencies?.find(a => a.uuid === session.selectedAgency)?.brands ?? [];
		const requiresBrandSetup = session?.usernameGenerated || !brand?.name;

		if (requiresBrandSetup) {
			return (
				<Navigate to="/studio/auth/brand" />
			);
		}

		return (
			<Navigate to="/studio" replace />
		);
	}

	return (
		<>
			<p className="mt-2">Create, Distribute, and Manage NFTs, Communities, and Unlocks</p>
			<form className="mt-4 w-full" onSubmit={handleSubmit}>
				<div className="space-y-2">
					<div className='py-2'>
						<Label htmlFor="email" className="mb-1">Email</Label>
						<Input id="identity" name="identity" type="email" autoComplete="email" required value={form.identity} onChange={formActions.intercept} />
					</div>
					<div className='py-2'>
						<Label htmlFor="password" className="mb-1">Password</Label>
						<Input id="password" name="password" type="password" autoComplete="password" minLength="6" required value={form.password} onChange={formActions.intercept} />
					</div>
				</div>
				<div className="py-2">
					<Button className="w-full" size="xl" disabled={disabled}>
						Sign In
					</Button>
				</div>
			</form>
			<div className="mt-4 text-sm">
				<div className="text-right text-sm">
					Don't have an account? <Link to="/studio/auth/signup" className="text-theme-primary font-medium">Create account</Link>
				</div>
			</div>
			<div className="mt-4 text-sm justify-end">
				<div className="text-right text-sm">
					<Link to="/studio/auth/forgot-password" className="text-theme-primary font-medium">Forgot password?</Link>
				</div>
			</div>
		</>
	);
};

export default AuthSignUpRoute;


