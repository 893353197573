import ConditionsForm from './Form';
import { useCallback } from 'react';

const ConditionsInput = ({
	collections,
	className,
	onChange,
	values,
	name
}) => {
	const handleConditionAdd = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = [...values, ConditionsForm.getInitialState()];

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});	
	}, [
		onChange,
		values,
		name
	]);

	const handleConditionChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = values.map(value => {
			if (value === event.previous) {
				return event.next
			}

			return value;
		});

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});	
	}, [
		onChange,
		values,
		name
	]);

	const handleConditionRemove = useCallback(condition => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = values.filter(current => current !== condition);

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});	
	}, [
		onChange,
		values,
		name
	]);

	return (
		<div className={ className }>
			<div className="space-y-2 mb-4">
				{ values?.map((value, index) => (
					<ConditionsForm
						key={ index }
						value={ value }
						collections={ collections }
						onChange={ handleConditionChange }
						onAddButtonClick={ handleConditionAdd }
						onRemoveButtonClick={ handleConditionRemove }
						disableAddButton={ index !== values?.length - 1 }
						disableRemoveButton={ index === values?.length - 1 }
					/>
				)) }
			</div>
		</div>
	);
};

ConditionsInput.getInitialState = () => [
	ConditionsForm.getInitialState()
];

ConditionsInput.defaultProps = {
	values: ConditionsInput.getInitialState()
};

export default ConditionsInput;
