import ConsumerHeader from 'features/Consumer/Header';
import { Outlet } from 'react-router-dom';

const TokenTemplate = () => {
	return (
		<div className="min-h-full">
			<ConsumerHeader />
			<div className="w-full border-t border-theme-backdrop my-2"></div>
			<main className="w-full max-w-7xl mx-auto">
				<Outlet />
			</main>
		</div>
	);
};

export default TokenTemplate;
