import { useRef, useEffect } from 'react';

const useSetDocumentTitle = (title) => {
	const previous = useRef(document.title);

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => () => {
		document.title = previous.current;
	}, []);
};

export default useSetDocumentTitle;
