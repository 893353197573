import client from '../client';

const getBrandPageMessages = (options = {}) => {
	const brandPageId = options?.meta?.brandPageId;

	if (!brandPageId) {
		return Promise.reject(new Error('Unable to fetch brand page messages without valid brand page id'));
	}

	return client
		.get(`/pages/${ brandPageId }/messages`)
		.then(response => response?.data)
	;
};

export default getBrandPageMessages;
