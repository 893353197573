import client from '../client';

const accessAccount = (data = {}) => client
	.post('/accounts/recovery/reset-password', data)
	.then(response => {
		return response?.data;
	});
;

export default accessAccount;
