import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Heading from 'components/Heading';
import useJSONState from 'hooks/useJSONState';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import useUpdateBrandMutation from 'services/api/hooks/useUpdateBrandMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import getErrorMessage from 'utils/getErrorMessage';
import { useRef, useEffect, useMemo, useCallback } from 'react';

const DashboardSettingsBrandForm = ({
	className
}) => {
	const assigned = useRef();

	const notifications = useNotificationsStore();

	const [form, formActions] = useJSONState({
		name: '',
		slug: ''
	});

	const { isUpdating, mutate } = useUpdateBrandMutation({
		onSuccess: (data) => {
			notifications.success('Brand successfully updated');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update brand information'));
		}
	});

	const { data, isSuccess, isLoading } = useSessionQuery();

	const isDisabled = useMemo(() => {
		return (
			isLoading ||
			isUpdating ||
			!form.name,
			!form.slug
		);
	}, [
		isLoading,
		isUpdating,
		form,
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	useEffect(() => {
		if (assigned.current) {
			return;
		}

		if (!isSuccess) {
			return;
		}

		const agency = data?.agencies?.find(agency => {
			return agency?.uuid === data?.selectedAgency;
		});

		formActions.extend({
			name: agency?.brands?.[0]?.name ?? '',
			slug: agency?.brands?.[0]?.slug ?? ''
		});

		assigned.current = true;
	}, [
		data,
		assigned,
		isSuccess,
		formActions
	]);

	return (
		<div className={ className }>
			<form onSubmit={ handleSubmit } disabled={ isDisabled } action="/a-fake-action" method="post" className="space-y-8 divide-y divide-theme-divider">
				<div className="space-y-8 divide-y divide-theme-divider sm:space-y-5">
					<div className="space-y-6 sm:space-y-5">
						<div>
							<Heading component="h3" variant="section">General</Heading>
							<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
								This information will be displayed publicly so be careful what you share.
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="name" className="sm:mt-px sm:pt-2">
									Brand Name
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input value={ form.name } onChange={ formActions.intercept } disabled={ isLoading } id="name" name="name" type="text" className="max-w-lg sm:max-w-xs" placeholder="e.g, Amazing Llama" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="slug" className="sm:mt-px sm:pt-2">
									Handle
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input value={ form.slug } onChange={ formActions.intercept } disabled={ isLoading } id="slug" name="slug" type="text" className="max-w-lg sm:max-w-xs" placeholder="e.g, @amazingllama" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="flex justify-end space-x-3">
						<Button disabled={ isDisabled } variant="primary">
							Save
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default DashboardSettingsBrandForm;
