import EthereumPrice from 'components/Ethereum/Price';
import Currency from 'components/Currency';
import Button from 'components/Button';
import Heading from 'components/Heading';
import useCollectionQuery from 'services/api/hooks/useCollectionQuery';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import DropClaimDialog from './Claim/Dialog';
import AuthDialog from 'features/Auth/Dialog';
import { CheckBadgeIcon, ArrowRightCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { useParams, Link } from 'react-router-dom';
import { useMemo, useCallback, useState } from 'react';

const DropMeta = ({
	className
}) => {
	const { data: session, isSuccess: isSuccessSession, isLoading: isLoadingSession } = useSessionQuery();

	const [showClaimDialog, setShowClaimDialog] = useState(false);

	const [showAuthDialog, setShowAuthDialog] = useState(false);

	const isAuthorized = useIsAuthorized();

	const meta = useParams();

	const { data = {}, isLoading } = useCollectionQuery({
		requireTemplateId: true,
		meta
	});

	const handleClaimClick = useCallback(() => {
		setShowClaimDialog(true);
	}, []);

	const handleClaimDialogClose = useCallback(() => {
		setShowClaimDialog(false);
	}, []);

	const handleAuthDialogOpen = useCallback(() => {
		setShowAuthDialog(true);
	}, []);

	const handleAuthDialogClose = useCallback(response => {
		setShowAuthDialog(false);

		if (response?.success) {
			handleClaimClick();
		}
	}, [handleClaimClick]);

	const action = useMemo(() => {
		if (data?.template?.claimMethod?.type !== 'claim') {
			return null;
		}

		const content = data?.template?.claimMethod?.type === 'claim' ? 'Claim' : 'Purchase';

		if (!isAuthorized || (isSuccessSession && session?.phoneVerified !== 1)) {
			return (
				<Button onClick={handleAuthDialogOpen} size="xl" className="w-full">
					{content}
				</Button>
			);
		}

		if (data?.template?.owned !== null) {
			return (
				<Button disabled size="xl" className="w-full items-center">
					<CheckCircleIcon className="w-5 h-5 mr-1" />Owned
				</Button>
			);
		}

		return (
			<Button disabled={isLoadingSession} onClick={handleClaimClick} size="xl" className="w-full">
				{content}
			</Button>
		);
	}, [
		data,
		session,
		isAuthorized,
		isLoadingSession,
		isSuccessSession,
		handleClaimClick,
		handleAuthDialogOpen
	]);

	if (isLoading) {
		return (
			<div className={className}>
				<div className="bg-theme-placeholder rounded-md mb-2 h-10 w-3/4" />
				<div className="bg-theme-placeholder rounded-md mb-4 h-8 w-1/4 mb-4" />
				<div className="bg-theme-placeholder rounded-md h-6 mb-1" />
				<div className="bg-theme-placeholder rounded-md h-6 w-1/2 mb-1" />
				<div className="bg-theme-placeholder rounded-md h-6 w-3/4 mb-8" />
				<div className="bg-theme-placeholder rounded-md h-16" />
			</div>
		);
	}

	return (
		<div className={className}>
			<h1 className="text-3xl tracking-tight font-bold">{data?.template?.title}</h1>
			<div className="mb-4">
				<Link to={`/c/${data?.brand?.slug}`} className="font-medium flex items-center">
					<CheckBadgeIcon className="w-4 h-4 mr-1 text-theme-primary" />
					{data?.brand?.slug}
				</Link>
				{data?.template?.externalUrl ? (
					<a target="_blank" rel="noreferrer" href={`https://${data?.template?.externalUrl}`} className="flex items-center font-medium text-theme-primary mb-4">
						<ArrowRightCircleIcon className="w-4 h-4 mr-1" /> https://{data.template.externalUrl}
					</a>
				) : null}
			</div>
			{data?.template?.claimMethod?.type === 'purchase' ? (
				<div className="mb-4">
					<Currency component="p" className="text-4xl tracking-tight font-bold" value="100" />
					<EthereumPrice className="block text-lg text-theme-primary" value="100" />
				</div>
			) : null}
			<Heading variant="section" className="mb-4">
				About
			</Heading>
			{data?.template?.description ? (
				<p className="mb-8 whitespace-pre-line">
					{data?.template?.description}
				</p>
			) : (
				<p className="mb-8 whitespace-pre-line text-theme-typography-dim italic">
					No description was provided for this token.
				</p>
			)}
			{action}
			<DropClaimDialog
				meta={meta}
				open={showClaimDialog}
				onClose={handleClaimDialogClose}
			/>
			<AuthDialog
				open={showAuthDialog}
				onClose={handleAuthDialogClose}
			/>
		</div>
	);
};

export default DropMeta;
