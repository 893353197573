import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import updateTemplate from '../endpoints/updateTemplate';

const useUpdateTemplateMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();

	return useMutation(data => updateTemplate(data, {
		agency,
		templateId: options?.templateId,
		collectionId: options?.collectionId
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.setQueryData(['templateManager', agency.id, agency.brandId, options.collectionId, options.templateId], data);

			queryClient.setQueryData(['collectionManager', agency.id, agency.brandId, options.collectionId], (state) => {
				return {
					...state,
					templates: state?.templates?.map(template => {
						if (template.uuid !== data.uuid) {
							return template;
						}

						return data;
					})
				};
			});
		}
	});
};

export default useUpdateTemplateMutation;
