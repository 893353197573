import Label from 'components/Label';
import clsx from 'clsx';

const Radio = ({
	description,
	className,
	title,
	id,
	...rest
}) => {
	return (
		<div className={ clsx('flex items-center', className) }>
			<input { ...rest } id={ id } type="radio" className="h-4 w-4 border-theme-input-primary-border text-theme-primary focus:ring-theme-input-primary-focus" />
			<Label htmlFor={ id } className="ml-3">
				{ title }
				<p className="text-sm text-theme-typography-dim font-normal">
					{ description }
				</p>
			</Label>
		</div>
	);
};

export default Radio;
