import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import updateBrand from '../endpoints/updateBrand';

const useUpdateBrandMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(data => updateBrand(data, {
		agency
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.invalidateQueries({
				queryKey: ['session']
			});
		}
	});
};

export default useUpdateBrandMutation;