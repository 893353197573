import clsx from 'clsx';
import { useCallback } from 'react';

const SelectNative = ({
	defaultText,
	className,
	onChange,
	values,
	...rest
}) => {
	const handleChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		onChange(event);
	}, [onChange]);

	return (
		<select { ...rest } onChange={ handleChange } className={ clsx('relative w-full cursor-default rounded-md border border-theme-input-primary-border bg-theme-input-primary py-2 pl-3 pr-10 text-left shadow-sm focus:border-theme-input-primary-focus focus:outline-none focus:ring-1 focus:ring-theme-input-primary-focus sm:text-sm', className) }>
			<option value={ SelectNative.defaultProps.value }>{ defaultText }</option>
			{ values?.map(option => (
				<option key={ option?.value } value={ option?.value } disabled={ option?.disabled }>{ option?.name }</option>
			)) }
		</select>
	);
}

SelectNative.defaultProps = {
	value: '',
	defaultText: 'Select one'
};

export default SelectNative;
