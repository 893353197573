import { useMemo, useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import clsx from 'clsx';
import useJSONState from 'hooks/useJSONState';
import useAccountInviteQuery from 'services/api/hooks/useAccountInviteQuery';
import useRegisterAccountInviteMutation from 'services/api/hooks/useRegisterAccountInviteMutation';
import useFollowBrandMutation from 'services/api/hooks/useFollowBrandMutation';

import getErrorMessage from 'utils/getErrorMessage';
import useNotificationsStore from 'state/hooks/useNotificationsStore';

const AuthInviteForm = ({
	className
}) => {
	const [params] = useSearchParams();
	const code = params.get('c');
	const navigate = useNavigate();
	const notifications = useNotificationsStore();
	const [err, setErr] = useState(null);
	const [opts, setOpts] = useState({
		brandId: null,
		hasPassword: false,
		redirectTo: '/',
	})
	const [form, formActions] = useJSONState({
		code,
		email: '',
		password: '',
		passwordConfirmation: ''
	});

	const { mutate: followBrand } = useFollowBrandMutation({
		meta: params.meta,
	});

	const { mutate: mutateInvite, isLoading: isLoadingInvite } = useRegisterAccountInviteMutation({
		onSuccess: () => {
			if (opts.brandId) {
				followBrand({
					brandId: opts.brandId
				})
			}
			navigate(opts.redirectTo);
			// notifications.success('Your account has been completed!');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to setup account.'));
		}
	});

	const { isInitialLoading: isInitialLoadingInvite } = useAccountInviteQuery({
		onSuccess: async (data) => {
			formActions.extend({
				email: data?.email
			});

			const { token } = data?.claim;
			const { address, network } = data?.collection?.details;
			const redirectTo = `/collection/tokens?claimed=true&network=${network.slug}&address=${address}&token=${token}`;

			setOpts({
				brandId: data?.collection?.brand?.contractUuid,
				redirectTo,
				hasPassword: data.userHasPassword
			});
		},
		onError: (data) => {
			if (data?.response?.status) {
				navigate('/authorize');
			}
			notifications.failure(getErrorMessage(data, ''));
			setErr('Failed to obtain invite information, please try refreshing.');
		},
		enabled: true,
		meta: {
			code
		}
	});

	useEffect(() => {
		if (opts.hasPassword) {
			mutateInvite({ code });
		}
	}, [code, opts.hasPassword, mutateInvite])

	const disabled = useMemo(() => {
		return (
			err ||
			isLoadingInvite ||
			isInitialLoadingInvite ||
			!form.email.trim() ||
			!form.password.trim() ||
			form.password !== form.passwordConfirmation
		);
	}, [
		err,
		form,
		isLoadingInvite,
		isInitialLoadingInvite
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();

		mutateInvite(form);
	}, [
		form,
		mutateInvite
	]);

	return (

		<form onSubmit={handleSubmit} action="/a-fake-action" className={clsx('space-y-8', 'w-full', className)}>
			<div className="mb-12">
				<Heading>Create your account</Heading>
			</div>
			<div className="space-y-6">
				<div>
					<Label htmlFor="email" className="mb-1">Email</Label>
					<Input disabled={!!code} id="email" name="email" type="email" placeholder={code ? '' : 'elmo@sesamestreet.com'} required value={form.email} onChange={formActions.intercept} />
				</div>
				<div>
					<Label htmlFor="password" className="mb-1">Password</Label>
					<Input id="password" name="password" type="password" required onChange={formActions.intercept} value={form.password} />
				</div>
				<div>
					<Label htmlFor="passwordConfirmation" className="mb-1">Confirm Password</Label>
					<Input id="passwordConfirmation" name="passwordConfirmation" type="password" required onChange={formActions.intercept} value={form.passwordConfirmation} />
				</div>
			</div>
			<div>
				<Button className="w-full" size="xl" disabled={disabled}>
					Continue
				</Button>

				{
					err && <div className='mt-4 text-center text-red-600'>Failed to obtain invite information, please try refreshing.</div>
				}
			</div>
		</form>
	);
};

export default AuthInviteForm;