import Heading from 'components/Heading';
import DashboardCollectionTokenFreeForm from 'features/Dashboard/Collection/Token/FreeForm';

const DashboardCollectionCreateFreeRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Create Free Token</Heading>
			<DashboardCollectionTokenFreeForm />
		</div>
	);
};

export default DashboardCollectionCreateFreeRoute;
