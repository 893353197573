import client from '../client';

const updateTemplate = (data = {}, options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId && options?.collectionId && options?.templateId)) {
		return Promise.reject(new Error('Unable to edit template without valid agency/brand/collection/template id'));
	}

	return client
		.put(`/agencies/${ agency.id }/brands/${ agency.brandId }/collections/${ options.collectionId }/templates/${ options.templateId }/details`, data)
		.then(response => response?.data)
	;
};

export default updateTemplate;
