import clsx from 'clsx';

const LogoPoweredByWordMark = ({
	color = 'black',
	className,
	...rest
}) => (
	<img className={clsx('fill-current', className)} alt='HexKey Logo' src={`/logos/powered_by_hexkey_logo_${color}.png`} />
);

export default LogoPoweredByWordMark;
