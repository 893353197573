import Heading from 'components/Heading';
import DashboardSettingsBrandForm from 'features/Dashboard/Settings/BrandForm';

const DashboardSettingsBrandRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Brand</Heading>
			<DashboardSettingsBrandForm  />
		</div>
	);
};

export default DashboardSettingsBrandRoute;
