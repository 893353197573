import Heading from 'components/Heading';
import ConsumerCommunitiesListItem from './ListItem';
import useFollowedBrandPagesQuery from 'services/api/hooks/useFollowedBrandPagesQuery';

const ConsumerCommunitiesList = ({
	heading,
	className,
	disableHeading
}) => {
	const { data = {}, isLoading } = useFollowedBrandPagesQuery();
	
	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			{ isLoading ? (
				<div className="grid gap-4 md:grid-cols-2">
					<ConsumerCommunitiesListItem loading />
					<ConsumerCommunitiesListItem loading />
					<ConsumerCommunitiesListItem loading />
				</div>
			) : (
				<div className="grid gap-4 md:grid-cols-2">
					{ data?.pages?.length > 0 ? data.pages.map(page => (
						<ConsumerCommunitiesListItem
							id={ page?.uuid }
							key={ page?.uuid }
							name={ page?.name }
							slug={ page?.slug }
							banner={ page?.community?.details?.banner || `/images/banner.jpg` }
							avatar={ page?.community?.details?.cover }
						/>
					)) : (
						<div className="col-span-3 text-theme-typography-dim italic">
							You're currently not following any brands.
						</div>
					) }
				</div>
			) }
		</div>
	);
};

ConsumerCommunitiesList.defaultProps = {
	heading: 'Communities'
};

export default ConsumerCommunitiesList;
