import client from '../client';

const getBrandPage = (options = {}) => {
	const brandSlug = options?.meta?.brandSlug;

	if (!brandSlug) {
		return Promise.reject(new Error('Unable to fetch brand page without valid brand slug'));
	}

	return client
		.get(`/pages/${ brandSlug }`)
		.then(response => response?.data)
	;
};

export default getBrandPage;
