import Heading from 'components/Heading';
import DashboardCollectionTokenEditForm from 'features/Dashboard/Collection/Token/EditForm';

const DashboardCollectionEditIndexRoute = () => {
	return (
		<div className="p-4">
			<Heading className="mb-6">Edit Token</Heading>
			<DashboardCollectionTokenEditForm />
		</div>
	);
};

export default DashboardCollectionEditIndexRoute;
