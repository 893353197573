import client from '../client';

const getTemplateForBrandManager = (options = {}) => {
	const collectionId = options?.meta?.collectionId;
	const templateId = options?.meta?.templateId;
	const agency = options?.meta?.agency ?? {};

	if (!(agency.id && agency.brandId && collectionId && templateId)) {
		return Promise.reject(new Error('Unable to fetch collection without valid agency/brand/collection/template id'));
	}

	return client
		.get(`/agencies/${ agency.id }/brands/${ agency.brandId }/collections/${ collectionId }/templates/${ templateId }`)
		.then(response => response?.data)
	;
};

export default getTemplateForBrandManager;
