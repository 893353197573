import { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from "react-router-dom";
import Heading from 'components/Heading';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useVerifyAccountMutation from 'services/api/hooks/useVerifyAccountMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import BackToSignIn from './components/BackToSignIn';
import EmailIcon from './components/EmailIcon';
import ResendEmail from './components/ResendEmail';
import Loader from 'components/Loader';

const Verifying = () => {
	const [loading, setLoading] = useState(false);
	const notifications = useNotificationsStore();
	const isAuthorized = useIsAuthorized();

	const [params] = useSearchParams();
	const token = params.get('token');

	const { mutate } = useVerifyAccountMutation({
		onError: () => {
			setLoading(false);
			notifications.failure('Unable to verify your account at this time. Try requesting a new verification code.');
		}
	});

	useEffect(() => {
		if (isAuthorized) {
			return;
		}

		if (!token) {
			return;
		}

		mutate({ code: token });
		setLoading(true);
	}, [
		isAuthorized,
		mutate,
		token
	]);

	return isAuthorized ? (
		<Navigate to="/studio/auth/messages/verified" replace />
	) : (
		<div>
			<EmailIcon />
			<Heading className="text-center">Verifying your account</Heading>

			<hr className="my-6 border-t border-gray-200" />

			<p className="font-light mt-4 mb-4">
				Thank you for registering with us! We're currently verifying your account. Please wait a moment while we process your information
			</p>

			<ResendEmail />

			{loading && (
				<>
					<div className='flex justify-center mt-8'>
						<Loader />
					</div>
					<p className="font-light text-center mt-2 mb-2">
						This may take a minute, we are deploying your brand's smart contracts.
					</p>
				</>
			)}

			<BackToSignIn />
		</div >
	);
}

export default Verifying;
