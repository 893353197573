import { useMemo, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useJSONState from 'hooks/useJSONState';
import useSetupAccountMutation from 'services/api/hooks/useSetupAccountMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useSetDocumentTitle from 'hooks/useSetDocumentTitle';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import getErrorMessage from 'utils/getErrorMessage';

const AuthSignUpRoute = () => {
	useSetDocumentTitle('Getting Started with Hexkey');

	const notifications = useNotificationsStore();

	const isAuthorized = useIsAuthorized();

	const { mutate, isSuccess, isLoading } = useSetupAccountMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Please verify the information you entered and try again. If the issue persists, please contact our support team for assistance.'));
		}
	});

	const [form, formActions] = useJSONState({
		email: '',
	});

	const disabled = useMemo(() => {
		return isLoading || !form.email.trim();
	}, [
		form,
		isLoading
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	if (isSuccess) {
		return <Navigate to={`/studio/auth/messages/verify-your-email?email=${window.btoa(form.email)}`} />
	}

	if (isAuthorized) {
		return (
			<Navigate to='/studio' />
		);
	}

	return (
		<>
			<p className="mt-2">Create, Distribute, and Manage NFTs, Communities, and Unlocks</p>
			<form className="mt-4 w-full" onSubmit={handleSubmit}>
				<div className="space-y-2">
					<div className='py-2'>
						<Label htmlFor="email" className="mb-1">Email</Label>
						<Input id="email" name="email" type="email" placeholder="elmo@sesamestreet.com" required value={form.email} onChange={formActions.intercept} />
					</div>
					<p className="mt-4 text-xs py-2">
						By signing up, you are creating a Hexkey account, and you agree to Hexkey <a href='.'>Terms of Use</a> and <a href='.'>Privacy Policy</a>.
					</p>
				</div>
				<div className="py-2">
					<Button className="w-full" size="xl" disabled={disabled}>
						Continue
					</Button>
				</div>
			</form>
			<div className="mt-4 text-sm">
				<div className="text-right text-sm">
					Already have an account? <Link to="/studio/auth/signin" className="text-theme-primary font-medium">Sign in</Link>
				</div>
			</div>
		</>
	);
};

export default AuthSignUpRoute;


