import { Outlet } from 'react-router-dom';
import LogoWordMark from 'components/LogoWordMark';

const StudioAuthMessagesTemplate = () => (
	<div className="h-screen flex flex-col xl:flex-row">
		<div className="w-full h-full xl pb-40 flex flex-col justify-center items-center">
			<div className='w-40'>
				<LogoWordMark color='white' />
			</div>
			<div className="flex flex-col bg-theme-paper p-12 max-w-xl w-full mt-6 mx-auto">
				<Outlet />
			</div>
		</div>
	</div>
);

export default StudioAuthMessagesTemplate;
