import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import archiveTemplate from '../endpoints/archiveTemplate';

const useArchiveTemplateMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(() => archiveTemplate({
		agency,
		templateId: options?.templateId,
		collectionId: options?.collectionId
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.invalidateQueries({
				queryKey: ['templateManager', agency.id, agency.brandId, options.collectionId, options.templateId],
				refetchType: 'none'
			});

			queryClient.setQueryData(['collectionManager', agency.id, agency.brandId, options.collectionId], (state) => {
				return {
					...state,
					templates: state?.templates?.filter(template => {
						return template.uuid !== options.templateId
					})
				};
			});
		}
	});
};

export default useArchiveTemplateMutation;
