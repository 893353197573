import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

const useRouteParamsAsMeta = (defaults = {}) => {
	const params = useParams();

	return useMemo(() => ({
		meta: {
			...defaults,
			...params
		}
	}), [
		defaults,
		params
	]);
};

export default useRouteParamsAsMeta;
