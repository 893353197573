import Heading from 'components/Heading';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useJSONState from 'hooks/useJSONState';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import useVerifyPhoneMutation from 'services/api/hooks/useVerifyPhoneMutation';
import useUpdatePhoneMutation from 'services/api/hooks/useUpdatePhoneMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useAuthStore from 'state/hooks/useAuthStore';
import getErrorMessage from 'utils/getErrorMessage';
import { Navigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import clsx from 'clsx';

const AuthSetupForm = ({
	embedded,
	className
}) => {
	const isAuthorized = useIsAuthorized();

	const notifications = useNotificationsStore();

	const { deauthorize } = useAuthStore();

	const { mutate, isLoading, isSuccess } = useUpdatePhoneMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to setup account.'));
		}
	});

	const { mutate: mutateVerify, isSuccess: isSuccessVerify } = useVerifyPhoneMutation({
		onSuccess: () => {
			notifications.success('Your account has been verified!');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to verify phone number.'));
		}
	});

	const { data: session, isLoading: isLoadingSession, isSuccess: isSuccessSession } = useSessionQuery();
	
	const [form, formActions] = useJSONState({
		phone: '',
		code: ''
	});

	const disabled = useMemo(() => {
		return (
			isLoading ||
			isLoadingSession ||
			!form.phone.trim() ||
			(isSuccess && !form.code.trim())
		);
	}, [
		isLoadingSession,
		isLoading,
		isSuccess,
		form
	]);

	const handleSubmit = useCallback(event => {
		const action = isSuccess ? mutateVerify : mutate;
		
		event.preventDefault();
		
		action(form);
	}, [
		form,
		mutate,
		isSuccess,
		mutateVerify
	]);

	if (!embedded && !isAuthorized) {
		return (
			<Navigate to="/authorize" replace />
		);
	}

	if (!embedded && (isSuccessVerify || (isSuccessSession && session?.phoneVerified === 1))) {
		return (
			<Navigate to="/" replace />
		);
	}

	return  (
		<form onSubmit={ handleSubmit } action="/a-fake-action" className={ clsx('space-y-8', className) }>
			<div className="space-y-4">
				<Heading>{ isSuccess ? 'Verify' : 'Setup' } your account</Heading>
				{ isSuccess ? (
					<p>A verification code has been sent to your mobile device.</p>
				) : (
					<p>A valid phone number is required to fully utilize your account.</p>
				) }
			</div>
			<div className="space-y-2">
				{ isSuccess ? (
					<div>
						<Label htmlFor="code" className="mb-1">Verification Code</Label>
						<Input id="code" name="code" type="text" required onChange={ formActions.intercept } value={ form.code } />
					</div>
				) : (
					<div>
						<Label htmlFor="phone" className="mb-1">Phone</Label>
						<Input id="phone" name="phone" type="phone" required onChange={ formActions.intercept } value={ form.phone } />
					</div>
				) }
				{ isSuccessSession ? (
					<div className="text-right text-sm">
						Not <span className="font-medium">{ session.email }</span>? <button type="button" onClick={ deauthorize } className="text-theme-primary font-medium">Sign out</button>
					</div>
				) : null }
			</div>
			<div className="space-y-4">
				<Button size="xl" className="w-full" disabled={ disabled }>
					Continue
				</Button>
			</div>
		</form>
	);
};

export default AuthSetupForm;