import { PlusIcon, FolderPlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Button from './Button';
import clsx from 'clsx';

const EmptyState = ({
	icon: Icon,
	createPath,
	className,
	type,
}) => {
	return (
		<div className={ clsx('flex flex-col items-center rounded-lg border-2 border-dashed border-theme-diver p-12', className) }>
			<Icon className="w-12 h-12 text-theme-typography-dim mb-2" />
			<h3 className="text-sm font-medium mb-1">No { type }s</h3>
			<p className="text-sm text-theme-typography-dim mb-6">Get started by creating a new { type }.</p>
			<Button component={ Link } to={ createPath } className="inline-flex items-center capitalize" size="md">
				<PlusIcon className="w-4 h-4 mr-1" />
				New { type }
			</Button>
		</div>
	);
};

EmptyState.defaultProps = {
	type: 'project',
	icon: FolderPlusIcon,
	createPath: 'create'
};

export default EmptyState;
