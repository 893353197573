import client from '../client';

const setupBrand = (data = {}, options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId)) {
		return Promise.reject(new Error('Unable to edit brand without valid agency/brand id'));
	}

	return client
		.put(`/agencies/${agency.id}/brands/${agency.brandId}/setup`, data)
		.then(response => response?.data)
		;
};

export default setupBrand;
