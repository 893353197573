import AttributesForm from './Form';
import { useCallback } from 'react';

const AttributesInput = ({
	className,
	onChange,
	values,
	name
}) => {
	const handleAttributeAdd = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = [...values, AttributesForm.getInitialState()];

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});	
	}, [
		onChange,
		values,
		name
	]);

	const handleAttributeChange = useCallback(event => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = values.map(value => {
			if (value === event.previous) {
				return event.next
			}

			return value;
		});

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});
	}, [
		onChange,
		values,
		name
	]);

	const handleAttributeRemove = useCallback(attribute => {
		if (typeof onChange !== 'function') {
			return;
		}

		const value = values.filter(current => current !== attribute);

		onChange({
			target: {
				name,
				value,
				tagName: 'input'
			}
		});	
	}, [
		onChange,
		values,
		name
	]);

	return (
		<div className={ className }>
			<div className="space-y-2 mb-4">
				{ values?.map((value, index) => (
					<AttributesForm
						key={ index }
						value={ value }
						onChange={ handleAttributeChange }
						onAddButtonClick={ handleAttributeAdd }
						onRemoveButtonClick={ handleAttributeRemove }
						disableAddButton={ index !== values?.length - 1 }
						disableRemoveButton={ index === values?.length - 1 }
					/>
				)) }
			</div>
		</div>
	);
};

AttributesInput.getInitialState = () => [
	AttributesForm.getInitialState()
];

AttributesInput.defaultProps = {
	values: AttributesInput.getInitialState()
};

export default AttributesInput;
