import { useQuery } from '@tanstack/react-query'
import getUserTokens from '../endpoints/getUserTokens';

const useUserTokensQuery = (options) => {
	return useQuery(['userTokens'], getUserTokens, {
		...options
	});
};

export default useUserTokensQuery;
