import BrandBanner from 'features/Brand/Banner';
import useAgency from 'features/Auth/hooks/useAgency';
import { Outlet } from 'react-router-dom';

const StudioProfileTemplate = () => {
	const agency = useAgency();

	return (
		<div className="xl:p-4">
			<BrandBanner editable brandSlug={ agency.brandSlug } />
			<Outlet />
		</div>
	);
};

export default StudioProfileTemplate;
