import { useMutation, useQueryClient } from '@tanstack/react-query';
import verifyPhone from '../endpoints/verifyPhone';

const useVerifyPhoneMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(verifyPhone, {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}
			
			console.info('@TODO useVerifyPhoneMutation() If this response returns the phone number, I can assign it to session instead of invalidating it.');

			/**
			 * Invalidate session upon verification.
			 */
			queryClient.invalidateQueries(['session']);
		}
	});
};

export default useVerifyPhoneMutation;
