import client from '../client';
import useAuthStore from 'state/hooks/useAuthStore';

const getAccountInvite = (options = {}) => {
	const code = options?.meta?.code;

	if (!code) {
		return Promise.reject(new Error('Unable to fetch account invite without valid code'));
	}

	return client
		.get('/accounts/grant-password/invite', {
			params: {
				c: code
			}
		})
		.then(response => {
			if (response?.data?.token) {
				useAuthStore.getState().authorize(response.data.token);
			}

			return response?.data;
		})
		;
};

export default getAccountInvite;
