import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import clsx from 'clsx';

const PopoutMenu = ({
	itemsClassName,
	className,
	icon,
	items,
}) => (
	<Menu as="div" className={ clsx('inline-flex relative', className) }>
		<Menu.Button className="focus:outline-none">
			{ icon }
		</Menu.Button>
		<Transition
		as={ Fragment }
		enter="transition ease-out duration-200"
		enterFrom="transform opacity-0 scale-95"
		enterTo="transform opacity-100 scale-100"
		leave="transition ease-in duration-75"
		leaveFrom="transform opacity-100 scale-100"
		leaveTo="transform opacity-0 scale-95"
		>
			<Menu.Items className={ clsx('overflow-hidden absolute right-0 origin-top-right rounded-md ring-1 ring-theme-menu-focus ring-opacity-20 bg-theme-menu shadow-lg', itemsClassName) }>
				<div>
					{ items?.map(item => (
						<Menu.Item key={ item.key }>
							{ item }
						</Menu.Item>
					)) }
				</div>
			</Menu.Items>
		</Transition>
	</Menu>
);

PopoutMenu.defaultProps = {
	itemsClassName: 'w-32 top-8',
	icon: <EllipsisVerticalIcon className="w-6 h-6 text-theme-typography-dim" />
}

export default PopoutMenu;
