import Button from 'components/Button';
import useDisconnectMetaMaskMutation from 'services/api/hooks/useDisconnectMetaMaskMutation';
import getErrorMessage from 'utils/getErrorMessage';
import { Dialog, Transition } from '@headlessui/react';
import { useCallback, useEffect, Fragment } from 'react';

const ConsumerWalletsDisconnectDialog = ({
	open,
	onClose,
	address,
	networkId,
}) => {
	const { mutate: disconnectMetaMask, isLoading: isLoadingMetaMask, isError: isErrorMetaMask, isSuccess: isSuccessMetaMask, error: metaMaskError, reset: resetMetaMask } = useDisconnectMetaMaskMutation({
		address,
		networkId
	});

	const handleClose = useCallback(() => {
		if (isLoadingMetaMask) {
			return;
		}

		if (isErrorMetaMask) {
			resetMetaMask();
		}

		if (typeof onClose === 'function') {
			onClose();
		}
	}, [
		isLoadingMetaMask,
		isErrorMetaMask,
		resetMetaMask,
		onClose
	]);

	useEffect(() => {
		if (isErrorMetaMask && !window?.ethereum) {
			window.open('https://metamask.io/', '_blank');
		}
	}, [isErrorMetaMask]);

	return (
		<Transition.Root show={ open } as={ Fragment }>
			<Dialog as="div" className="relative z-10" onClose={ handleClose }>
				<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-theme-backdrop bg-opacity-75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-theme-background p-8 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
								<h3 className="font-bold text-2xl tracking-tight mb-4">
									Disconnect Wallet
								</h3>
								{ !open ? (
									<div>
										<div className="bg-theme-placeholder h-6 rounded-md w-1/2 mb-1" />
										<div className="bg-theme-placeholder h-6 rounded-md w-full mb-1" />
										<div className="bg-theme-placeholder h-6 rounded-md w-3/4 mb-4" />
										<div className="bg-theme-placeholder h-6 rounded-md w-3/4 mb-1" />
										<div className="bg-theme-placeholder h-6 rounded-md w-1/4" />
									</div>
								) : isSuccessMetaMask ? (
									<div>
										<div className="flex items-start">
											<p className="text-sm text-theme-typography-dim mb-4">
												Success! You have successfully disconnected your wallet.
											</p>
										</div>
										<Button size="xl" className="w-full" variant="primary" onClick={ handleClose }>Close</Button>
									</div>
								) : isErrorMetaMask ? (
									<div>
										<p className="text-sm text-theme-typography-dim mb-4">
											A problem occurred during the MetaMask disconnect process.
										</p>
										<div className="bg-theme-placeholder rounded-md text-sm p-4 mb-4">
											{ getErrorMessage(metaMaskError) }
										</div>
										<Button size="xl" className="w-full mb-4" variant="primary" onClick={ resetMetaMask }>Start over?</Button>
										<Button size="xl" className="w-full" variant="neutral" onClick={ handleClose }>Nevermind</Button>
									</div>
								) : (
									<div>
										<p className="text-sm text-theme-typography-dim mb-4">
											Disconnecting a wallet will remove any and all benefits you've obtained from the app.
											<br/><br/>
											If you still want to continue, choose a wallet to disconnect from below.
										</p>
										<Button disabled={ isLoadingMetaMask } size="xl" className="w-full mb-4" variant="primary" onClick={ disconnectMetaMask }>Disconnect with MetaMask</Button>
										<Button disabled={ isLoadingMetaMask } size="xl" className="w-full" variant="neutral" onClick={ handleClose }>Nevermind</Button>
									</div>
								) }
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ConsumerWalletsDisconnectDialog;
