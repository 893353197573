import Avatar from 'components/Avatar';
import { Link } from 'react-router-dom';

const ConsumerCommunitiesListItem = ({
	id,
	name,
	slug,
	banner,
	avatar,
	loading,
	className
}) => {
	if (loading) {
		return (
			<div>
				<div className="aspect-[6/1] bg-theme-placeholder rounded-lg overflow-hidden mb-2" />
				<div className="bg-theme-placeholder rounded-lg w-3/4 h-6 mb-1" />
				<div className="bg-theme-placeholder rounded-lg w-1/4 h-6" />
			</div>
		);
	}

	return (
		<Link className={ className } to={ `/c/${ slug }` }>
			<div className="aspect-[6/1] bg-theme-placeholder rounded-t-lg overflow-hidden">
				{ banner ? <img src={ banner } alt={ name } className="object-cover w-full h-full" /> : null }
			</div>
			<div className="bg-theme-background-dim p-4 rounded-b-lg shadow-sm border-b flex items-center">
				<Avatar src={ avatar } className="self-start" size="xl" />
				<div className="ml-4">
					<h3 className="tracking-tight font-bold text-base">
						{ name }
					</h3>
					<p className="text-sm text-theme-typography-dim">
						@{ slug }
					</p>
				</div>
			</div>
		</Link>
	);
}

export default ConsumerCommunitiesListItem;
