const Skeleton = ({
	className
}) => {
	return (
		<div className={ className }>
			<div className="bg-theme-placeholder h-16 rounded-lg mb-4" />
			<div className="bg-theme-placeholder h-64 rounded-lg mb-4" />
			<div className="flex items-center mb-4 lg:max-w-2xl">
				<div className="bg-theme-placeholder w-24 aspect-[1/1] rounded-full" />
				<div className="flex-1 ml-4">
					<div className="bg-theme-placeholder h-6 rounded-lg w-3/4 mb-2" />
					<div className="bg-theme-placeholder h-6 rounded-lg w-1/4 mb-2" />
					<div className="bg-theme-placeholder h-6 rounded-lg w-1/2" />
				</div>					
			</div>
			<div className="flex items-center lg:max-w-2xl mb-4">
				<div className="bg-theme-placeholder w-24 aspect-[1/1] rounded-full" />
				<div className="flex-1 ml-4">
					<div className="bg-theme-placeholder h-6 rounded-lg w-3/4 mb-2" />
					<div className="bg-theme-placeholder h-6 rounded-lg w-1/4 mb-2" />
					<div className="bg-theme-placeholder h-6 rounded-lg w-1/2" />
				</div>					
			</div>
			<div className="bg-theme-placeholder h-16 rounded-lg mb-4" />
		</div>
	)
};

export default Skeleton;
