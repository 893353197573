import client from '../client';

const getToken = (options = {}) => {
	const network = options?.meta?.network;
	const address = options?.meta?.address;
	const token = options?.meta?.token;
	
	if (!(network && address && token)) {
		return Promise.reject(new Error('Unable to fetch token without valid network/address/token id'));
	}

	return client
		.get(`/collections/${ network }/${ address }/${ token }`)
		.then(response => response?.data)
	;
};

export default getToken;
