import Heading from 'components/Heading';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import clsx from 'clsx';
import useAccessAccountMutation from 'services/api/hooks/useAccessAccountMutation';
import useJSONState from 'hooks/useJSONState';
import useIsAuthorized from 'hooks/useIsAuthorized';
import getErrorMessage from 'utils/getErrorMessage';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import { Link, Navigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';

const AuthSignInForm = ({
	embedded,
	className,
	onAccountSignUpRequest
}) => {
	const isAuthorized = useIsAuthorized();
	const notifications = useNotificationsStore();

	const { mutate, isLoading } = useAccessAccountMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to authenticate.'));
		}
	});
	
	const [form, formActions] = useJSONState({
		identity: '',
		password: ''
	});

	const disabled = useMemo(() => {
		return isLoading || !form.identity.trim() || !form.password.trim();
	}, [
		form,
		isLoading
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	return !embedded && isAuthorized ? (
		<Navigate to="/" replace />
	) : (
		<form onSubmit={ handleSubmit } action="/a-fake-action"  className={ clsx('space-y-8', className) }>
			<div className="space-y-4">
				<Heading>Sign in to your account</Heading>
			</div>
			<div className="space-y-2">
				<div>
					<Label htmlFor="identity" className="mb-1">Email Address</Label>
					<Input id="identity" name="identity" type="email" required value={ form.identity } onChange={ formActions.intercept } />
				</div>
				<div>
					<Label htmlFor="password" className="mb-1">Password</Label>
					<Input id="password" name="password" type="password" minLength="6" required value={ form.password } onChange={ formActions.intercept } />
				</div>
				<div className="text-right text-sm">
					Don't have an account? { embedded ? (
						<button type="button" onClick={ onAccountSignUpRequest } className="text-theme-primary font-medium">
							Create account
						</button>
					) : (
						<Link to="/authorize/signup" className="text-theme-primary font-medium">
							Create account
						</Link>
					) }
				</div>
			</div>
			<div className="space-y-4">
				<Button size="xl" className="w-full" disabled={ disabled }>
					Continue
				</Button>
			</div>
		</form>
	);
};

export default AuthSignInForm;
