import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Heading from 'components/Heading';
import DashboardCollectionsList from 'features/Dashboard/Collections/List';

const DashboardCollectionsIndexRoute = () => {
	return (
		<div className="p-4">
			<div className="flex items-center mb-6">
				<Heading className="flex-1">Collections</Heading>
				<Button component={ Link } to="create" className="inline-flex items-center" size="sm">
					Create Collection
				</Button>
			</div>
			<DashboardCollectionsList />
		</div>
	);
};

export default DashboardCollectionsIndexRoute;
