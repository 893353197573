import QRCode from 'components/QRCode';
import Button from 'components/Button';
import copy from 'utils/copy';
import { useState, useEffect, useCallback, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ClipboardIcon } from '@heroicons/react/24/outline';

const ShareDialog = ({
	open,
	link,
	title,
	banner,
	onClose,
	username,
	claimable,
}) => {
	const [copyStatus, setCopyStatus] = useState(null);

	const handleCopyClick = useCallback(() => {
		copy(link).then(() => {
			setCopyStatus(1);
		}).catch(err => {
			setCopyStatus(0);
		});
	}, [link]);

	useEffect(() => {
		if (copyStatus === null) {
			return;
		}

		const id = setTimeout(() => setCopyStatus(null), 1000);

		return () => {
			clearTimeout(id);
		};
	}, [copyStatus]);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={onClose}>
				<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-theme-backdrop bg-opacity-75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-theme-background px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
								{banner ? (
									<div className="bg-theme-placeholder rounded-md aspect-[21/9] mb-6 overflow-hidden">
										{open ? (
											<img src={banner} alt={title} className="w-full h-full object-cover" />
										) : null}
									</div>
								) : null}
								<div className="flex flex-row mb-6">
									{open ? (
										<QRCode className="text-primary w-48 mr-6" padding="0" content={link} />
									) : (
										<div className="w-48 h-48 bg-theme-placeholder rounded-md mr-6" />
									)}
									<div className="flex-1">
										{open ? (
											<Dialog.Title as="h3" className="text-3xl font-bold tracking-tight">
												{title}
											</Dialog.Title>
										) : (
											<div className="h-5 w-full rounded-md bg-theme-placeholder mb-1" />
										)}
										{username && open ? (
											<span className="font-sm text-theme-primary font-medium">{username}</span>
										) : !open ? (
											<div className="h-5 w-1/2 rounded-md bg-theme-placeholder mb-1" />
										) : null}
									</div>
								</div>
								<div className="mb-6 flex border bg-theme-placeholder rounded-md">
									<button onClick={handleCopyClick} className="p-4 border-r">
										{open ? (
											<ClipboardIcon className="w-6 h-6" />
										) : (
											<div className="w-6 h-6" />
										)}
									</button>
									<div className="p-4 overflow-x-auto whitespace-nowrap scrollbar-none font-medium">
										{copyStatus === 0 ? 'Failed to copy link' : copyStatus === 1 ? 'Link copied!' : link}
									</div>
								</div>
								<Button onClick={onClose} size="md" variant="primary" className="w-full py-3">
									Dismiss
								</Button>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ShareDialog;
