import BrandUnlock from 'features/Brand/Unlock';

const BrandUnlockRoute = () => {
	return (
		<div className="p-4">
			<BrandUnlock />
		</div>
	);
};

export default BrandUnlockRoute;
