import { useQuery } from '@tanstack/react-query'
import useAgency from 'features/Auth/hooks/useAgency';
import getCollectionsForBrandManager from '../endpoints/getCollectionsForBrandManager';

const useCollectionsForBrandManagerQuery = (options) => {
	const { id, brandId } = useAgency();

	return useQuery(['collectionsManager', id, brandId], getCollectionsForBrandManager, {
		...options,
		enabled: !!(id && brandId),
		meta: {
			agency: {
				id,
				brandId
			},
			...options?.meta,
		},
	});
};

export default useCollectionsForBrandManagerQuery;
