import Button from 'components/Button';
import Loader from 'components/Loader';
import useCollectionQuery from 'services/api/hooks/useCollectionQuery';
import useWeb3AccountsQuery from 'services/api/hooks/useWeb3AccountsQuery';
import useClaimTokenMutation from 'services/api/hooks/useClaimTokenMutation';
import useConnectMetaMaskMutation from 'services/api/hooks/useConnectMetaMaskMutation';
import getErrorMessage from 'utils/getErrorMessage';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useMemo, useState, useCallback, useEffect, Fragment } from 'react';

const DropClaimDialog = ({
	meta,
	open,
	onClose
}) => {
	const [skipped, setSkipped] = useState(false);

	const { data: web3Accounts, isLoading: isLoadingWeb3Accounts } = useWeb3AccountsQuery({
		enabled: open
	});

	const { data: collection, isLoading: isLoadingCollection } = useCollectionQuery({
		requireTemplateId: true,
		enabled: open,
		meta
	});

	const { mutate: connectMetaMask, isLoading: isLoadingMetaMask, isError: isErrorMetaMask, error: metaMaskError, reset: resetMetaMask } = useConnectMetaMaskMutation({
		networkId: collection?.details?.network?.id
	});

	const { mutate: claimToken, isIdle: isIdleClaimToken, isLoading: isLoadingClaimToken, isSuccess: isSuccessClaimToken, isError: isErrorClaimToken, error: claimTokenError, reset: resetClaimToken } = useClaimTokenMutation({
		meta
	});

	const handleSkip = useCallback(() => {
		setSkipped(true);
	}, []);

	const handleClose = useCallback(() => {
		if (isLoadingMetaMask) {
			return;
		}

		if (isLoadingClaimToken) {
			return;
		}

		if (skipped) {
			setSkipped(false);
		}

		if (isErrorMetaMask) {
			resetMetaMask();
		}

		if (isErrorClaimToken) {
			resetClaimToken();
		}

		if (typeof onClose === 'function') {
			onClose();
		}
	}, [
		isLoadingClaimToken,
		isLoadingMetaMask,
		isErrorClaimToken,
		isErrorMetaMask,
		resetClaimToken,
		resetMetaMask,
		skipped,
		onClose
	]);

	const claimAddress = useMemo(() => skipped ? 'Custodial' : web3Accounts?.address, [
		skipped,
		web3Accounts
	]);

	useEffect(() => {
		if (!open) {
			return;
		}

		if (!claimAddress) {
			return;
		}

		if (!isIdleClaimToken) {
			return;
		}

		claimToken();
	}, [
		open,
		claimToken,
		claimAddress,
		isIdleClaimToken
	]);

	useEffect(() => {
		if (isErrorMetaMask && !window?.ethereum) {
			window.open('https://metamask.io/', '_blank');
		}
	}, [isErrorMetaMask]);

	return (
		<Transition.Root show={ open } as={ Fragment }>
			<Dialog as="div" className="relative z-10" onClose={ handleClose }>
				<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-theme-backdrop bg-opacity-75 transition-opacity" />
				</Transition.Child>
				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child as={ Fragment } enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-theme-background p-8 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
								<h4 className="font-semibold uppercase text-theme-typography-dim text-xs">
									Token Claim
								</h4>
								<h3 className="font-bold text-2xl tracking-tight mb-4">
									{ collection?.template?.title }
								</h3>
								{ !open || isLoadingCollection || isLoadingWeb3Accounts ? (
									<div>
										<div className="bg-theme-placeholder h-6 rounded-md w-1/2 mb-1" />
										<div className="bg-theme-placeholder h-6 rounded-md w-full mb-1" />
										<div className="bg-theme-placeholder h-6 rounded-md w-3/4 mb-4" />
										<div className="bg-theme-placeholder h-6 rounded-md w-3/4 mb-1" />
										<div className="bg-theme-placeholder h-6 rounded-md w-1/4" />
									</div>
								) : isSuccessClaimToken ? (
									<div>
										<div className="flex items-start">
											<p className="text-sm text-theme-typography-dim mb-4">
												Congratulations! You have successfully claimed <span className="font-medium">{ collection?.template?.title }</span>.
											</p>
										</div>
										<Button component={ Link } to="/collection/tokens" size="xl" className="w-full mb-4" variant="primary">View</Button>
										<Button size="xl" className="w-full" variant="neutral" onClick={ handleClose }>Close</Button>
									</div>
								) : isErrorClaimToken ? (
									<div>
										<p className="text-sm text-theme-typography-dim mb-4">
											A problem occurred during the claim process.
										</p>
										<div className="bg-theme-placeholder rounded-md text-sm p-4 mb-4">
											{ getErrorMessage(claimTokenError) }
										</div>
										<Button size="xl" className="w-full mb-4" variant="primary" onClick={ resetClaimToken }>Try again?</Button>
										<Button size="xl" className="w-full" variant="neutral" onClick={ handleClose }>Nevermind</Button>
									</div>
								) : isErrorMetaMask ? (
									<div>
										<p className="text-sm text-theme-typography-dim mb-4">
											A problem occurred during the MetaMask connect process.
										</p>
										<div className="bg-theme-placeholder rounded-md text-sm p-4 mb-4">
											{ getErrorMessage(metaMaskError) }
										</div>
										<Button size="xl" className="w-full mb-4" variant="primary" onClick={ resetMetaMask }>Start over?</Button>
										<Button size="xl" className="w-full" variant="neutral" onClick={ handleClose }>Nevermind</Button>
									</div>
								) : claimAddress ? (
									<div>
										<div className="flex items-center mb-4">
											<Loader className="w-6 mr-2" />
											<p className="text-sm text-theme-typography-dim">
												Processing your token claim, one moment...
											</p>
										</div>
										<div className="bg-theme-placeholder rounded-md text-sm p-4">
											<h4 className="font-semibold uppercase text-theme-typography-dim text-xs">
												Claiming to wallet
											</h4>
											<p className="text-sm text-theme-typography-dim">
												{ claimAddress }
											</p>
										</div>
									</div>
								) : (
									<div>
										<p className="text-sm text-theme-typography-dim mb-4">
											To claim this token, you'll need to either connect your web3 wallet or skip this step and let us handle it.
											<br/><br/>
											If you choose to skip, you can connect your web3 wallet at any point in the future.
										</p>
										<Button disabled={ isLoadingMetaMask } size="xl" className="w-full mb-4" variant="primary" onClick={ handleSkip }>Skip for now</Button>
										<Button disabled={ isLoadingMetaMask } size="xl" className="w-full" variant="neutral" onClick={ connectMetaMask }>Connect with MetaMask</Button>
									</div>
								) }
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default DropClaimDialog;
