import clsx from 'clsx';
import { UserCircleIcon } from '@heroicons/react/24/solid';

const Avatar = ({
	component: Component,
	className,
	outlined,
	size,
	src,
	...rest
}) => {
	return (
		<Component { ...rest } className={ clsx('inline-flex aspect-[1/1] rounded-full overflow-hidden bg-theme-placeholder', Avatar.sizes[size], {
			'p-1': outlined && src
		}, className) }>
			{ src ? (
				<img src={ src } alt="User Avatar" className="w-full h-full object-cover rounded-full" />
			) : (
				<UserCircleIcon className="w-full text-theme-typography-dim text-opacity-10" />
			) }
		</Component>
	);
};

Avatar.defaultProps = {
	component: 'div',
	size: 'auto'
};

Avatar.sizes = {
	xs: 'w-6',
	sm: 'w-8',
	md: 'w-10',
	lg: 'w-12',
	xl: 'w-14',
	auto: 'w-full'
};

export default Avatar;
