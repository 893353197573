import clsx from 'clsx';

const Label = ({
	className,
	children,
	htmlFor,
}) => {
	return (
		<label htmlFor={ htmlFor } className={ clsx('block text-sm font-medium', className) }>
			{ children }
		</label>
	);
};

export default Label;
