import client from '../client';

const unlinkWeb3Account = (data = {}) => client
	.delete('/accounts/link3', {
		data
	})
	.then(response => response?.data)
;

export default unlinkWeb3Account;
