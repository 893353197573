const getErrorMessage = (err, defaultMessage) => {
	if (err instanceof Error) {
		if (err?.isAxiosError) {
			if (err?.response?.data?.errors?.[0]?.text) {
				return err.response.data.errors[0].text;
			}
		}
	}

	return defaultMessage ?? err?.message ?? 'An unknown error has occurred.';
};

export default getErrorMessage;
