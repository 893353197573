import { useMutation, useQueryClient } from '@tanstack/react-query'
import attachUnlock from '../endpoints/attachUnlock';

const useAttachUnlockMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(attachUnlock, {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			const unlockId = data?.unlockId;

			if (!unlockId) {
				return;
			}

			queryClient.setQueryData(['unlock', unlockId], (state) => {
				return {
					...state,
					attached: true
				}
			});
		}
	});
};

export default useAttachUnlockMutation;
