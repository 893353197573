import client from '../client';

const getCollection = (options = {}) => {
	const collectionId = options?.meta?.collectionId;
	
	if (!collectionId) {
		return Promise.reject(new Error('Unable to fetch collection without valid collection id'));
	}

	if (options?.requireTemplateId && !options?.meta?.templateId) {
		return Promise.reject(new Error('Unable to fetch collection without valid template id'));
	}

	return client
		.get(`/collections/${ collectionId }`, {
			params: {
				template: options?.meta?.templateId ?? undefined
			}
		}).then(response => response?.data)
	;
};

export default getCollection;
