import { Switch } from '@headlessui/react';
import { useCallback } from 'react';
import clsx from 'clsx';

const Toggle = ({
	className,
	onChange,
	checked,
	name
}) => {
	const handleChange = useCallback(value => {
		if (typeof onChange !== 'function') {
			return;
		}

		onChange({
			target: {
				name,
				value,
				checked: value,
				tagName: 'input',
				type: 'checkbox'
			}
		});
	}, [
		name,
		onChange
	]);

	return (
		<div className={ className }>
			<Switch checked={ checked } onChange={ handleChange } className={ clsx('relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-primary', {
				'bg-theme-primary': !!checked,
				'bg-theme-toggle-primary': !checked
			}) }>
				<span className={ clsx('pointer-events-none inline-block h-5 w-5 transform rounded-full bg-theme-background shadow ring-0 transition duration-200 ease-in-out', {
					'translate-x-5': !!checked,
					'translate-x-0': !checked
				}) } />
			</Switch>
		</div>
	);
};

export default Toggle;
