import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useBrandPageQuery from 'services/api/hooks/useBrandPageQuery';
import useUpdateBrandPageMutation from 'services/api/hooks/useUpdateBrandPageMutation';
import Heading from 'components/Heading';
import Button from 'components/Button';
import SocialInput from 'components/Social/Input';
import SocialIcon from 'components/Social/Icon';
import socialNetworks from 'config/socialNetworks';
import getErrorMessage from 'utils/getErrorMessage';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useJSONState from 'hooks/useJSONState';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useCallback, useMemo, useState, Fragment } from 'react';

const BrandSocials = ({
	heading,
	editable,
	className,
	brandSlug,
	disableHeading
}) => {
	const notifications = useNotificationsStore();

	const [editing, setEditing] = useState(false);

	const [form, formActions] = useJSONState({
		socials: []
	});

	const { data = {}, isLoading } = useBrandPageQuery(useRouteParamsAsMeta({
		brandSlug
	}));

	const { mutate, isLoading: isUpdating } = useUpdateBrandPageMutation({
		onSuccess: () => {
			notifications.success('Social networks successfully updated');
			setEditing(false);
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update social networks'));
		},
	});

	const setEditingActive = useCallback(event => {
		formActions.extend({
			socials: data?.community?.details?.socials?.length > 0 ? data.community.details.socials : SocialInput.getInitialState()
		});

		setEditing(true);
	}, [
		data,
		formActions
	]);

	const setEditingInactive = useCallback(event => {
		setEditing(false);
	}, []);

	const handleSaveClick = useCallback(event => {
		mutate({
			socials: form.socials.filter(item => !!item.value)
		});
	}, [
		form,
		mutate
	]);

	const socials = useMemo(() => {
	 	if (!(data?.community?.details?.socials?.length > 0)) {
	 		return null;
	 	}

	 	return data.community.details.socials.map(item => {
	 		const match = socialNetworks.find(network => network.value === item.name);

	 		if (!match) {
	 			return null;
	 		}

	 		return {
	 			network: match.value,
	 			href: match.pattern.replace('%v', item.value),
	 			key: `${ match.value }__${ item.value }`,
	 			id: item.value,
	 		};
	 	});
	}, [data]);

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<div className="min-w-0 flex items-center mb-4">
					<Heading variant="section" className="truncate flex-1">
						{ heading }
					</Heading>
					{ editing || !editable ? null : (
						<button disabled={ isLoading } onClick={ setEditingActive }>
							<PencilIcon className="w-5 h-5" />
						</button>
					) }
				</div>
			) }
			<div className="flex flex-wrap gap-4">
				{ isLoading ? (
					<Fragment>
						<div className="rounded-full w-12 h-12 bg-theme-placeholder" />
						<div className="rounded-full w-12 h-12 bg-theme-placeholder" />
						<div className="rounded-full w-12 h-12 bg-theme-placeholder" />
					</Fragment>
				) : editing ? (
					<div className="w-full">
						<SocialInput className="mb-4" name="socials" values={ form.socials } onChange={ formActions.intercept } />
						<div className="flex justify-end space-x-3">
							<Button disabled={ isUpdating } onClick={ setEditingInactive } variant="neutral">
								Cancel
							</Button>
							<Button disabled={ isUpdating } onClick={ handleSaveClick } variant="primary">
								Save
							</Button>
						</div>
					</div>
				) : socials ? socials.map(item => (
					<a key={ item.key } title={ item.id } rel="noreferrer" target="_blank" href={ item.href }>
						<SocialIcon network={ item.network } />
					</a>
				)) : (
					<p className="col-span-9 text-sm text-theme-typography-dim italic">
						{ data?.name ?? 'This brand' } hasn't linked to any social networks.
					</p>
				) }
			</div>
		</div>
	);
};

BrandSocials.defaultProps = {
	heading: 'Socials'
};

export default BrandSocials;
