import client from '../client';
import useAuthStore from 'state/hooks/useAuthStore';

const verifyAccount = (data = {}) => client
	.post(`/accounts/grant-password/setup/email/verify/${data.code}`, {}, {
		timeout: 0
	})
	.then(response => {
		if (!response?.data?.token) {
			return Promise.reject(new Error('No authorization token included in verifyAccount response.'));
		}

		useAuthStore.getState().authorize(response.data.token);

		return response.data;
	});
;

export default verifyAccount;
