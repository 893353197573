import { useQuery } from '@tanstack/react-query'
import useAgency from 'features/Auth/hooks/useAgency';
import getCollectionForBrandManager from '../endpoints/getCollectionForBrandManager';

const useCollectionForBrandManagerQuery = (options) => {
	const { id, brandId } = useAgency();
	const collectionId = options?.meta?.collectionId;

	return useQuery(['collectionManager', id, brandId, collectionId], getCollectionForBrandManager, {
		...options,
		enabled: !!(id && brandId && collectionId),
		meta: {
			agency: {
				id,
				brandId
			},
			...options?.meta,
		}
	});
};

export default useCollectionForBrandManagerQuery;
