import { useState, useEffect } from 'react';
import useTokenQuery from './useTokenQuery';
import { useSearchParams } from 'react-router-dom';

const useOptionalTokenQuery = () => {
	const [params] = useSearchParams();
	const claimed = params.get('claimed');
	const network = params.get('network');
	const address = params.get('address');
	const token = params.get('token');

	const [tokenData, setTokenData] = useState(null);
	const [tokenIsLoading, setTokenIsLoading] = useState(false);

	const { data, isLoading } = useTokenQuery({
		meta: {
			network,
			address,
			token,
		},
	});

	useEffect(() => {
		if (claimed) {
			setTokenData(data);
			setTokenIsLoading(isLoading);
		}
	}, [claimed, data, isLoading]);

	if (!claimed || !network || !address || isNaN(token)) {
		return {};
	}

	return { tokenData, tokenIsLoading };
};

export default useOptionalTokenQuery;
