import { create } from 'zustand';

const useNotificationsStore = create((set, get) => ({
	data: [],
	success: (options = {}) => {
		if (typeof options === 'string') {
			options = {
				body: options
			};
		}

		get().add({
			...options,
			type: NOTIFICATION_TYPE_SUCCESS
		});
	},
	failure: (options = {}) => {
		if (typeof options === 'string') {
			options = {
				body: options
			};
		}

		get().add({
			...options,
			type: NOTIFICATION_TYPE_FAILURE
		});
	},
	notice: (options = {}) => {
		if (typeof options === 'string') {
			options = {
				body: options
			};
		}

		get().add({
			...options,
			type: NOTIFICATION_TYPE_NOTICE
		});
	},
	add: (options = {}) => {
		if (typeof options === 'string') {
			options = {
				body: options
			};
		}
		
		const type = NOTIFICATION_TYPES.indexOf(options?.type) !== -1 ? options?.type : NOTIFICATION_TYPE_NOTICE;
		const body = options.body || options.message || '';
		const title = options.title || NOTIFICATION_TITLES[type];

		set(state => ({
			data: [...state.data, {
				id: options?.id ?? Math.ceil(Date.now() * Math.random()),
				ttl: options?.ttl ?? 5,
				image: options?.image ?? '',
				created: Date.now(),
				title,
				type,
				body
			}]
		}));
	},
	remove: (id) => {
		set(state => ({
			data: state.data.filter(item => item.id !== id)
		}));
	}
}));

export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_FAILURE = 'failure';
export const NOTIFICATION_TYPE_NOTICE = 'notice';

export const NOTIFICATION_TITLES = {
	[NOTIFICATION_TYPE_SUCCESS]: 'Success!',
	[NOTIFICATION_TYPE_FAILURE]: 'Error!',
	[NOTIFICATION_TYPE_NOTICE]: 'Notification'
};

export const NOTIFICATION_TYPES = [
	NOTIFICATION_TYPE_SUCCESS,
	NOTIFICATION_TYPE_FAILURE,
	NOTIFICATION_TYPE_NOTICE
];

export default useNotificationsStore;
