import { useState, useCallback, useMemo } from 'react';

const useEventTargetValue = (initialValue = '') => {
	const [value, setValue] = useState(initialValue);
	const setEventValue = useCallback(event => setValue(event?.target?.value), []);
	
	return useMemo(() => [
		value,
		setEventValue,
		setValue,
	], [
		value,
		setEventValue,
		setValue
	]);
};

export default useEventTargetValue;
