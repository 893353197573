import TokenMedia from 'features/Token/Media';
import TokenMeta from 'features/Token/Meta';
import TokenProperties from 'features/Token/Properties';
import TokenAbout from 'features/Token/About';
import TokenDetails from 'features/Token/Details';
import clsx from 'clsx';

const sectionClassName = 'p-4 bg-theme-paper rounded-sm';

const TokenIndexRoute = () => {
	return (
		<div className="p-4">
			<div className="md:grid md:grid-cols-12 gap-8">
				<div className="md:col-span-6 space-y-8">
					<TokenMedia disableHeading />

					<div className={clsx('md:hidden', sectionClassName)}>
						<TokenMeta />
					</div>
					<div className={sectionClassName}>
						<TokenProperties />
					</div>
					<div className={sectionClassName}>
						<TokenAbout />
					</div>
					<div className={sectionClassName}>
						<TokenDetails />
					</div>
				</div>
				<div className="md:col-span-6">
					<TokenMeta className="hidden md:block" />
				</div>
			</div>
		</div>
	);
};

export default TokenIndexRoute;
