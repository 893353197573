import { Fragment, useMemo, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import useJSONState from 'hooks/useJSONState';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useSessionQuery from 'services/api/hooks/useSessionQuery';
import useSetupBrandMutation from 'services/api/hooks/useSetupBrandMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import getErrorMessage from 'utils/getErrorMessage';

const StudioAuthBrandRoute = () => {
	const isAuthorized = useIsAuthorized();

	const notifications = useNotificationsStore();

	const [form, formActions] = useJSONState({
		name: '',
		username: ''
	});

	const { data: session, isSuccess: hasSession } = useSessionQuery({
		onSuccess: (data) => {
			if (!data?.usernameGenerated && !!data?.username) {
				formActions.extend({
					username: data.username
				});
			}
		}
	});

	const { isLoading, isSuccess, mutate } = useSetupBrandMutation({
		onSuccess: () => {
			notifications.success('Brand successfully updated');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update brand information'));
		},
	});

	const isDisabled = useMemo(() => {
		return (
			isLoading ||
			!hasSession ||
			!form.name ||
			!form.username
		);
	}, [
		hasSession,
		isLoading,
		form,
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	if (!isAuthorized) {
		return (
			<Navigate to="/studio/auth/signin" replace />
		);
	}

	if (isAuthorized && hasSession) {
		const [brand] = session?.agencies?.find(a => a.uuid === session.selectedAgency)?.brands ?? [];
		const requiresBrandSetup = session?.usernameGenerated || !brand?.name;

		if (!requiresBrandSetup) {
			return (
				<Navigate to="/studio" replace />
			);
		}
	}

	if (isSuccess) {
		return (
			<Navigate to="/studio" replace />
		);
	}

	return (
		<Fragment>
			<p className="mt-2">
				Before you can start creating, you'll need to setup a few basic details about your brand.
			</p>
			<form className="mt-4 w-full" onSubmit={handleSubmit}>
				<div className="space-y-2 mb-4">
					<div>
						<Label htmlFor="name" className="mb-1">Name</Label>
						<Input id="name" name="name" placeholder="e.g, Elephant Brand" required value={form.name} onChange={formActions.intercept} />
					</div>
					<div>
						<Label htmlFor="username" className="mb-1">Username</Label>
						<Input id="username" name="username" placeholder="e.g, elephantbrand" required value={form.username} onChange={formActions.intercept} />
					</div>
				</div>
				<div className="py-2">
					<Button className="w-full" size="xl" disabled={isDisabled}>
						Continue
					</Button>
				</div>
			</form>
		</Fragment>
	);
};

export default StudioAuthBrandRoute;


