import Label from 'components/Label';
import Input from 'components/Input';
import Button from 'components/Button';
import Radio from 'components/Radio';
import Heading from 'components/Heading';
import Textarea from 'components/Textarea';
import Prompt from 'components/Prompt';
import Upload from 'components/Upload';
import ConditionsInput from 'components/Conditions/Input';
import useNotificationsStore from 'state/hooks/useNotificationsStore'
import useUnlockForBrandManagerQuery from 'services/api/hooks/useUnlockForBrandManagerQuery';
import useCreateUnlockMutation from 'services/api/hooks/useCreateUnlockMutation';
import useUpdateUnlockMutation from 'services/api/hooks/useUpdateUnlockMutation';
import useArchiveUnlockMutation from 'services/api/hooks/useArchiveUnlockMutation';
import useCollectionsForBrandManagerQuery from 'services/api/hooks/useCollectionsForBrandManagerQuery';
import useJSONState from 'hooks/useJSONState';
import getErrorMessage from 'utils/getErrorMessage';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useState, useCallback, useMemo } from 'react';

const DashboardUnlocksFileForm = ({
	className
}) => {
	const meta = useParams();

	const notifications = useNotificationsStore();

	const isEditing = !!meta?.unlockId;

	const [showArchivePrompt, setShowArchivePrompt] = useState(false);

	const { isSuccess, mutate, isLoading } = useCreateUnlockMutation({
		onSuccess: (data) => {
			notifications.success('A new unlock was created.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to create unlock.'))
		}
	});

	const [form, formActions] = useJSONState(() => ({
		matchConditions: 'any',
		instructions: '',
		description: '',
		coverImageUrl: '',
		conditions: ConditionsInput.getInitialState(),
		title: '',
		url: '',
	}));

	const { data: collections, isLoading: isLoadingCollections } = useCollectionsForBrandManagerQuery();

	const filteredCollections = useMemo(() => {
		return collections?.collections?.map(item => ({
			name: item.name,
			value: item.uuid
		})) ?? [];
	}, [collections]);

	const { data: savedForm, isLoading: isLoadingForm } = useUnlockForBrandManagerQuery({
		meta,
		onSuccess: (data) => {
			formActions.extend({
				matchConditions: data?.all === 1 ? 'all' : 'any',
				instructions: data?.privateContext?.instructions ?? '',
				description: data?.description ?? '',
				coverImageUrl: data?.coverImageUrl ?? '',
				title: data?.title ?? '',
				url: data?.privateContext?.url ?? '',
				file: data?.privateContext?.meta,
				conditions: data?.conditions?.length > 0 ? data?.conditions.map(condition => ({
					type: 'contract',
					context: {
						contractUuid: condition?.context?.contractUuid,
						key: condition?.context?.key,
						value: condition?.context?.value
					}
				})) : ConditionsInput.getInitialState()
			});
		}
	});

	const { mutate: mutateArchive, isLoading: isArchivingForm, isSuccess: isSuccessfulArchive } = useArchiveUnlockMutation({
		unlockId: meta?.unlockId,
		onSuccess: (data) => {
			notifications.success('Unlock was archived successfully.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to archive unlock.'));
		}
	});

	const { mutate: mutateUpdate, isLoading: isUpdatingForm, isSuccess: isSuccessfulUpdate } = useUpdateUnlockMutation({
		unlockId: meta?.unlockId,
		onSuccess: (data) => {
			notifications.success('Unlock was updated successfully.');
		},
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Failed to update unlock.'));
		}
	});

	const handleArchiveClick = useCallback(() => {
		setShowArchivePrompt(true);
	}, [setShowArchivePrompt]);

	const handleArchiveConfirm = useCallback(event => {
		event.preventDefault();
		mutateArchive();
	}, [mutateArchive]);

	const handleArchiveDismiss = useCallback(event => {
		setShowArchivePrompt(false);
	}, [setShowArchivePrompt]);

	const handleUploadChange = useCallback(event => {
		formActions.extend({
			url: event?.target?.value,
			file: event?.target?.file
		});
	}, [formActions]);

	const handleFileChangeClick = useCallback(event => {
		event.preventDefault();
		formActions.assign('file', null);
	}, [formActions]);

	const handleFormSubmit = useCallback(event => {
		event.preventDefault();
		
		const action = isEditing ? mutateUpdate : mutate;

		action({
			title: form.title,
			description: form.description,
			coverImageUrl: form.coverImageUrl,
			all: form.matchConditions === 'all',
			privateContext: {
				type: 'file',
				url: form.url,
				instructions: form.instructions,
				meta: {
					name: form?.file?.name,
					size: form?.file?.size,
					type: form?.file?.type
				}
			},
			conditions: form.conditions.filter(item => {
				return !!item?.context?.contractUuid;
			}).map(item => {
				if (item?.context?.key && item?.context?.value) {
					return {
						type: 'contract',
						context: {
							contractUuid: item.context.contractUuid,
							value: item.context.value,
							key: item.context.key
						}
					};
				}

				return {
					type: 'contract',
					context: {
						contractUuid: item.context.contractUuid
					}
				};
			})
		});
	}, [
		form,
		mutate,
		isEditing,
		mutateUpdate
	]);

	const isDisabled = useMemo(() => {
		return (
			isLoading ||
			(isLoadingForm && isEditing) ||
			isUpdatingForm ||
			isArchivingForm ||
			!form.title?.trim()
		);
	}, [
		form,
		isLoading,
		isEditing,
		isLoadingForm,
		isUpdatingForm,
		isArchivingForm
	]);

	/**
	 * Navigate to updated unlocks on success.
	 */
	if (isSuccessfulUpdate || isSuccessfulArchive) {
		return (
			<Navigate to=".." replace />
		);
	}

	/**
	 * Navigate to created unlocks on success.
	 */
	if (isSuccess) {
		return (
			<Navigate to="../.." replace />
		);
	}

	return (
		<div className={ className }>
			<form disabled={ isDisabled } onSubmit={ handleFormSubmit } method="POST" action="/fake-action-for-ios" className="space-y-8 divide-y divide-theme-divider">
				<div className="space-y-8 divide-y divide-theme-divider sm:space-y-5">
					<div className="space-y-6 sm:space-y-5">
						<div>
							<Heading component="h3" variant="section">General</Heading>
							<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
								This information will be displayed publicly so be careful what you share.
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="title" className="sm:mt-px sm:pt-2">
									Title
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Input disabled={ isLoadingForm && isEditing } value={ form.title } onChange={ formActions.intercept } id="title" name="title" type="text" className="max-w-lg sm:max-w-xs" placeholder="e.g. Magical Creation #5" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label htmlFor="description" className="sm:mt-px sm:pt-2">
									Description
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Textarea disabled={ isLoadingForm && isEditing } value={ form.description } onChange={ formActions.intercept } id="description" name="description" rows="6" className="max-w-lg" placeholder="A short description about your unlockable content" />
								</div>
							</div>
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label className="sm:mt-px sm:pt-2">
									Cover Photo
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0">
									<Upload disabled={ isLoadingForm && isEditing } image name="coverImageUrl" onChange={ formActions.intercept } value={ form.coverImageUrl } className="max-w-lg sm:max-w-xs aspect-[1/1]" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
					<div>
						<Heading component="h3" variant="section">Locked Content</Heading>
						<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
							The following can only be accessed by token holders that meet the conditions set in the access control panel.
						</p>
					</div>
					<div className="space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
							<Label className="sm:mt-px sm:pt-2">
								File
							</Label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								{ form?.file ? (
									<div className="flex items-center">
										<div className="mr-4">
											<p className="font-medium">{ form.file?.name }</p>
											<p className="text-xs text-theme-typography-dim">{ form.file?.type }</p>
										</div>
										<Button variant="neutral" type="button" onClick={ handleFileChangeClick }>Change</Button>
									</div>
								) : (
									<Upload disabled={ isLoadingForm && isEditing } acceptText="Any file up to 1gb" accept="*.*" name="url" onChange={ handleUploadChange } value={ form.url } className="max-w-lg sm:max-w-xs aspect-[21/9]" />
								) }
							</div>
						</div>
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
							<Label htmlFor="instructions" className="sm:mt-px sm:pt-2">
								Instructions
							</Label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<Textarea disabled={ isLoadingForm && isEditing } value={ form.instructions } onChange={ formActions.intercept } id="instructions" name="instructions" rows="6" className="max-w-lg" placeholder="Include details and/or instructions about unlockable content, including access codes." />
							</div>
						</div>
					</div>
				</div>

				<div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
					<div>
						<Heading component="h3" variant="section">Access Control</Heading>
						<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
							Determine where your unlocks can be found and who has access to the locked content.
						</p>
					</div>
					<div className="space-y-6 sm:space-y-5">
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
							<Label className="sm:mt-px sm:pt-2">
								Token holder must match
							</Label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<div className="space-y-2">
									<Radio disabled={ isLoadingForm && isEditing } onChange={ formActions.intercept } checked={ form.matchConditions === 'any' } id="any" name="matchConditions" value="any" title="Any Condition" />
									<Radio disabled={ isLoadingForm && isEditing } onChange={ formActions.intercept }  checked={ form.matchConditions === 'all' } id="all" name="matchConditions" value="all" title="All Conditions" />
								</div>
							</div>
						</div>
						<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
							<Label className="sm:mt-px sm:pt-2">
								Conditions
							</Label>
							<div className="mt-1 sm:col-span-2 sm:mt-0">
								<ConditionsInput disabled={ isLoadingCollections || (isLoadingForm && isEditing) } name="conditions" collections={ filteredCollections } values={ form.conditions } onChange={ formActions.intercept } />
							</div>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="flex justify-end space-x-3">
						<Button component={ Link } to={ isEditing ? '..' : '../..' } variant="neutral">
							Cancel
						</Button>
						{ isEditing ? (
							<Button onClick={ handleArchiveClick } type="button" variant="neutral">
								Archive
							</Button>
						) : null }
						<Button disabled={ isDisabled } type="submit" variant="primary">
							Save
						</Button>
					</div>
				</div>
			</form>
			{ isEditing ? (
				<Prompt
					open={ showArchivePrompt }
					onClose={ handleArchiveDismiss }
					severity="high"
					title="Archive Unlock"
					primaryAction="Archive"
					onPrimaryAction={ handleArchiveConfirm }>
					Archiving an unlock will remove it from your collection. Are you sure you want to archive <strong>{ savedForm?.title ?? 'this unlock' }</strong>?
				</Prompt>
			) : null }
		</div>
	);
};

export default DashboardUnlocksFileForm;
