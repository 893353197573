import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useTokenQuery from 'services/api/hooks/useTokenQuery';
import Heading from 'components/Heading';

const TokenMedia = ({
	heading,
	className,
	disableHeading
}) => {
	const videoRef = useRef();
	const meta = useParams();

	const { data = {} } = useTokenQuery({
		meta
	});

	useEffect(() => {
		if (videoRef?.current) {
			const p = videoRef.current.play();
			if (p) {
				p.catch((_) => {
					videoRef.current.muted = true;
					videoRef.current.play().catch((_) => { });
				});
			}
		}
	}, [videoRef]);

	return (
		<div className={className}>
			{disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{heading}
				</Heading>
			)}
			<div className="bg-theme-placeholder rounded-lg aspect-[1/1] overflow-hidden">
				{data?.metadata?.video_url ? (
					<video
						ref={videoRef}
						src={data.metadata.video_url}
						alt={data?.metadata?.title}
						poster={data?.metadata?.image}
						className="object-cover object-center w-full h-full"
						playsInline
						muted
						autoPlay
						loop
					/>
				) : data?.metadata?.image ? (
					<img src={data.metadata.image} alt={data?.metadata?.title} className="object-cover object-center w-full h-full" />
				) : null}

			</div>
		</div>
	);
};

TokenMedia.defaultProps = {
	heading: 'Media'
};

export default TokenMedia;
