import clsx from 'clsx';
import Button from 'components/Button';
import { PaperClipIcon, FaceSmileIcon } from '@heroicons/react/24/outline';

const RichTextarea = ({
	disableSubmit,
	className,
	onSubmit,
	...rest
}) => {
	return (
		<div className={ clsx('rounded-lg shadow-sm border border-theme-divider bg-theme-input-primary overflow-hidden', className) }>
			<textarea { ...rest } className="block w-full bg-transparent resize-none border-0 p-4 focus:ring-0 sm:text-sm" rows="3" placeholder="Add your commment..." />
			<div className="flex items-center p-4">
				<div className="flex-1 flex items-center space-x-2 mr-4">
					{ true ? null : (
						<button className="w-6 rounded-full">
							<PaperClipIcon className="w-full" />
						</button>
					) }
					{ true ? null : (
						<button className="w-6 rounded-full">
							<FaceSmileIcon className="w-full" />
						</button>
					) }
				</div>
				<Button disabled={ disableSubmit } onClick={ onSubmit }>Post</Button>
			</div>
		</div>
	);
};

export default RichTextarea;
