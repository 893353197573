import clsx from 'clsx';
import { Fragment, useMemo } from 'react';

const Logo = ({
	className,
	variant,
	...rest
}) => { 
	const match = useMemo(() => {
		return Logo.variants[variant] ?? Logo.variants.condensed;
	}, [variant]);

			
	return (
		<svg { ...rest } className={ clsx('fill-current text-theme-primary', className) } version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox={ match.viewBox }>
			{ match.children }
		</svg>
	);
};

Logo.variants = {
	condensed: {
		viewBox: '0 0 344 310',
		children: (
			<Fragment>
				<g transform="matrix(4.16667,0,0,4.16667,241.396,309.571)">
					<path d="M0,-74.297L-9.715,-74.297C-10.618,-74.297 -11.46,-73.844 -11.958,-73.091L-34.708,-38.629C-35.301,-37.731 -35.301,-36.566 -34.708,-35.668L-11.958,-1.207C-11.46,-0.454 -10.618,0 -9.715,0L0,0C2.14,0 3.421,-2.381 2.242,-4.167L-18.554,-35.668C-19.147,-36.566 -19.147,-37.731 -18.554,-38.629L2.242,-70.13C3.421,-71.916 2.14,-74.297 0,-74.297" />
				</g>
				<g transform="matrix(4.16667,0,0,4.16667,11.2141,179.52)">
					<path d="M0,31.212L9.715,31.212C10.618,31.212 11.46,30.759 11.958,30.006L23.892,11.928C24.485,11.03 24.485,9.865 23.892,8.967L19.034,1.609C17.972,0 15.612,0 14.55,1.609L-2.242,27.045C-3.421,28.831 -2.14,31.212 0,31.212" />
				</g>
				<g transform="matrix(4.16667,0,0,4.16667,51.6953,130.05)">
					<path d="M0,-31.212L-9.715,-31.212C-11.856,-31.212 -13.137,-28.831 -11.958,-27.045L4.835,-1.609C5.897,0 8.257,0 9.319,-1.609L14.177,-8.967C14.769,-9.865 14.769,-11.03 14.177,-11.928L2.242,-30.006C1.745,-30.759 0.903,-31.212 0,-31.212" />
				</g>
				<g transform="matrix(4.16667,0,0,4.16667,331.845,309.571)">
					<path d="M0,-74.297L-9.715,-74.297C-10.618,-74.297 -11.46,-73.844 -11.958,-73.091L-34.708,-38.629C-35.301,-37.731 -35.301,-36.566 -34.708,-35.668L-11.958,-1.207C-11.46,-0.454 -10.618,0 -9.715,0L0,0C2.14,0 3.421,-2.381 2.242,-4.167L-18.554,-35.668C-19.147,-36.566 -19.147,-37.731 -18.554,-38.629L2.242,-70.13C3.421,-71.916 2.14,-74.297 0,-74.297" />
				</g>
			</Fragment>
		)
	},
	text: {
		viewBox: '0 0 806 175',
		children: (
			<Fragment>
			    <g transform="matrix(4.16667,0,0,4.16667,134.579,174.554)">
			        <path d="M0,-41.893C0.477,-41.893 0.873,-41.724 1.192,-41.386C1.509,-41.049 1.668,-40.661 1.668,-40.225L1.668,-1.669C1.668,-1.231 1.509,-0.844 1.192,-0.507C0.873,-0.169 0.477,0 0,0L-4.768,0C-5.244,0 -5.642,-0.169 -5.959,-0.507C-6.278,-0.844 -6.436,-1.231 -6.436,-1.669L-6.436,-15.732C-6.436,-16.209 -6.605,-16.606 -6.943,-16.924C-7.281,-17.241 -7.668,-17.401 -8.105,-17.401L-22.526,-17.401C-22.963,-17.401 -23.351,-17.241 -23.688,-16.924C-24.026,-16.606 -24.194,-16.209 -24.194,-15.732L-24.194,-1.669C-24.194,-1.231 -24.353,-0.844 -24.671,-0.507C-24.989,-0.169 -25.386,0 -25.863,0L-30.63,0C-31.107,0 -31.504,-0.169 -31.822,-0.507C-32.141,-0.844 -32.299,-1.231 -32.299,-1.669L-32.299,-40.225C-32.299,-40.661 -32.141,-41.049 -31.822,-41.386C-31.504,-41.724 -31.107,-41.893 -30.63,-41.893L-25.863,-41.893C-25.386,-41.893 -24.989,-41.724 -24.671,-41.386C-24.353,-41.049 -24.194,-40.661 -24.194,-40.225L-24.194,-26.042C-24.194,-25.565 -24.026,-25.167 -23.688,-24.85C-23.351,-24.531 -22.963,-24.373 -22.526,-24.373L-8.105,-24.373C-7.668,-24.373 -7.281,-24.531 -6.943,-24.85C-6.605,-25.167 -6.436,-25.565 -6.436,-26.042L-6.436,-40.206C-6.436,-41.138 -5.681,-41.893 -4.749,-41.893L0,-41.893Z" />
			    </g>
			    <g transform="matrix(4.16667,0,0,4.16667,208.637,146)">
			        <path d="M0,-28.187C-0.477,-28.187 -0.874,-28.018 -1.192,-27.68C-1.51,-27.343 -1.669,-26.955 -1.669,-26.519L-1.669,-19.248C-1.669,-18.771 -1.51,-18.374 -1.192,-18.057C-0.874,-17.738 -0.477,-17.58 0,-17.58L13.348,-17.58C13.825,-17.58 14.222,-17.41 14.54,-17.073C14.858,-16.735 15.017,-16.348 15.017,-15.911L15.017,-12.455C15.017,-12.017 14.858,-11.63 14.54,-11.293C14.222,-10.955 13.825,-10.786 13.348,-10.786L0,-10.786C-0.477,-10.786 -0.874,-10.627 -1.192,-10.309C-1.51,-9.991 -1.669,-9.595 -1.669,-9.118L-1.669,-1.728C-1.669,-1.252 -1.51,-0.854 -1.192,-0.536C-0.874,-0.218 -0.477,-0.06 0,-0.06L18.771,-0.06C19.208,-0.06 19.595,0.109 19.933,0.446C20.27,0.785 20.44,1.172 20.44,1.609L20.44,5.184C20.44,5.622 20.27,6.009 19.933,6.346C19.595,6.684 19.208,6.853 18.771,6.853L-8.164,6.853C-8.602,6.853 -8.989,6.684 -9.326,6.346C-9.664,6.009 -9.833,5.622 -9.833,5.184L-9.833,-33.37C-9.833,-34.292 -9.085,-35.04 -8.163,-35.04L18.771,-35.04C19.208,-35.04 19.595,-34.871 19.933,-34.533C20.27,-34.196 20.44,-33.808 20.44,-33.372L20.44,-29.855C20.44,-29.379 20.27,-28.981 19.933,-28.664C19.595,-28.345 19.208,-28.187 18.771,-28.187L0,-28.187Z" />
			    </g>
			    <g transform="matrix(4.16667,0,0,4.16667,559.552,146)">
			        <path d="M0,-28.187C-0.477,-28.187 -0.874,-28.018 -1.192,-27.68C-1.51,-27.343 -1.669,-26.955 -1.669,-26.519L-1.669,-19.248C-1.669,-18.771 -1.51,-18.374 -1.192,-18.057C-0.874,-17.738 -0.477,-17.58 0,-17.58L13.348,-17.58C13.825,-17.58 14.222,-17.41 14.54,-17.073C14.858,-16.735 15.017,-16.348 15.017,-15.911L15.017,-12.455C15.017,-12.017 14.858,-11.63 14.54,-11.293C14.222,-10.955 13.825,-10.786 13.348,-10.786L0,-10.786C-0.477,-10.786 -0.874,-10.627 -1.192,-10.309C-1.51,-9.991 -1.669,-9.595 -1.669,-9.118L-1.669,-1.728C-1.669,-1.252 -1.51,-0.854 -1.192,-0.536C-0.874,-0.218 -0.477,-0.06 0,-0.06L18.771,-0.06C19.208,-0.06 19.595,0.109 19.933,0.446C20.27,0.785 20.44,1.172 20.44,1.609L20.44,5.184C20.44,5.622 20.27,6.009 19.933,6.346C19.595,6.684 19.208,6.853 18.771,6.853L-8.164,6.853C-8.602,6.853 -8.989,6.684 -9.326,6.346C-9.664,6.009 -9.833,5.622 -9.833,5.184L-9.833,-33.37C-9.833,-34.292 -9.085,-35.04 -8.163,-35.04L18.771,-35.04C19.208,-35.04 19.595,-34.871 19.933,-34.533C20.27,-34.196 20.44,-33.808 20.44,-33.372L20.44,-29.855C20.44,-29.379 20.27,-28.981 19.933,-28.664C19.595,-28.345 19.208,-28.187 18.771,-28.187L0,-28.187Z" />
			    </g>
			    <g transform="matrix(4.16667,0,0,4.16667,445.613,174.554)">
			        <path d="M0,-41.893L-5.478,-41.893C-5.987,-41.893 -6.462,-41.637 -6.742,-41.213L-19.571,-21.781C-19.905,-21.275 -19.905,-20.618 -19.571,-20.112L-6.742,-0.68C-6.462,-0.256 -5.987,0 -5.478,0L0,0C1.207,0 1.929,-1.342 1.264,-2.35L-10.462,-20.112C-10.796,-20.618 -10.796,-21.275 -10.462,-21.781L1.264,-39.543C1.929,-40.551 1.207,-41.893 0,-41.893" />
			    </g>
			    <g transform="matrix(4.16667,0,0,4.16667,315.822,101.225)">
			        <path d="M0,17.599L5.478,17.599C5.987,17.599 6.462,17.343 6.742,16.919L13.472,6.725C13.806,6.219 13.806,5.562 13.472,5.056L10.733,0.907C10.134,0 8.803,0 8.204,0.907L-1.264,15.249C-1.929,16.257 -1.207,17.599 0,17.599" />
			    </g>
			    <g transform="matrix(4.16667,0,0,4.16667,338.648,73.3292)">
			        <path d="M0,-17.599L-5.478,-17.599C-6.685,-17.599 -7.407,-16.257 -6.742,-15.249L2.726,-0.907C3.325,0 4.656,0 5.255,-0.907L7.994,-5.056C8.328,-5.562 8.328,-6.219 7.994,-6.725L1.264,-16.919C0.984,-17.343 0.509,-17.599 0,-17.599" />
			    </g>
			    <g transform="matrix(4.16667,0,0,4.16667,496.614,174.554)">
			        <path d="M0,-41.893L-5.478,-41.893C-5.987,-41.893 -6.462,-41.637 -6.742,-41.213L-19.571,-21.781C-19.905,-21.275 -19.905,-20.618 -19.571,-20.112L-6.742,-0.68C-6.462,-0.256 -5.987,0 -5.478,0L0,0C1.207,0 1.929,-1.342 1.264,-2.35L-10.462,-20.112C-10.796,-20.618 -10.796,-21.275 -10.462,-21.781L1.264,-39.543C1.929,-40.551 1.207,-41.893 0,-41.893" />
			    </g>
			    <g transform="matrix(4.16667,0,0,4.16667,804.869,171.08)">
			        <path d="M0,-40.225C-0.319,-40.781 -0.796,-41.059 -1.431,-41.059L-6.436,-41.059C-7.072,-41.059 -7.549,-40.781 -7.866,-40.225L-16.166,-26.012L-16.166,-21.611C-16.166,-20.895 -16.746,-20.316 -17.461,-20.316C-18.176,-20.316 -18.756,-20.895 -18.756,-21.611L-18.756,-26.013L-27.055,-40.225C-27.373,-40.781 -27.85,-41.059 -28.485,-41.059L-33.491,-41.059C-34.127,-41.059 -34.604,-40.781 -34.921,-40.225C-35.239,-39.668 -35.239,-39.112 -34.921,-38.556L-21.751,-16.686C-21.593,-16.368 -21.513,-16.09 -21.513,-15.852L-21.513,-0.835C-21.513,-0.397 -21.355,-0.01 -21.036,0.327C-20.719,0.664 -20.321,0.834 -19.844,0.834L-15.077,0.834C-14.6,0.834 -14.203,0.664 -13.885,0.327C-13.568,-0.01 -13.408,-0.397 -13.408,-0.835L-13.408,-15.852C-13.408,-16.09 -13.329,-16.368 -13.17,-16.686L0,-38.556C0.317,-39.112 0.317,-39.668 0,-40.225" />
			    </g>
			</Fragment>
		)
	}
};

Logo.defaultProps = {
	variant: 'condensed'
};

export default Logo;


