import Heading from 'components/Heading';
import useCollectionForBrandManagerQuery from 'services/api/hooks/useCollectionForBrandManagerQuery';
import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import clsx from 'clsx';

const DashboardCollectionHeading = ({
	className
}) => {
	const { data = {}, isLoading } = useCollectionForBrandManagerQuery(useRouteParamsAsMeta());

	const address = data?.details?.address;
	const link = data?.details?.network?.explorer && address
		? `${data?.details?.network?.explorer}/address/${address}`
		: null;

	if (isLoading) {
		return (
			<div className={ clsx('min-w-0', className) }>
				<div className="h-8 mb-2 w-3/4 bg-theme-placeholder rounded-md" />
				<div className="h-4 mb-1 w-48 bg-theme-placeholder rounded-md" />
				<div className="h-4 mb-1 w-1/2 bg-theme-placeholder rounded-md" />
			</div>
		);
	}

	return (
		<div className={ clsx('min-w-0', className) }>
			<Heading className="mb-2 line-clamp-1">{ data?.name ?? 'Collection' }</Heading>
			<p className="text-sm font-medium">Contract Address</p>
			{
				link && (
					<p className="text-sm text-blue-500 truncate">
						<a target='_blank' href={link} rel="noreferrer">{address}</a>
					</p>
				)
			}
		</div>
	);
};

export default DashboardCollectionHeading;
