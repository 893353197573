import client from '../client';

const getNetworks = () => client
	.get('/networks')
	.then(response => {
		if (!response?.data?.networks?.length) {
			return [];
		}

		return response?.data?.networks?.map(network => ({
			name: network?.disabled ? `${network?.name} - Contact us` : network?.name,
			value: network?.id,
			disabled: network?.disabled,
		}));
	})
;

export default getNetworks;
