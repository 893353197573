import clsx from 'clsx';

const NotificationsList = ({
	className,
	children
}) => {
	return (
		<ul className={ clsx('divide-y divide-theme-divider divide-opacity-50', className) }>
			{ children }
		</ul>
	);
};

export default NotificationsList;
