import client from '../client';

const attachUnlock = (data = {}) => {
	const unlockId = data?.unlockId;

	if (!(unlockId)) {
		return Promise.reject(new Error('Unable to attach unlock without valid unlock id'));
	}

	return client
		.post(`/unlocks/${ unlockId }`)
		.then(response => ({
			unlockId
		}))
	;
};

export default attachUnlock;
