import { Outlet } from 'react-router-dom';
import LogoPoweredByWordMark from 'components/LogoPoweredByWordMark';

const AuthTemplate = () => {
	return (
		<div className="h-screen flex flex-col md:flex-row">
			<div className="w-full h-full md:w-1/2 pb-0 md:pb-20 bg-theme-default flex flex-col md:justify-center md:items-center px-4 md:px-0">
				<div className='w-40 ml-auto mr-auto pt-14 pb-2 md:w-inherit md:pt-0 md:pb-0'>
					<div className='invite-wordmark' />
					<div className='flex justify-end'>
						<div className='w-36 mt-2 mb-4'>
							<LogoPoweredByWordMark color='white' />
						</div>
					</div>
				</div>
				<div className="flex flex-col items-start bg-theme-paper p-12 max-w-lg w-full mt-2 md:mx-auto rounded-sm">
					<Outlet />
				</div>
			</div>

			<div className={`w-full hidden md:flex md:w-1/2 pb-20 flex-col justify-center items-center bg-responsive`}>
				<div className='p-6 flex flex-col items-start justify-center items-center max-w-lg mx-auto px-4'>
					<div className='max-w-sm mt-2 mx-auto'>
						{/* <h2 className="mt-4 text-xl font-bold text-gray-100 py-2">copy copy.</h2>
						<p className="mt-2 text-sm text-gray-100 py-2">copy copy copy.</p>
						<p className="mt-2 text-sm text-gray-100 py-2">copy copy copy.</p>
						<p className="mt-2 text-sm text-gray-100 py-2">copy copy copy. </p> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthTemplate;
