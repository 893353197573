import axios from 'axios';
import config from 'config';
import useAuthStore from 'state/hooks/useAuthStore';
import useNotificationsStore from 'state/hooks/useNotificationsStore';

const client = axios.create({
	baseURL: config.api.baseURL,
	timeout: config.api.timeout
});

client.interceptors.request.use(options => {
	const token = useAuthStore.getState().token;

	if (!token) {
		return options;
	}

	if (options.excludeAuthToken) {
		return options;
	}

	/**
	 * @TODO
	 * Remove this check when no longer mocking out stuff with
	 * random user.
	 */
	if (options?.url?.indexOf('randomuser.me') !== -1) {
		return options;
	}

	return {
		...options,
		headers: {
			...options.headers,
			Authorization: `Bearer ${token}`
		}
	};
});

client.interceptors.response.use(null, error => {
	if (!axios.isAxiosError(error)) {
		return Promise.reject(error);
	}

	if (error?.response?.status === 401 && useAuthStore.getState().token) {
		useNotificationsStore.getState().failure({
			title: 'Unauthorized',
			body: 'Failed to complete your request. Please sign in and try again.'
		});

		useAuthStore.getState().deauthorize();
	}

	return Promise.reject(error);
});

export default client;
