import { useMutation } from '@tanstack/react-query'
import createUnlock from '../endpoints/createUnlock';
import useAgency from 'features/Auth/hooks/useAgency';

const useCreateUnlockMutation = (options) => {
	const agency = useAgency();

	return useMutation(data => createUnlock(data, {
		agency
	}), options);
};

export default useCreateUnlockMutation;
