import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import archiveCollection from '../endpoints/archiveCollection';

const useArchiveCollectionMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(() => archiveCollection({
		agency,
		collectionId: options?.collectionId
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.invalidateQueries({
				queryKey: ['collectionManager', agency.id, agency.brandId, options?.collectionId],
				refetchType: 'none'
			});

			queryClient.setQueryData(['collectionsManager', agency.id, agency.brandId], (state) => {
				return {
					...state,
					collections: state?.collections?.filter(collection => {
						return collection.uuid !== options?.collectionId;
					})
				};
			});
		}
	});
};

export default useArchiveCollectionMutation;
