import client from '../client';

const createCollection = (data = {}, options = {}) => {
	const agency = options?.agency ?? {};

	if (!(agency.id && agency.brandId)) {
		return Promise.reject(new Error('Unable to create collection without valid agency/brand id'));
	}

	return client
		.post(`/agencies/${ agency.id }/brands/${ agency.brandId }/collections`, data, {
			timeout: 0
		})
		.then(response => response?.data)
	;
};

export default createCollection;
