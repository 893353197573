import clsx from 'clsx';

const Textarea = ({
	className,
	variant,
	...rest
}) => {
	return (
		<textarea
			{ ...rest }
			className={ clsx('resize-none appearance-none block w-full px-3 py-2 border rounded-md focus:outline-none sm:text-sm focus:ring-1', Textarea.variants[variant], className) }
		/>
	);
};

Textarea.defaultProps = {
	variant: 'primary'
};

Textarea.variants = {
	primary: [
		'bg-theme-input-primary',
		'border-theme-input-primary-border',
		'text-theme-input-primary-content',
		'placeholder-theme-input-primary-placeholder',
		'focus:ring-theme-input-primary-focus',
		'focus:border-theme-input-primary-focus'
	]
};

export default Textarea;
