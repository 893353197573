import useCollectionQuery from 'services/api/hooks/useCollectionQuery';
import Heading from 'components/Heading';
import { useParams } from 'react-router-dom';
import { Fragment } from 'react';

const DropAbout = ({
	heading,
	className,
	disableHeading
}) => {
	const meta = useParams();

	const { data = {}, isLoading } = useCollectionQuery({
		requireTemplateId: true,
		meta
	});

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading } { data?.name }
				</Heading>
			) }
			{ isLoading ? (
				<Fragment>
					<div className="bg-theme-placeholder rounded-md h-6 mb-1" />
					<div className="bg-theme-placeholder rounded-md h-6 w-1/2 mb-1" />
					<div className="bg-theme-placeholder rounded-md h-6 w-3/4" />
				</Fragment>
			) : data?.details?.description ? (
				<p>
					{ data.details.description }
				</p>
			) : (
				<p className="col-span-3 text-theme-typography-dim italic">
					There is no description assigned to this collection.
				</p>
			) }
		</div>
	);
};

DropAbout.defaultProps = {
	heading: 'About'
};

export default DropAbout;
