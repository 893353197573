import client from '../client';

const getUnlock = (options = {}) => {
	const unlockId = options?.meta?.unlockId;
	
	if (!unlockId) {
		return Promise.reject(new Error('Unable to fetch unlock without valid unlock id'));
	}

	return client
		.get(`/unlocks/${ unlockId }`)
		.then(response => response?.data)
	;
};

export default getUnlock;
