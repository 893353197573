import client from '../client';

const getCollectionForBrandManager = (options = {}) => {
	const collectionId = options?.meta?.collectionId;
	const agency = options?.meta?.agency ?? {};

	if (!(agency.id && agency.brandId && collectionId)) {
		return Promise.reject(new Error('Unable to fetch collection without valid agency/brand/collection id'));
	}

	return client
		.get(`/agencies/${ agency.id }/brands/${ agency.brandId }/collections/${ collectionId }`, {
			params: {
				template: options?.meta?.templateId ?? undefined
			}
		}).then(response => response?.data)
	;
};

export default getCollectionForBrandManager;
