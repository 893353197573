import useTokenQuery from 'services/api/hooks/useTokenQuery';
import Heading from 'components/Heading';
import { useParams } from 'react-router-dom';

const TokenDetails = ({
	heading,
	className,
	disableHeading
}) => {
	const meta = useParams();

	const { data = {}, isLoading } = useTokenQuery({
		meta
	});

	const scanLink = data?.collection?.details?.network?.scan
		? (
			<a target='_blank' className='text-theme-primary' href={data.collection.details.network.scan} rel="noreferrer">
				{data?.collection?.address}
			</a>
		)
		: '';

	const openseaLink = data?.collection?.details?.network?.opensea
		? (
			<a target='_blank' className='text-theme-primary' href={data.collection.details.network.opensea} rel="noreferrer">
				View on Opensea
			</a>
		)
		: ''

	return (
		<div className={className}>
			{disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{heading}
				</Heading>
			)}
			{isLoading ? (
				<div className="space-y-2">
					<div className="bg-theme-placeholder rounded-md h-6" />
					<div className="bg-theme-placeholder rounded-md h-6" />
					<div className="bg-theme-placeholder rounded-md h-6" />
					<div className="bg-theme-placeholder rounded-md h-6" />
				</div>
			) : (
				<div className="divide-y divide-theme-divider text-sm">
					<div className="grid grid-cols-3 gap-4 pb-2">
						<span className="col-span-1 font-medium truncate">Owner</span>
						<span className="col-span-2 truncate">
							{
								data?.owned?.isMe
									? 'Owned by you'
									: data?.owned?.address ?? ''
							}
						</span>
					</div>
					<div className="grid grid-cols-3 gap-4 py-2">
						<span className="col-span-1 font-medium truncate">Contract Address</span>
						<span className="col-span-2 truncate">{scanLink}</span>
					</div>
					<div className="grid grid-cols-3 gap-4 py-2">
						<span className="col-span-1 font-medium  truncate">Token Standard</span>
						<span className="col-span-2 truncate">{data?.collection?.details?.network?.standard}</span>
					</div>
					<div className="grid grid-cols-3 gap-4 py-2">
						<span className="col-span-1 font-medium  truncate">Blockchain</span>
						<span className="col-span-2 truncate">{data?.collection?.details?.network?.network}</span>
					</div>
					{ openseaLink ? (
						<div className="grid grid-cols-3 gap-4 py-2">
							<span className="col-span-1 font-medium  truncate">Opensea</span>
							<span className="col-span-2 truncate">{openseaLink}</span>
						</div>
					) : null }
				</div>
			)}
		</div>
	);
};

TokenDetails.defaultProps = {
	heading: 'Details'
};

export default TokenDetails;
