import { useMutation, useQueryClient } from '@tanstack/react-query'
import unfollowBrand from '../endpoints/unfollowBrand';

const useUnfollowBrandMutation = (options) => {
	const queryClient = useQueryClient();

	return useMutation(unfollowBrand, {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			const brandSlug = options?.meta?.brandSlug;

			if (!brandSlug) {
				return;
			}

			queryClient.setQueryData(['brandPage', brandSlug], (state) => {
				return {
					...state,
					following: false
				}
			});
		}
	});
};

export default useUnfollowBrandMutation;
