import PopoutMenu from 'components/PopoutMenu';
import QueryableListItem from 'components/Queryable/ListItem';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const DashboardCollectionsListItem = ({
	id,
	name,
	type,
	banner,
	address,
	loading,
	viewType,
	blockchain,
	description
}) => (
	<QueryableListItem
		title={ name }
		banner={ banner }
		loading={ loading }
		viewType={ viewType }
		primary={ blockchain }
		secondary={ type }
		description={ description }
		link={ id }
		menu={(
			<PopoutMenu className="items-start" items={[
				<Link key="edit" component="button" to={ `${ id }/edit` } className="flex w-full items-center px-4 py-2 text-sm hover:bg-theme-menu-hover">
					<PencilIcon className="w-4 h-4 mr-2" />Edit
				</Link>
			]} />
		)}
	/>
);

export default DashboardCollectionsListItem;
