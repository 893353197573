import { useCallback, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import useFinalizeAccountMutation from 'services/api/hooks/useFinalizeAccountMutation';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useJSONState from 'hooks/useJSONState';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import EmailOpenIcon from './components/EmailOpenIcon';
import getErrorMessage from 'utils/getErrorMessage';

const Verified = () => {
	const navigate = useNavigate();
	const notifications = useNotificationsStore();

	const isAuthorized = useIsAuthorized();
	const { mutate, isLoading, isSuccess } = useFinalizeAccountMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, "We're sorry, but we were unable to save your profile at this time. Please verify the information you entered and try again. If you need assistance, please contact our support team."))
		},
		onSuccess: () => {
			notifications.success("Your profile has been saved, now let's set up your brand!");
			navigate('/studio');
		}
	});

	const [form, formActions] = useJSONState({
		name: '',
		password: '',
		confirmPassword: ''
	});

	const disabled = useMemo(() => {
		return (
			isLoading ||
			!form.name.trim() ||
			!form.password.trim() ||
			form.password !== form.confirmPassword
		);
	}, [
		isLoading,
		form
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	if (!isAuthorized) {
		return (
			<Navigate to='/studio/auth/signin' />
		);
	}

	if (isSuccess) {
		return null;
	}

	return (
		<div>
			<EmailOpenIcon />
			<Heading className="text-center">Your account was successfully verified!</Heading>

			<hr className="my-6 border-t border-gray-200" />

			<form onSubmit={handleSubmit}>
				<div className="pt-4 pb-4">
					<Heading variant='section'>Set up your profile</Heading>
				</div>
				<div className="space-y-2">
					<div className='py-2'>
						<Label htmlFor="name" className="mb-1">Name</Label>
						<Input id="name" name="name" required onChange={formActions.intercept} value={form.name} />
					</div>
					<div className='py-2'>
						<Label htmlFor="password" className="mb-1">Password</Label>
						<Input id="password" name="password" type="password" required onChange={formActions.intercept} value={form.password} />
					</div>
					<div className='py-2'>
						<Label htmlFor="confirmPassword" className="mb-1">Confirm Password</Label>
						<Input id="confirmPassword" name="confirmPassword" type="password" required onChange={formActions.intercept} value={form.confirmPassword} />
					</div>
				</div>
				<div className="pt-8">
					<Button size="xl" className="w-full" disabled={disabled}>
						Continue
					</Button>
				</div>
			</form>
		</div >
	);
}

export default Verified;
