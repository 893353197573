import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useBrandPageQuery from 'services/api/hooks/useBrandPageQuery';
import EthereumPrice from 'components/Ethereum/Price';
import Currency from 'components/Currency';
import Heading from 'components/Heading';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const BrandStore = ({
	heading,
	className,
	brandSlug,
	disableHeading
}) => {
	const { data = {}, isLoading } = useBrandPageQuery(useRouteParamsAsMeta({
		brandSlug
	}));

	return (
		<div className={ className }>
			{ disableHeading || !heading ? null : (
				<Heading variant="section" className="mb-4">
					{ heading }
				</Heading>
			) }
			{ isLoading ? (
				<div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
					<div className="group">
						<div className="aspect-[4/3] md:aspect-[3/4] bg-theme-placeholder rounded-lg mb-4" />
						<div className="bg-theme-placeholder rounded-md h-8 mb-2" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/2 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-3/4 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/4" />
					</div>
					<div className="group">
						<div className="aspect-[4/3] md:aspect-[3/4] bg-theme-placeholder rounded-lg mb-4" />
						<div className="bg-theme-placeholder rounded-md h-8 mb-2" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/2 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-3/4 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/4" />
					</div>
					<div className="group">
						<div className="aspect-[4/3] md:aspect-[3/4] bg-theme-placeholder rounded-lg mb-4" />
						<div className="bg-theme-placeholder rounded-md h-8 mb-2" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/2 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-3/4 mb-1" />
						<div className="bg-theme-placeholder rounded-md h-4 w-1/4" />
					</div>
				</div>
			) : data?.store?.length > 0 ? (
				<div className="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
					{ data.store.map(item => (
						<Link to={ `/drop/collection/${ item.collectionUuid }/${ item.uuid }` } className="flex md:flex-col" key={ item?.uuid }>
							<div className="bg-theme-placeholder rounded-md w-32 h-32 md:w-full md:aspect-[1/1] mr-4 overflow-hidden md:mr-0 md:mb-4 md:h-auto">
								{ item?.imageUrl ? (
									<img className="object-cover object-center w-full h-full" alt={ item?.title } src={ item.imageUrl } />
								) : null }
							</div>
							<div className="flex-1">
								<h3 className="font-bold tracking-tight mb-1 truncate">{ item.title }</h3>
								<div className="mb-2">
									<Currency defaultValue="Free" component="h2" value={ item.price } className={ clsx('text-2xl tracking-tight font-bold', {
										'text-theme-primary': !item.price
									}) } />
									{ item.price ? (
										<EthereumPrice defaultValue="0.000000" className="block text-base text-theme-primary" value={ item.price } />
									) : null }
								</div>
								<p className="text-sm text-theme-typography-dim line-clamp-3">{ item.description }</p>
							</div>
						</Link>
					)) }
				</div>
			) : (
				<p className="text-sm text-theme-typography-dim italic">
					{ data?.name } hasn't created any storefront tokens.
				</p>
			) }
		</div>
	);
};

BrandStore.defaultProps = {
	heading: 'Store'
};

export default BrandStore;
