import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'services/api/queryClient';
import ReactDOM from 'react-dom/client';
import Main from './Main';
import './index.css';

const rootNode = ReactDOM.createRoot(document.getElementById('root'));

rootNode.render(
  <QueryClientProvider client={ queryClient }>
		<Main />
	</QueryClientProvider>
);
