import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSetupAccountMutation from 'services/api/hooks/useSetupAccountMutation';
import useNotificationsStore from 'state/hooks/useNotificationsStore';
import getErrorMessage from 'utils/getErrorMessage';

const ResendEmail = () => {
	const notifications = useNotificationsStore();

	const [lockedOut, setLockedOut] = useState(false);

	const [params] = useSearchParams();
	const paramEmail = params.get('email');
	const email = paramEmail ? window.atob(paramEmail) : null;

	const { mutate, isLoading } = useSetupAccountMutation({
		onError: (data) => {
			notifications.failure(getErrorMessage(data, 'Please verify the information you entered and try again. If the issue persists, please contact our support team for assistance.'));
		},
		onSuccess: () => {
			notifications.success("We've sent a new confirmation email. Please check your inbox and follow the instructions to complete your registration");
		}
	});

	const handleResend = useCallback(event => {
		event.preventDefault();
		mutate({ email });
		setLockedOut(true);
		setTimeout(() => {
			setLockedOut(false);
		}, 5000);
	}, [
		email,
		mutate
	]);

	const isLockedOut = lockedOut || isLoading;

	const resendClass = isLockedOut
		? "cursor-pointer"
		: "text-theme-primary cursor-pointer";

	return (
		<div className="space-y-4">
			<p className="font-light">
				Did not receive the email? <span className={resendClass} onClick={!isLockedOut ? handleResend : undefined}>Resend confirmation email</span>
			</p>
		</div>
	);
}

export default ResendEmail;