import Label from 'components/Label';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Radio from 'components/Radio';
import useEventTargetValue from 'hooks/useEventTargetValue';
import { Link } from 'react-router-dom';

const DashboardUnlocksCreateIndexRoute = () => {
	const [selection, handleChange] = useEventTargetValue('url');

	return (
		<div className="p-4">
			<Heading className="mb-6">Getting Started</Heading>
			<form className="space-y-8 divide-y divide-theme-divider">
				<div className="space-y-8 divide-y divide-theme-divider sm:space-y-5">
					<div className="space-y-6 sm:space-y-5">
						<div>
							<Heading component="h3" variant="section">Unlock</Heading>
							<p className="mt-1 max-w-2xl text-sm text-theme-typography-dim">
								General information about the unlock you'll be creating today
							</p>
						</div>
						<div className="space-y-6 sm:space-y-5">
							<div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-theme-divider sm:pt-5">
								<Label className="sm:mt-px sm:pt-2">
									Type
								</Label>
								<div className="mt-1 sm:col-span-2 sm:mt-0 space-y-4">
									<Radio checked={ selection === 'url' } onChange={ handleChange } id="url" description="Provide a hidden URL with instructions only visible to designated token holders" name="type" value="url" title="URL" />
									<Radio checked={ selection === 'file' } onChange={ handleChange } id="file" description="Upload a file that can only be revealed by specific token holders" name="type" value="file" title="File" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pt-5">
					<div className="flex justify-end space-x-3">
						<Button component={ Link } to=".." variant="neutral">
							Cancel
						</Button>
						<Button variant="primary" component={ Link } to={ selection }>
							Continue
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default DashboardUnlocksCreateIndexRoute;
