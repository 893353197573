import client from '../client';

const claimToken = (options = {}) => {
	if (!(options?.meta?.collectionId && options?.meta?.templateId)) {
		return Promise.reject(new Error('Unable to claim token without valid collection/template id'));
	}

	return client
		.post(`/collections/${ options.meta.collectionId }/templates/${ options.meta.templateId }/claim`)
		.then(response => response?.data)
	;
};

export default claimToken;
