import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAgency from 'features/Auth/hooks/useAgency';
import updateCollection from '../endpoints/updateCollection';

const useUpdateCollectionMutation = (options) => {
	const queryClient = useQueryClient();
	const agency = useAgency();
	
	return useMutation(data => updateCollection(data, {
		agency,
		collectionId: options?.collectionId
	}), {
		...options,
		onSuccess: (data) => {
			if (options?.onSuccess) {
				options.onSuccess(data);
			}

			queryClient.setQueryData(['collectionManager', agency.id, agency.brandId, data.uuid], (currentState) => {
				return {
					...currentState,
					...data
				};
			});
		}
	});
};

export default useUpdateCollectionMutation;
