import Heading from 'components/Heading';
import InlineNavigation from 'components/InlineNavigation';
import { Outlet } from 'react-router-dom';
import { Fragment } from 'react';

const navigation = [{
	name: 'Tokens',
	path: 'tokens'
}, {
	name: 'Communities',
	path: 'communities'
}, {
	name: 'Unlocks',
	path: 'unlocks'
}];

const ConsumerCollectionTemplate = () => {
	return (
		<Fragment>
			<InlineNavigation className="mb-4" paths={navigation} />
			<Heading className="px-4" variant="page">My Collection</Heading>
			<Outlet />
		</Fragment>
	);
};

export default ConsumerCollectionTemplate;
