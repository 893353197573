import QueryableList from 'components/Queryable/List';
import useEventTargetValue from 'hooks/useEventTargetValue';
import useRouteParamsAsMeta from 'hooks/useRouteParamsAsMeta';
import useCollectionForBrandManagerQuery from 'services/api/hooks/useCollectionForBrandManagerQuery';
import DashboardCollectionTokenListItem from './ListItem';
import ShareDialog from 'components/ShareDialog';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useCallback, Fragment } from 'react';

const DashboardCollectionTokenList = ({
	className
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [viewType, setViewType] = useEventTargetValue(QueryableList.viewTypes[0]?.value);
	const [searchTerm, setSearchTerm] = useEventTargetValue();
	const [sortMethod, setSortMethod] = useEventTargetValue(QueryableList.sortMethods[0]?.value);
	const { data = {}, isLoading } = useCollectionForBrandManagerQuery(useRouteParamsAsMeta());

	const filteredData = useMemo(() => {
		if (!searchTerm) {
			return data?.templates;
		}

		const term = searchTerm.toLowerCase();

		return data?.templates?.filter(item => {
			return (item?.title?.toLowerCase()?.indexOf(term) ?? -1) !== -1
		});
	}, [
		data,
		searchTerm
	]);

	const handleClose = useCallback(() => {
		setSearchParams();
	}, [setSearchParams]);

	const sharing = useMemo(() => {
		const id = searchParams.get('s');

		if (!id) {
			return { open: false, onClose: handleClose };
		}

		const match = data?.templates?.find(t => t?.uuid === id);

		if (!match) {
			return { open: false, onClose: handleClose };
		}

		return {
			open: true,
			title: match?.title,
			banner: match?.imageUrl,
			username: data?.brand?.slug,
			onClose: handleClose,
			link: `${window?.location?.origin}/drop/collection/${data?.uuid}/${match?.uuid}`
		}
	}, [
		data,
		handleClose,
		searchParams,
	]);

	return (
		<div className={className}>
			<QueryableList
				viewType={viewType}
				searchTerm={searchTerm}
				sortMethod={sortMethod}
				onViewTypeChange={setViewType}
				onSearchTermChange={setSearchTerm}
				onSortMethodChange={setSortMethod}
				createPath="create/free"
				type="token"
			>
				{isLoading ? (
					<Fragment>
						<DashboardCollectionTokenListItem viewType={viewType} loading />
						<DashboardCollectionTokenListItem viewType={viewType} loading />
						<DashboardCollectionTokenListItem viewType={viewType} loading />
					</Fragment>
				) : filteredData?.length > 0 ? filteredData?.map((item = {}) => {
					return (
						<DashboardCollectionTokenListItem
							key={item.uuid}
							id={item.uuid}
							name={item.title}
							banner={item.imageUrl}
							price={item.price}
							minted={item.minted ?? 0}
							username={data?.brand?.slug}
							description={item.description}
							supply={item.capacity === null ? 'Unlimited' : item.capacity ?? 0}
							collectionId={data?.uuid}
							viewType={viewType}
							includeOnStorefront={item.includeOnStorefront}
						/>
					);
				}) : null}
			</QueryableList>
			<ShareDialog {...sharing} />
		</div>
	);
};

export default DashboardCollectionTokenList;
