/**
 * General Dependencies
 */
import { Fragment } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import UINotifications from 'features/UI/Notifications';

/**
 * Error
 */
import ErrorIndexRoute from 'routes/Error/Index';

/**
 * Brand Page
 */
import BrandTemplate from 'routes/Brand/Template';
import BrandFeedRoute from 'routes/Brand/Feed';
import BrandStoreRoute from 'routes/Brand/Store';
import BrandUnlockRoute from 'routes/Brand/Unlock';
import BrandUnlocksRoute from 'routes/Brand/Unlocks';

/**
 * Drop
 */
import DropTemplate from 'routes/Drop/Template';
import DropProductRoute from 'routes/Drop/Product';

/**
 * Token
 */
import TokenTemplate from 'routes/Token/Template';
import TokenIndexRoute from 'routes/Token/Index';

/**
 * Authorization
 */
import AuthTemplate from 'routes/Auth/Template';
import AuthSetupRoute from 'routes/Auth/Setup';
import AuthSignInRoute from 'routes/Auth/SignIn';
import AuthSignUpRoute from 'routes/Auth/SignUp';
import AuthVerifyRoute from 'routes/Auth/Verify';

import AuthInviteTemplate from 'routes/Auth/Invite/Template';
import AuthInviteRoute from 'routes/Auth/Invite/Invite';

/**
 * Studio
 */
import StudioTemplate from 'routes/Studio/Template';
import StudioAudienceRoute from 'routes/Studio/Audience';
import StudioAnalyticsRoute from 'routes/Studio/Analytics';

/**
 * Studio Profile
 */
import StudioProfileTemplate from 'routes/Studio/Profile/Template';
import StudioProfileEditRoute from 'routes/Studio/Profile/Edit';
import StudioProfileIndexRoute from 'routes/Studio/Profile/Index';

/**
 * Studio Authorization
 */
import StudioAuthTemplate from 'routes/Studio/Auth/Template';
import StudioAuthBrandRoute from 'routes/Studio/Auth/Brand';
import StudioAuthSignInRoute from 'routes/Studio/Auth/SignIn';
import StudioAuthSignUpRoute from 'routes/Studio/Auth/SignUp';
import StudioAuthSignOutRoute from 'routes/Studio/Auth/SignOut';
import StudioAuthForgotPasswordRoute from 'routes/Studio/Auth/ForgotPassword';
import StudioAuthProfileRoute from 'routes/Studio/Auth/Profile';

import StudioAuthMessagesTemplate from 'routes/Studio/Auth/Messages/Template';
import StudioAuthMessagesMessage from 'routes/Studio/Auth/Messages/Message';

/**
 * Studio Pages
 */
import StudioPagesIndexRoute from 'routes/Studio/Pages/Index';
import StudioPagesCreateRoute from 'routes/Studio/Pages/Create';

/**
 * Studio Unlocks
 */
import StudioUnlocksIndexRoute from 'routes/Studio/Unlocks/Index';
import StudioUnlocksEditIndexRoute from 'routes/Studio/Unlocks/Edit/Index';
import StudioUnlocksCreateIndexRoute from 'routes/Studio/Unlocks/Create/Index';
import StudioUnlocksCreateURLRoute from 'routes/Studio/Unlocks/Create/URL';
import StudioUnlocksCreateFileRoute from 'routes/Studio/Unlocks/Create/File';


/**
 * Studio Collections
 */
import StudioCollectionsIndexRoute from 'routes/Studio/Collections/Index';
import StudioCollectionsCreateIndexRoute from 'routes/Studio/Collections/Create/Index';
import StudioCollectionsEditIndexRoute from 'routes/Studio/Collections/Edit/Index';

/**
 * Studio Collection
 */
import StudioCollectionIndexRoute from 'routes/Studio/Collection/Index';
import StudioCollectionEditIndexRoute from 'routes/Studio/Collection/Edit/Index';
import StudioCollectionCreateIndexRoute from 'routes/Studio/Collection/Create/Index';
import StudioCollectionCreateFreeRoute from 'routes/Studio/Collection/Create/Free';
import StudioCollectionCreatePurchaseRoute from 'routes/Studio/Collection/Create/Purchase';

/**
 * Studio Settings
 */
import StudioSettingsTemplate from 'routes/Studio/Settings/Template';
import StudioSettingsProfileRoute from 'routes/Studio/Settings/Profile';
import StudioSettingsBrandRoute from 'routes/Studio/Settings/Brand';
import StudioSettingsSubscriptionsRoute from 'routes/Studio/Settings/Subscriptions';
import StudioSettingsMerchantAccountRoute from 'routes/Studio/Settings/MerchantAccount';

/**
 * Consumer
 */
import ConsumerTemplate from 'routes/Consumer/Template';

/**
 * Consumer Collection
 */
import ConsumerCollectionTemplate from 'routes/Consumer/Collection/Template';
import ConsumerCollectionTokensRoute from 'routes/Consumer/Collection/Tokens';
import ConsumerCollectionUnlocksRoute from 'routes/Consumer/Collection/Unlocks';
import ConsumerCollectionCommunitiesRoute from 'routes/Consumer/Collection/Communities';

/**
 * Consumer Settings
 */
import ConsumerSettingsTemplate from 'routes/Consumer/Settings/Template';
import ConsumerSettingsProfileRoute from 'routes/Consumer/Settings/Profile';
import ConsumerSettingsWalletsRoute from 'routes/Consumer/Settings/Wallets';

/**
 * Landing
 */
import LandingTemplate from 'routes/Landing/Template';

/**
 * Misc
 */
import SignOut from 'routes/Auth/SignOut';

const Main = () => {
	return (
		<Fragment>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<ConsumerTemplate />}>
						<Route index element={<Navigate to="collection" replace />} />
						<Route path="settings" element={<ConsumerSettingsTemplate />}>
							<Route index element={<Navigate to="profile" replace />} />
							<Route path="profile" element={<ConsumerSettingsProfileRoute />} />
							<Route path="wallets" element={<ConsumerSettingsWalletsRoute />} />
						</Route>
						<Route path="collection" element={<ConsumerCollectionTemplate />}>
							<Route index element={<Navigate to="tokens" replace />} />
							<Route path="tokens" element={<ConsumerCollectionTokensRoute />} />
							<Route path="unlocks" element={<ConsumerCollectionUnlocksRoute />} />
							<Route path="communities" element={<ConsumerCollectionCommunitiesRoute />} />
						</Route>
					</Route>
					<Route path="/landing" element={<LandingTemplate />} />
					<Route path="signout" element={<SignOut />} />
					<Route path="studio/auth/messages" element={<StudioAuthMessagesTemplate />}>
						<Route path=":message" element={<StudioAuthMessagesMessage />} />
					</Route>
					<Route path="studio/auth" element={<StudioAuthTemplate />}>
						<Route index element={<StudioAuthSignUpRoute />} />
						<Route path="signup" element={<StudioAuthSignUpRoute />} />
						<Route path="signin" element={<StudioAuthSignInRoute />} />
						<Route path="forgot-password" element={<StudioAuthForgotPasswordRoute />} />
						<Route path="profile" element={<StudioAuthProfileRoute />} />
						<Route path="brand" element={<StudioAuthBrandRoute />} />
						<Route path="signout" element={<StudioAuthSignOutRoute />} />
					</Route>
					<Route path="studio" element={<StudioTemplate />}>
						<Route index element={<Navigate to="profile" replace />} />
						<Route path="profile" element={<StudioProfileTemplate />}>
							<Route index element={<StudioProfileIndexRoute />} />
							{true ? null : <Route path="edit" element={<StudioProfileEditRoute />} />}
						</Route>
						{true ? null : <Route path="audience" element={<StudioAudienceRoute />} />}
						{true ? null : <Route path="analytics" element={<StudioAnalyticsRoute />} />}
						<Route path="unlocks">
							<Route index element={<StudioUnlocksIndexRoute />} />
							<Route path="create">
								<Route index element={<StudioUnlocksCreateIndexRoute />} />
								<Route path="url" element={<StudioUnlocksCreateURLRoute />} />
								<Route path="file" element={<StudioUnlocksCreateFileRoute />} />
							</Route>
							<Route path=":unlockId" element={<StudioUnlocksEditIndexRoute />} />
						</Route>
						<Route path="settings" element={<StudioSettingsTemplate />}>
							<Route index element={<Navigate to="brand" replace />} />
							<Route path="brand" element={<StudioSettingsBrandRoute />} />
							<Route path="profile" element={<StudioSettingsProfileRoute />} />
							{true ? null : <Route path="subscriptions" element={<StudioSettingsSubscriptionsRoute />} />}
							{true ? null : <Route path="merchant-account" element={<StudioSettingsMerchantAccountRoute />} />}
						</Route>
						<Route path="pages">
							<Route index element={<StudioPagesIndexRoute />} />
							<Route path="create" element={<StudioPagesCreateRoute />} />
						</Route>
						<Route path="collections">
							<Route index element={<StudioCollectionsIndexRoute />} />
							<Route path="create" element={<StudioCollectionsCreateIndexRoute />} />
							<Route path=":collectionId">
								<Route index element={<StudioCollectionIndexRoute />} />
								<Route path="edit" element={<StudioCollectionsEditIndexRoute />} />
								<Route path="create">
									{ /** Disabling other token creation types for now. **/}
									{true ? null : <Route index element={<StudioCollectionCreateIndexRoute />} />}
									{true ? null : <Route path="purchase" element={<StudioCollectionCreatePurchaseRoute />} />}
									<Route path="free" element={<StudioCollectionCreateFreeRoute />} />
								</Route>
								<Route path=":templateId" element={<StudioCollectionEditIndexRoute />} />
							</Route>
						</Route>
					</Route>
					<Route path="authorize" element={<AuthTemplate />}>
						<Route index element={<AuthSignInRoute />} />
						<Route path="signup" element={<AuthSignUpRoute />} />
						<Route path="verify/email/:code" element={<AuthVerifyRoute />} />
						<Route path="setup" element={<AuthSetupRoute />} />
					</Route>
					<Route path="invite" element={<AuthInviteTemplate />}>
						<Route index element={<AuthInviteRoute />} />
					</Route>
					<Route path="drop" element={<DropTemplate />}>
						<Route path="collection/:collectionId/:templateId" element={<DropProductRoute />} />
					</Route>
					<Route path="t/:network/:address/:token" element={<TokenTemplate />}>
						<Route index element={<TokenIndexRoute />} />
					</Route>
					<Route path="c/:brandSlug" element={<BrandTemplate />}>
						<Route index element={<Navigate to="feed" replace />} />
						<Route path="feed" element={<BrandFeedRoute />} />
						<Route path="store" element={<BrandStoreRoute />} />
						<Route path="unlocks">
							<Route index element={<BrandUnlocksRoute />} />
							<Route path=":unlockId" element={<BrandUnlockRoute />} />
						</Route>
					</Route>
					<Route path="*" element={<ErrorIndexRoute />} />
				</Routes>
			</BrowserRouter >
			<UINotifications />
		</Fragment >
	);
};

export default Main;
