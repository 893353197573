import { useQuery } from '@tanstack/react-query'
import useAgency from 'features/Auth/hooks/useAgency';
import getTemplateForBrandManager from '../endpoints/getTemplateForBrandManager';

const useTemplateForBrandManagerQuery = (options) => {
	const { id, brandId } = useAgency();
	const templateId = options?.meta?.templateId;
	const collectionId = options?.meta?.collectionId;

	return useQuery(['templateManager', id, brandId, collectionId, templateId], getTemplateForBrandManager, {
		...options,
		enabled: !!(id && brandId && collectionId && templateId),
		meta: {
			agency: {
				id,
				brandId
			},
			...options?.meta,
		}
	});
};

export default useTemplateForBrandManagerQuery;
