import Heading from 'components/Heading';
import Button from 'components/Button';
import Input from 'components/Input';
import Label from 'components/Label';
import useIsAuthorized from 'hooks/useIsAuthorized';
import useJSONState from 'hooks/useJSONState';
import useFinalizeAccountMutation from 'services/api/hooks/useFinalizeAccountMutation';
import { Navigate } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import clsx from 'clsx';

const AuthProfileForm = ({
	className
}) => {
	const isAuthorized = useIsAuthorized();
	const { mutate, isLoading, isSuccess } = useFinalizeAccountMutation();
	const [form, formActions] = useJSONState({
		name: '',
		password: '',
		confirmPassword: ''
	});

	const disabled = useMemo(() => {
		return (
			isLoading || 
			!form.name.trim() || 
			!form.password.trim() || 
			form.password !== form.confirmPassword
		);
	}, [
		isLoading,
		form
	]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		mutate(form);
	}, [
		form,
		mutate
	]);

	return isSuccess ? (
		<Navigate to="/" replace />
	) : !isAuthorized ? (
		<Navigate to="/authorize" replace />
	) : (
		<form onSubmit={ handleSubmit } action="/a-fake-action" className={ clsx('space-y-8', className) }>
			<div className="space-y-4">
				<Heading>Setup your profile</Heading>
			</div>
			<div className="space-y-2">
				<div>
					<Label htmlFor="name" className="mb-1">Name</Label>
					<Input id="name" name="name" required onChange={ formActions.intercept } value={ form.name } />
				</div>
				<div>
					<Label htmlFor="password" className="mb-1">Password</Label>
					<Input id="password" name="password" type="password" required onChange={ formActions.intercept } value={ form.password } />
				</div>
				<div>
					<Label htmlFor="confirmPassword" className="mb-1">Confirm Password</Label>
					<Input id="confirmPassword" name="confirmPassword" type="password" required onChange={ formActions.intercept } value={ form.confirmPassword } />
				</div>
			</div>
			<div className="space-y-4">
				<Button size="xl" className="w-full" disabled={ disabled }>
					Continue
				</Button>
			</div>
		</form>
	);
};

export default AuthProfileForm;